import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/BusinessForm/businessform.scss'
import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import VerificationFailedContent from '../../../Verification/components/verificationHelp'
import Toast from 'src/components/Toast'
import { InputFields } from 'src/components/InputFields'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Label from '../../../PersonalInformation/_components/Label'
import useVerifyCacModal from './hook'
import { useCustomToast } from 'src/utils/Hooks/useToast'

const VerifyCacModal = ({
    showModal,
    onCancel,
    cacHelpPayload,
    setVerificationInProgress,
    inputs,
    isCustomerApproved,
}) => {
    const {
        verificationFailedModal,
        validateCacMutation,
        setToastMessage,
        modalToastMessage,
        modalToastType,
        modalOnlyInputError,
        modalInputs,
        closeModal,
        handleChange,
        handleOnBlur,
        handleSubmit,
    } = useVerifyCacModal(onCancel, inputs)

    const { isMobile } = useMediaQueries()
    const { BVNAlert } = useCustomToast()

    return (
        <>
            <ConsumerModalBase
                showModal={showModal}
                onCancel={closeModal}
                modalWidth={'475px'}
                title={
                    verificationFailedModal
                        ? 'Unable to verify'
                        : 'Verify CAC number'
                }
                secondaryBtnText="I'll verify later"
                primaryBtnText={
                    verificationFailedModal ? 'Try again' : 'Confirm'
                }
                handlePrimaryBtnClick={handleSubmit}
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                primaryBtnLoading={validateCacMutation.isLoading}
                kycSecondaryButtonStopLoading
                footerLeftContent={verificationFailedModal ? 'chat' : 'button'}
                handleSecondaryBtnClick={() => {
                    closeModal()
                    BVNAlert(
                        'Please, go ahead to provide your other details',
                        isMobile ? '100%' : '511px',
                    )
                }}
            >
                {verificationFailedModal ? (
                    <VerificationFailedContent
                        payload={cacHelpPayload}
                        onCancel={onCancel}
                        setVerificationInProgress={setVerificationInProgress}
                        setToastMessage={setToastMessage}
                        type={'cac'}
                    />
                ) : (
                    <div className="BusinessInfo_CACMOdalContent-Wrapper">
                        <div style={{ position: 'relative' }}>
                            {modalToastMessage && (
                                <Toast
                                    message={modalToastMessage}
                                    messageType={modalToastType}
                                    closable
                                    width={isMobile ? '100%' : '367px'}
                                    position={'absolute'}
                                    top={30}
                                />
                            )}
                            <Label
                                title="Confirm your business name"
                                subTitle="Please confirm your registered name before continuing"
                            />
                            <InputFields
                                title="Business Name"
                                name="businessName"
                                value={modalInputs?.businessName}
                                handleChange={handleChange}
                                onBlur={handleOnBlur}
                                errorMessage={modalOnlyInputError?.businessName}
                                disabled={isCustomerApproved}
                            />
                            <VerticalSpacer top="32px" />
                            <Label
                                title="Confirm your CAC number"
                                subTitle="Please confirm your CAC number before continuing"
                            />
                            <InputFields
                                title="CAC Number"
                                name="cacNumber"
                                value={modalInputs?.cacNumber}
                                handleChange={handleChange}
                                onBlur={handleOnBlur}
                                errorMessage={modalOnlyInputError?.cacNumber}
                                disabled={isCustomerApproved}
                            />
                        </div>
                    </div>
                )}
            </ConsumerModalBase>
        </>
    )
}

VerifyCacModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    verificationFailedModal: PropTypes.bool,
    cacHelpPayload: PropTypes.object,
    setVerificationInProgress: PropTypes.func,
    setToastMessage: PropTypes.func,
    modalToastMessage: PropTypes.string,
    modalToastType: PropTypes.string,
    inputs: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
    isCustomerApproved: PropTypes.bool,
    confirmCac: PropTypes.func,
    validateCacMutation: PropTypes.func,
}

export default VerifyCacModal
