import { useEffect, useState } from 'react'
import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'
import ConsumerLoader from 'src/components/ConsumerLoader'
import styles from './creditdecision.module.scss'
import DecisionIsNo from './components/DecisionIsNo'
import DecisionIsMaybe from './components/DecisionIsMaybe'
import DecisionIsYes from './components/DecisionIsYes'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import {
    retrievePaymentPlans,
    runCreditDecision,
    runCreditDecisionWithBody,
} from 'src/api/shoppingExperience/creditDecision'
import { useAppContext } from 'src/appContext/AppContext'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

const CreditDecision = () => {
    const { LoadingContent, pageWrapper, pageContent } = styles
    const [decision, setDecision] = useState('')
    const [loadingState, setLoadingState] = useState(false)
    const history = useHistory()
    const pageTitle =
        'Credit Decision | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREDIT_DECISION_SSE'
    const { overviewInfo, errorAlert, simulatedCreditDecision } =
        useAppContext()

    const DecisionComponent = {
        no: <DecisionIsNo />,
        yes: <DecisionIsYes />,
        maybe: <DecisionIsMaybe />,
    }

    const { refetch: refetchPaymentPlans, isFetching: paymentPlansIsLoading } =
        useQuery(
            'paymentPlans',
            () => retrievePaymentPlans(overviewInfo?.estimationId),
            {
                enabled: false,
                retry: false,
                onSuccess: ({ data }) => {
                    setLoadingState(false)
                    if (
                        data?.data?.condition?.type === 1 &&
                        overviewInfo?.paymentPlanType === 'Subscription'
                    ) {
                        history.push({
                            pathname: '/consumer/workspace',
                            state: { isSubscription: true },
                        })
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            eventTrackers['creditDecisionisYesSub'].action,
                            eventTrackers['creditDecisionisYesSub'].label,
                            eventTrackers['creditDecisionisYesSub'].category,
                            ['MP', 'GA'],
                            'event',
                            { data: data?.data },
                        )
                    } else if (
                        data?.data?.condition?.type === 1 &&
                        overviewInfo?.paymentPlanType === 'Lease to Own'
                    ) {
                        history.push({
                            pathname: '/consumer/workspace',
                            state: { isLeaseToOwn: true },
                        })
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            eventTrackers['creditDecisionisYesL2O'].action,
                            eventTrackers['creditDecisionisYesL2O'].label,
                            eventTrackers['creditDecisionisYesL2O'].category,
                            ['MP', 'GA'],
                            'event',
                            { data: data?.data },
                        )
                    } else if (
                        data?.data?.condition?.type === 2 ||
                        data?.data?.condition?.type === 3 ||
                        data?.data?.condition?.type === 4
                    ) {
                        history.push({
                            pathname: '/consumer/workspace',
                            state: { isWithSuggestion: true },
                        })
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            eventTrackers['creditDecisionisYesWithSuggestion']
                                .action,
                            eventTrackers['creditDecisionisYesWithSuggestion']
                                .label,
                            eventTrackers['creditDecisionisYesWithSuggestion']
                                .category,
                            ['MP', 'GA'],
                            'event',
                            { data: data?.data },
                        )
                    } else if (data?.data?.condition?.type === 5) {
                        setDecision('maybe')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            eventTrackers['creditDecisionisMaybe'].action,
                            eventTrackers['creditDecisionisMaybe'].label,
                            eventTrackers['creditDecisionisMaybe'].category,
                            ['MP', 'GA'],
                            'event',
                            { data: data?.data },
                        )
                    } else if (
                        data?.data?.condition?.type === 6 &&
                        overviewInfo?.paymentPlanType === 'Lease to Own'
                    ) {
                        setDecision('no')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            eventTrackers['creditDecisionIsNoL20'].action,
                            eventTrackers['creditDecisionIsNoL20'].label,
                            eventTrackers['creditDecisionIsNoL20'].category,
                            ['MP', 'GA'],
                            'event',
                            { data: data?.data },
                        )
                    } else if (
                        data?.data?.condition?.type === 6 &&
                        overviewInfo?.paymentPlanType === 'Subscription'
                    ) {
                        setDecision('no')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            eventTrackers['creditDecisionIsNoSub'].action,
                            eventTrackers['creditDecisionIsNoSub'].label,
                            eventTrackers['creditDecisionIsNoSub'].category,
                            ['MP', 'GA'],
                            'event',
                            { data: data?.data },
                        )
                    }
                },
                onError: error => {
                    setLoadingState(false)
                    errorAlert(
                        error?.response?.data?.message ||
                            'An error occured, please try again later.',
                    )

                    history.goBack()
                },
            },
        )

    const checkCreditDecision = useMutation({
        mutationKey: ['checkCreditDecision'],
        mutationFn: () =>
            simulatedCreditDecision?.length > 0
                ? runCreditDecisionWithBody({
                      estimation_id: overviewInfo?.estimationId,
                      type: simulatedCreditDecision,
                  })
                : runCreditDecision(overviewInfo?.estimationId),
        onSuccess: () => {
            simulatedCreditDecision?.length > 0
                ? setTimeout(() => {
                      refetchPaymentPlans()
                  }, 5000)
                : refetchPaymentPlans()
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['creditDecisionSuccess'].action,
                eventTrackers['creditDecisionSuccess'].label,
                eventTrackers['creditDecisionSuccess'].category,
            )
        },
        onError: error => {
            setLoadingState(false)
            errorAlert(
                error?.response?.data?.message ||
                    'An error occured, please try again later.',
            )

            history.goBack()
        },
    })

    useEffect(() => {
        checkCreditDecision.mutate()
        setLoadingState(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={pageWrapper}>
                {checkCreditDecision.isLoading ||
                loadingState ||
                paymentPlansIsLoading ? (
                    <div className={LoadingContent}>
                        <ConsumerLoader
                            overlayWidth="80px"
                            overlayHeight="120px"
                            overlayBgColor="#FFFFFF"
                            overlayPosition="relative"
                            iconSize="medium"
                        />
                        <h1>Checking Eligibility</h1>
                        <p>
                            Verifying if you’re eligible to access solar
                            financing by SunFi. Seat back and relax, this will
                            only take a moment.
                        </p>
                    </div>
                ) : (
                    <div className={pageContent}>
                        {DecisionComponent[decision]}
                    </div>
                )}
                <AccountSetupFooter />
            </div>
        </>
    )
}

export default CreditDecision
