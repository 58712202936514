import { useQuery } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { getReturningCustomerStatusApi } from 'src/api/shoppingExperience/landingPage'

const useGetReturningCustomerStatus = ({ email, phone, onSuccess }) => {
    const { errorAlert } = useCustomToast()

    const { isFetching, refetch } = useQuery({
        queryKey: ['getReturningCustomerStatus'],
        queryFn: () => getReturningCustomerStatusApi(email, phone),
        enabled: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        onSuccess: res => {
            onSuccess?.(res?.data?.data)
        },
        onError: error => {
            errorAlert(errorHandler(error?.response?.data))
        },
    })

    return {
        getReturningCustomerStatus: refetch,
        getReturningCustomerStatusLoading: isFetching,
    }
}

export default useGetReturningCustomerStatus
