import { useHistory } from 'react-router-dom'
import '../../NewProfile/newprofile.scss'
import BackNav from 'src/components/BackNav'
import SeoComponent from 'src/components/Seo'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import { InputFields } from 'src/components/InputFields'
import s from '../components/PageForm/pageform.module.scss'
import ButtonComponent from 'src/components/Button'
import UploadLogo from 'src/assets/images/upload.svg'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import { Switch, Upload } from 'antd'
import Representatives from './components/Representatives'
import { ReactComponent as QuestionIcon } from 'src/assets/images/question-mark-circle.svg'
import { handleInputChange } from 'src/components/ConsumerLandingPageBody/utils'
import { extractFileNameFromUrl } from 'src/utils/formatting'
import { useClusterInfo } from './hook'
import ActionModal from 'src/components/ActionModal'
import SkeletonInput from 'antd/lib/skeleton/Input'

const ProfileClusterInformation = () => {
    const history = useHistory()
    const { Dragger } = Upload
    const {
        inputs,
        setInputs,
        errors,
        setErrors,
        beforeUpload,
        props,
        toggleRep,
        handleNext,
        uploadToS3Loading,
        width,
        repData,
        setRepData,
        updateProfileLoading,
        showActionModal,
        setShowActionModal,
        getClusterOriginationFetching,
        isLoadingProfile,
        profile_id,
        refetchProfile,
    } = useClusterInfo()
    return (
        <>
            <SeoComponent title="SunFi | Admin | Manage Consumer Profile" />

            <ActionModal
                actionModalOpen={showActionModal}
                headerText="Consumer profile updated"
                subTitle="You have successfully edited this consumer profile"
                actionType="success"
                noCancelBtn
                noBackLink
                actionText="Done"
                actionHandler={() => {
                    setShowActionModal(false)
                    refetchProfile()
                }}
                closable
                onCancel={() => setShowActionModal(false)}
                closeModal={() => {
                    setShowActionModal(false)
                    refetchProfile()
                }}
            />
            <div className="NewProfileContainer">
                <BackNav title={'Go Back'} onClick={() => history.goBack()} />
                <div className="NewProfileContentWrapper">
                    <div
                        className="NewProfileFormWrapper"
                        style={{ width: '100%' }}
                    >
                        <p className="NewProfileTitle">
                            Enter cluster information
                        </p>
                        {isLoadingProfile || getClusterOriginationFetching ? (
                            <div style={{ display: 'grid' }}>
                                <SkeletonInput
                                    style={{ height: 19, marginBottom: 16 }}
                                />
                                <SkeletonInput
                                    style={{ height: 55, marginBottom: 16 }}
                                />
                                <SkeletonInput style={{ height: 55 }} />
                            </div>
                        ) : (
                            <div className={s.FormSection}>
                                <div className="space-between">
                                    <h4>Add closed cluster information</h4>
                                    <span className="align-items-center">
                                        <QuestionIcon />
                                    </span>
                                </div>
                                <InputFields
                                    name="name"
                                    title="Cluster Name"
                                    marginBottom={'10px'}
                                    value={inputs?.name}
                                    handleChange={e =>
                                        handleInputChange(
                                            e,
                                            setInputs,
                                            setErrors,
                                        )
                                    }
                                    errorMessage={errors['name']}
                                />
                                <div>
                                    <InputFields
                                        title="Cluster website"
                                        name="website"
                                        marginBottom={'10px'}
                                        value={inputs?.website}
                                        handleChange={e =>
                                            handleInputChange(
                                                e,
                                                setInputs,
                                                setErrors,
                                            )
                                        }
                                        errorMessage={errors['website']}
                                    />
                                    <p className={s.OptionalLabel}>Optional</p>
                                </div>
                            </div>
                        )}
                        {isLoadingProfile || getClusterOriginationFetching ? (
                            <div style={{ display: 'grid' }}>
                                <SkeletonInput
                                    style={{ height: 19, marginBottom: 16 }}
                                />
                                <SkeletonInput style={{ height: 222 }} />
                            </div>
                        ) : (
                            <div className={s.FormSection}>
                                <div className="space-between">
                                    <h4>Add cluster logo</h4>
                                    <span className="align-items-center">
                                        <QuestionIcon />
                                    </span>
                                </div>

                                <div>
                                    <Dragger
                                        className={s.UploadContainer}
                                        maxCount={1}
                                        beforeUpload={beforeUpload}
                                        showUploadList={false}
                                        accept="image/svg+xml,image/jpeg,image/png"
                                        disabled={uploadToS3Loading}
                                        customRequest={() => {}}
                                        {...props}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            {uploadToS3Loading &&
                                            width < 100 ? (
                                                <div className="SlideContainer">
                                                    <p
                                                        className="Slider"
                                                        style={{
                                                            width: `${width}%`,
                                                        }}
                                                    ></p>
                                                </div>
                                            ) : inputs?.logo?.length > 0 ? (
                                                <img
                                                    src={SuccessUpload}
                                                    className="UploadLogo"
                                                    alt="success logo"
                                                />
                                            ) : (
                                                <img
                                                    src={UploadLogo}
                                                    className="UploadLogo"
                                                    alt="upload logo"
                                                />
                                            )}
                                        </p>
                                        <p
                                            className="UploadText"
                                            style={{ position: 'relative' }}
                                        >
                                            {uploadToS3Loading &&
                                            width < 100 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        left: '36%',
                                                    }}
                                                >
                                                    Uploading picture...
                                                </div>
                                            ) : inputs?.logo?.length > 0 ? (
                                                <>
                                                    <p
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        {extractFileNameFromUrl(
                                                            inputs?.logo,
                                                        )}
                                                    </p>
                                                    <p>
                                                        <u
                                                            className="Browse"
                                                            style={{
                                                                marginTop:
                                                                    '20px',
                                                                lineHeight:
                                                                    '40px',
                                                            }}
                                                        >
                                                            Replace document
                                                        </u>
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        Upload file. Drag and
                                                        drop or
                                                        <u className="Browse">
                                                            browse
                                                        </u>
                                                    </p>
                                                    <p className={s.fileSize}>
                                                        The max file size is
                                                        5MB. Please ensure{' '}
                                                        <br />
                                                        the image is of high
                                                        quality.
                                                    </p>
                                                    <p className="OIDSupportedFormatText">
                                                        Supported format : SVG,
                                                        JPEG, PNG
                                                    </p>
                                                </>
                                            )}
                                        </p>
                                    </Dragger>
                                    {!uploadToS3Loading && (
                                        <p className="ErrorText">
                                            {errors['logo']}
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                        {isLoadingProfile || getClusterOriginationFetching ? (
                            <div style={{ display: 'grid' }}>
                                <SkeletonInput
                                    style={{ height: 19, marginBottom: 16 }}
                                />
                                <SkeletonInput style={{ height: 131 }} />
                            </div>
                        ) : (
                            <div className={s.FormSection}>
                                <div className="space-between">
                                    <h4>
                                        Add cluster representative information
                                    </h4>
                                    {[null, undefined].includes(profile_id) && (
                                        <div className={s.switch_wrapper}>
                                            <span
                                                style={{
                                                    color: !inputs?.cluster_representative
                                                        ? '#004AAD'
                                                        : '',
                                                }}
                                            >
                                                No
                                            </span>
                                            <Switch
                                                checked={
                                                    inputs?.cluster_representative
                                                }
                                                onChange={toggleRep}
                                            />
                                            <span
                                                style={{
                                                    color: inputs?.cluster_representative
                                                        ? '#004AAD'
                                                        : '',
                                                }}
                                            >
                                                Yes
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <Representatives
                                    data={repData}
                                    setData={setRepData}
                                    addRepInfo={
                                        profile_id?.length > 0 ||
                                        inputs?.cluster_representative
                                    }
                                />
                                <p className="ErrorText">
                                    {errors['representatives']}
                                </p>
                            </div>
                        )}
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            btnTextColor={'#FFFFFF'}
                            handleClick={handleNext}
                            disabled={
                                updateProfileLoading ||
                                uploadToS3Loading ||
                                getClusterOriginationFetching
                            }
                            loading={updateProfileLoading}
                        >
                            Continue
                        </ButtonComponent>
                    </div>
                    <div className="NewProfileDescriptionWrapper">
                        <DescriptionComponent text="Enter the details of the new consumer profile you want to create. Ensure you choose the correct profile type and system size." />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileClusterInformation
