import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import {
    getKYCCompletionStatus,
    getKYCCompletionStatusForAdmin,
} from 'src/api/shoppingExperience/account-overview'
import { formatKYCCompletionStatus } from 'src/utils/formatKYCCompletionStatus'
import { decodeUserInfo } from 'src/utils/auth'
export const useKYCCompletionStatus = (
    estimation_id,
    customer_type,
    isDynamicKycUser = false,
) => {
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const [kycStatus, setKycStatus] = useState([])

    const { refetch: statusRefetch } = useQuery(
        'kycStatus',
        () =>
            isAdminUser
                ? getKYCCompletionStatusForAdmin(estimation_id)
                : getKYCCompletionStatus(estimation_id),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                const formattedData = formatKYCCompletionStatus(
                    data,
                    customer_type,
                    isDynamicKycUser,
                )
                setKycStatus(formattedData)
            },
        },
    )

    useEffect(() => {
        if (estimation_id) {
            statusRefetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimation_id, customer_type])
    return kycStatus
}
