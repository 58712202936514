import PropTypes from 'prop-types'
import Verify from 'src/components/GettingStarted/components/Verify.jsx'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import Toast from 'src/components/Toast'
import useVerifyLoginOTP from '../hooks/useVerifyLoginOTP'
import KYCPageType from 'src/components/ConsumerLandingPageBody/KYCPageType'

const VerifyLoginOTP = ({
    showModal,
    setShowModal,
    method,
    inputs,
    userType,
}) => {
    const {
        otpError,
        toastError,
        errorMessage,
        resent,
        resendOTPMutation,
        closeModal,
        verifyLoginMutation,
        otp,
        setOtp,
        setResent,
        modalValues,
        action,
        handleKYCPageSelection,
        handlePrimaryBtnClick,
        kycPageType,
    } = useVerifyLoginOTP(setShowModal, method, inputs, userType)
    const { title, primaryBtnText } = modalValues

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={() => closeModal()}
            modalWidth={'475px'}
            title={title}
            primaryBtnText={primaryBtnText}
            handlePrimaryBtnClick={handlePrimaryBtnClick}
            primaryBtnLoading={verifyLoginMutation.isLoading}
            showFooter
            v2
            footerLeftContent={null}
            content={
                <>
                    {toastError && (
                        <Toast
                            messageType={'error-secondary'}
                            message={errorMessage}
                        />
                    )}
                    {action === 'select-kyc-page-type' ? (
                        <KYCPageType
                            handleKYCPageSelection={handleKYCPageSelection}
                            kycPageType={kycPageType}
                        />
                    ) : (
                        <Verify
                            contact={method}
                            otp={otp}
                            setOtp={setOtp}
                            otpError={otpError}
                            inputs={inputs}
                            handleResendOtp={() => resendOTPMutation.mutate()}
                            resent={resent}
                            setResent={setResent}
                            disabled={verifyLoginMutation.isLoading}
                        />
                    )}
                </>
            }
            dataTestId={'verify-login-otp-modal'}
            primaryBtnDataTestId={'verify-login-otp-primary-btn'}
        />
    )
}

VerifyLoginOTP.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    method: PropTypes.oneOf(['phone', 'email']),
    inputs: PropTypes.object,
    userType: PropTypes.oneOf(['consumer', 'cluster-rep']).isRequired,
}
export default VerifyLoginOTP
