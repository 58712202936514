import '../businessverification.scss'
import PropTypes from 'prop-types'
import { useState } from 'react'
import UploadField from 'src/components/InputFields/UploadField'
import { InputFields } from 'src/components/InputFields'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { bvnRegex } from 'src/utils/validationSchema'
import { useAppContext } from 'src/appContext/AppContext'
import { appTracking } from 'src/utils/appTracker'
import { ReactComponent as HelpIcon } from 'src/assets/images/help-circle.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import BvnData from '../../FinancialInformation/FinancialInfoForm/BvnData'
import { useKYCCompletionStatus } from 'src/utils/Hooks/useKYCCompletionStatus'
import OverrideCheckModal from '../../_components/OverrideCheckModal'
import useKYCFormCompletionGuard from '../../hooks/useKYCFormCompletionGuard'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import CSSEVideoPlayer from 'src/components/CSSEVideoPlayer'

const BusinessVerificationForm = ({
    cac,
    moa,
    setCac,
    setMoa,
    handleUploadClick,
    openBVNModal,
    inputs,
    setInputs,
    errors,
    setErrors,
    isAdminUser,
    handleRemoveFile,
    verificationInProgress,
}) => {
    const [localErrors, setLocalErrors] = useState({})
    const { isCustomerApproved, estimationId, customerType } = useAppContext()
    const pageTitle =
        'KYC - Business Verification | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const [tooltipVisible, setToolTipVisible] = useState('')
    const kycStatus = useKYCCompletionStatus(estimationId, customerType)
    const bvnCompleted = kycStatus?.bvn_completed
    const [showOverrideModal, setShowOverrideModal] = useState(false)
    const [showVideoModal, setShowVideoModal] = useState(!isAdminUser)
    const [showVideo, setShowVideo] = useState(false)

    const { isMobile } = useMediaQueries()

    const handleChange = e => {
        setToolTipVisible('revert-layer')

        const { name, value } = e.target
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeFromErrObj(name)
    }

    const handleBvnFocus = () => {
        setToolTipVisible('visible')
    }

    const handleStartBVNProcess = () => {
        if (bvnRegex.test(inputs.bvn)) {
            openBVNModal()
            delete localErrors.bvn
            removeFromErrObj('bvn_verified')
        } else {
            setLocalErrors(prev => ({
                ...prev,
                bvn: 'Please enter a valid BVN',
            }))
        }
    }

    const removeFromErrObj = field => {
        const newErrs = { ...errors }
        delete newErrs[field]
        setErrors(newErrs)
    }

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_BUSINESS_IDENTITY_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    const handleOverride = () => {
        setShowOverrideModal(true)
    }

    // check if user has completed previous forms
    useKYCFormCompletionGuard(kycStatus, 2, customerType)

    return (
        <>
            {(showVideoModal || showVideo) && (
                <CSSEVideoPlayer
                    showVideoModal={showVideoModal}
                    setShowVideoModal={setShowVideoModal}
                    showVideo={showVideo}
                    setShowVideo={setShowVideo}
                    modalTitle="Next step"
                    header="Verify your business information"
                    videoInfo="Here’s a video to guide you on how you can verify your business"
                    videoUrl="https://www.youtube.com/watch?v=CZyBPiPApqk"
                    videoGIF="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Verify_Your_Business_Using_these_Simple_Steps.gif"
                    description="We need to verify your business before we can provide financing, to do this, you’ll have to upload your CAC certificate, Memorandum of association, and verify your Director’s BVN"
                />
            )}
            <OverrideCheckModal
                showOverrideModal={showOverrideModal}
                type="BVN"
                onCancel={() => {
                    setShowOverrideModal(false)
                }}
            />
            <div className={'SSEBusinessVerificationWrapper'}>
                {!isAdminUser && (
                    <div
                        className="FinancialFormVideoWrapper"
                        onClick={() => setShowVideoModal(true)}
                    >
                        <PlayIcon />
                        <p>Watch how to verify your business</p>
                    </div>
                )}
                <div className={'SSEBusinessVerificationText'}>
                    <h4>Business CAC doc</h4>
                    {!isAdminUser && (
                        <p>Provide us with your business CAC certificate</p>
                    )}
                </div>
                <UploadField
                    btnText={'CAC certificate'}
                    handleUploadClick={() => {
                        handleUploadClick('cac_certificate')
                        removeFromErrObj('cac')
                    }}
                    handleRemoveClick={() => {
                        if (inputs.cac_url !== undefined) {
                            handleRemoveFile('CAC', inputs.cac_url)
                        } else {
                            setCac([])
                            setInputs(prev => ({
                                ...prev,
                                cac_url: undefined,
                            }))
                        }
                    }}
                    fileUploaded={cac.length > 0}
                    fileName={'CAC certificate.pdf'}
                    fileUrl={cac?.[0]?.url}
                    margin={'0 0 24px 0'}
                    width={isMobile ? '100%' : '457px'}
                    errorMessage={errors.cac}
                    disabled={isAdminUser}
                    downloadable={isAdminUser}
                />
                <UploadField
                    btnText={'Memorandum of association'}
                    handleUploadClick={() => {
                        handleUploadClick('memorandum_of_association')
                        removeFromErrObj('moa')
                    }}
                    handleRemoveClick={() => {
                        if (inputs.moa_url !== undefined) {
                            handleRemoveFile('MOA', inputs.moa_url)
                        } else {
                            setMoa([])
                            setInputs(prev => ({
                                ...prev,
                                moa_url: undefined,
                            }))
                        }
                    }}
                    fileUploaded={moa.length > 0}
                    fileName={'Memorandum of association.pdf'}
                    fileUrl={moa?.[0]?.url}
                    margin={'0 0 32px 0'}
                    width={isMobile ? '100%' : '457px'}
                    errorMessage={errors.moa}
                    disabled={isAdminUser}
                    downloadable={isAdminUser}
                />
                <div className={'SSEBusinessVerificationText'}>
                    <div className="SSEBusinessBVNTitleWrapper">
                        <h4>Director’s Bank Verification Number</h4>
                        {!isAdminUser && (
                            <CustomToolTip
                                text="Your director's BVN is required to verify the identity and validate name of the business director."
                                toolTipFontSize="12px"
                                toolTipMarginLeft="-150px"
                                toolTipPopupWidth="174px"
                                borderRadius="8px"
                                background="#070C18"
                                lineHeight="18px"
                                visibility={tooltipVisible}
                            >
                                <HelpIcon />
                            </CustomToolTip>
                        )}
                    </div>
                    {!isAdminUser && (
                        <p>
                            Please provide your director’s BVN. Your BVN does
                            not give us access to your bank account. We use it
                            to verify your credit history
                        </p>
                    )}
                </div>
                <div className="SSEBusinessBVNWrapper">
                    <InputFields
                        name={'bvn'}
                        title={"Director's BVN"}
                        width={isMobile ? '100%' : '457px'}
                        value={inputs.bvn}
                        handleChange={handleChange}
                        onBlur={e => handleTracking(e)}
                        errorMessage={
                            localErrors.bvn || errors.bvn || errors.bvn_verified
                        }
                        verified={inputs.bvn_verified}
                        disabled={
                            inputs.bvn_verified ||
                            isAdminUser ||
                            verificationInProgress
                        }
                        inprogress={verificationInProgress}
                        marginBottom={verificationInProgress && '5px'}
                        onFocus={handleBvnFocus}
                    />
                    {verificationInProgress && (
                        <span className="SSEBusinessBVNSpan">
                            Verification In Progress
                        </span>
                    )}

                    {!inputs.bvn_verified &&
                        !isAdminUser &&
                        !verificationInProgress && (
                            <div className={'SSEBusinessVerification_BvnBtn'}>
                                <button
                                    onClick={() => handleStartBVNProcess()}
                                    disabled={
                                        inputs?.bvn === undefined ||
                                        inputs?.bvn?.length === 0 ||
                                        inputs?.bvn?.length < 11 ||
                                        inputs?.bvn?.length > 11 ||
                                        isAdminUser ||
                                        isCustomerApproved
                                    }
                                >
                                    <span>
                                        {isMobile ? 'Verify' : 'Verify BVN'}
                                    </span>
                                </button>
                            </div>
                        )}
                    {isAdminUser && bvnCompleted && (
                        <BvnData
                            title="BVN DATA"
                            page="business-verification"
                            handleOverride={handleOverride}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

BusinessVerificationForm.propTypes = {
    cac: PropTypes.array.isRequired,
    moa: PropTypes.array.isRequired,
    setCac: PropTypes.func.isRequired,
    setMoa: PropTypes.func.isRequired,
    handleUploadClick: PropTypes.func.isRequired,
    openBVNModal: PropTypes.func.isRequired,
    inputs: PropTypes.object,
    setInputs: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    isAdminUser: PropTypes.bool,
    handleRemoveFile: PropTypes.object,
    verificationInProgress: PropTypes.bool,
}

export default BusinessVerificationForm
