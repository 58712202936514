/* eslint-disable no-undef */
import axios from 'axios'
import { getToken, logsOutUser } from 'src/utils/auth'
import { getClientLocator } from 'src/utils/user-tracking'
import { getCookie } from 'src/utils/cookie'
import Hashes from 'jshashes'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_V2,
})

const exception401Urls = ['/consumers/offer']

const exception404Urls = [
    '/consumers/offer',
    '/auth/consumers/email-verification/initiate',
    '/auth/consumers/phone-verification/initiate',
]

const exception403Urls = ['/consumers/offer']
const exceptionAuthUrls = ['/consumers/offer']

instance.interceptors.request.use(
    async config => {
        const token = getToken()
        if (token && !exceptionAuthUrls.includes(config.url)) {
            // add token to header
            // sample code: config.headers['header name'] = `expected token header format e.g Bearer ${token}`
            config.headers['Authorization'] = `Bearer ${token}`
        }

        const allowCookie = getCookie('allow-cookie')

        if (allowCookie === 'true') {
            const locator = await getClientLocator()
            config.headers['User-IP_Address'] = locator?.ipAddress
        }

        const SHA512 = new Hashes.SHA512()
        const timestamp = Date.now().toString()
        const plainText = config.baseURL + config.url + timestamp

        const hash_data = SHA512.hex_hmac(
            process.env.REACT_APP_SUNFI_APP_TOKEN,
            plainText,
        )
        config.headers['x-sunfi-signature'] = hash_data
        config.headers['x-sunfi-timestamp'] = timestamp

        return config
    },
    error => Promise.reject(error),
)

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error?.response?.status === 403) {
            if (exception403Urls.includes(error?.response?.config?.url)) {
                return Promise.reject(error)
            } else {
                window.location.href = '/app/403-page'
            }
        }
        if (error?.response?.status === 401) {
            if (exception401Urls.includes(error?.response?.config?.url)) {
                return Promise.reject(error)
            } else {
                const exceptionUrls = ['/app/403-page', '/app/not-found']
                const lastPage =
                    window.location.pathname + window.location.search

                logsOutUser(exceptionUrls.includes(lastPage) ? '' : lastPage)
            }
        }

        if (error?.response?.status === 404) {
            let staticUrl = error?.response?.config?.url
            let dynamicUrl = staticUrl?.split('?')?.[0]
            if (
                exception404Urls.includes(staticUrl) ||
                exception404Urls.includes(dynamicUrl)
            ) {
                return Promise.reject(error)
            } else {
                if (
                    error?.response?.config?.url?.substring(0, 40) ===
                    '/estimations/mini/consumer-conversation/'
                ) {
                    window.location.href = '/not-found'
                } else {
                    window.location.href = '/app/not-found'
                }
            }
        }

        return Promise.reject(error)
    },
)

export default instance
