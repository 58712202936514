import Avatar from '../../../../../components/Avatar/Avatar'
import { AppButton } from '../../../../../components/Button'
import EmptyProfilePic from '../../../../../components/EmptyProfilePic'

export const formatInstallerInfo = (
    data,
    setViewIdentificationDetails,
    setViewPicture,
) => {
    let formattedValues = []

    const email = data?.user?.email ?? '-'
    const phoneNumber = data?.user?.phone_number ?? '-'
    const companyName = data?.company_name ?? '-'
    const educationLevel = data?.user?.education_level ?? '-'
    const identificationType = data?.identification_details?.type ?? '-'

    formattedValues.push(
        { label: 'Email', value: email },
        {
            label: 'Phone Number',
            value: phoneNumber,
        },
        {
            label: 'Company Name',
            value: companyName,
        },
        {
            label: 'Qualification',
            value: educationLevel,
        },
        {
            label: 'Type of Identification',
            value: identificationType,
        },
        {
            label: 'Identification Details',
            value: (
                <AppButton
                    btnTextColor={'#004AAD'}
                    btnBgColor={'#E2EEFF'}
                    type="buttonTwo"
                    handleClick={() => setViewIdentificationDetails(true)}
                    disabled={
                        data?.identification_details?.file_url === null ||
                        data?.identification_details?.file_url === undefined
                    }
                >
                    View
                </AppButton>
            ),
        },
        {
            label: 'Profile Picture',
            value: (
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    {data?.user?.avatar_url ? (
                        <div onClick={() => setViewPicture(true)}>
                            <Avatar
                                avatar={`${data?.user?.avatar_url}`}
                                alt="provider-avatar"
                            />
                        </div>
                    ) : (
                        <EmptyProfilePic
                            firstLetter={data?.user?.first_name
                                ?.substring(0, 1)
                                ?.toUpperCase()}
                            secondLetter={data?.user?.last_name
                                ?.substring(0, 1)
                                ?.toUpperCase()}
                            profilePage={true}
                        />
                    )}
                </div>
            ),
        },
    )
    return formattedValues
}
