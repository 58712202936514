import PropTypes from 'prop-types'
import '../gettingstarted.scss'
import { requiredInfoList } from '../data'
import infoIcon from 'src/assets/images/Info-warning-blue.svg'

const RequiredInfo = ({ inputs, heading = '' }) => {
    return (
        <div
            className={'GettingStartedRequiredInfo'}
            data-testid="required-info-container"
        >
            <div
                className={'GettingStartedHeadingAndText noBottomMargin'}
                data-testid="heading-and-text"
            >
                {heading === '' ? (
                    <h3 data-testid="verification-heading">
                        {inputs?.email !== '' && inputs?.email !== undefined
                            ? 'Email'
                            : 'Phone number'}{' '}
                        verified successfully
                    </h3>
                ) : (
                    <h3>{heading}</h3>
                )}
                <p>
                    Before you continue with your signup, please be aware that
                    these are the required documents needed on SunFi.
                </p>
            </div>
            <div
                className={'GettingStartedRequiredInfoList'}
                data-testid="info-list"
            >
                <h3>Required information to be requested from you:</h3>
                <ul>
                    {requiredInfoList(inputs)?.map((each, i) => (
                        <li key={i} data-testid={`info-item-${i}`}>
                            {each}
                        </li>
                    ))}
                </ul>
            </div>
            <div
                className={'GettingStartedRequiredInfoDisclaimer'}
                data-testid="disclaimer"
            >
                <img src={infoIcon} alt={'info'} role={'presentation'} />
                <p>
                    Please note that none of these information requested from
                    you will give us access to your bank account or
                    transactions.
                </p>
            </div>
        </div>
    )
}

RequiredInfo.propTypes = {
    inputs: PropTypes.object,
    heading: PropTypes.string,
}

export default RequiredInfo
