import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import AccountSetupNav from './components/Nav'
import TopColumn from './components/TopColumn'
import SelectedSolution from './components/SelectedSolution'
import CreateAccount from './components/CreateAccount'
import SwitchUseType from './components/SwitchUseType'
import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'
import ActionModalV2 from 'src/components/ActionModalV2'
import { ReactComponent as NewPackageIcon } from '../../../../assets/images/new-package.svg'
import {
    actionModalTitleMapping,
    actionModalHeaderTextMapping,
    actionModalSubtitleMapping,
    actionModalButtonTextMapping,
    formatAccountOverviewData,
    useTypeMapping,
    formatEnergyInputs,
    formatEnergyNeeds,
    actionModalIconMapping,
    actionModalSecondaryButtonTextMapping,
} from './data'
import EditApplianceInput from '../SolarSystemsListings/EditApplianceInput'
import ConfirmEmail from './components/ConfirmEmail'
import { continueLaterEmailSchema } from 'src/utils/validationSchema'
import {
    retrieveEstimationApi,
    getKYCInfo,
    retrieveEstimationForAdminApi,
    getConsumerKYCInfoForAdmin,
    nudgeProviderApi,
} from 'src/api/shoppingExperience/account-overview'
import { useKYCCompletionStatus } from 'src/utils/Hooks/useKYCCompletionStatus'
import { proceedKYCLater } from 'src/api/shoppingExperience/get-system'
import { LeftSkeletons, RightSkeletons } from './components/Skeletons'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { removeCountryCodefromNGNNum } from 'src/utils/formatting'
import { useAppContext } from 'src/appContext/AppContext'
import SeoComponent from 'src/components/Seo'
import { decodeUserInfo } from 'src/utils/auth'
import WelcomeModal from './components/WelcomeModal'
import PackageDrawer from './components/PackageDrawer'
import DisputePackageModal from './components/DisputePackageModal'
import RequestUpdateModal from './components/RequestUpdate'
import useUpdateRequests from '../CreateAccount/hooks/useUpdateRequests'
import { decrypt } from 'src/utils/cryptography'
import CookieNotification from 'src/components/CookieNotificiation'
import NPSRatingModal from 'src/components/NPSRatingModal'
import { useUpdateNPSValues } from 'src/utils/handleNPSValues'
import { submitKYCRatingApi } from 'src/api/shoppingExperience/npsRating'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { getBankAnalysisResultApi } from '../CreateAccount/FinancialInformation/FinancialInfoForm/api'
import CreditDecisionInProgressModal from './components/CreditDecisionInProgressModal'
import useLogOut from 'src/utils/Hooks/useLogOut'
import { useCreditDecisionStatus } from './hooks/useCreditDecisionStatus'
import { handleAppTracking, pageTitle, pageTracker, pageUrlName } from './utils'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { useFetchKycModules } from './hooks/useFetchKycModules'
import AddPackageDrawer from 'src/pages/Admin/Profiles/AddPackage/components/AddPackageDrawer'
import ActionModal from 'src/components/ActionModal'
import { useChangePackage } from './hooks/useChangePackage'
import useRunCreditDecision from './hooks/useRunCreditDecision'
import { consumerAttributesMixpanel } from 'src/utils/consumerAttributesMixpanel'
import SecondaryModal from 'src/components/Modal/SecondaryModal'

const AccountSetupOverview = () => {
    const [estimation, setEstimation] = useState({})
    const [kycData, setKycData] = useState({})
    const [action, setAction] = useState('')
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [gettingStartedModalOpen, setGettingStartedModalOpen] =
        useState(false)
    const [confirmEmailModalOpen, setConfirmEmailModalOpen] = useState(false)
    const [showEditInputDrawer, setShowEditInputDrawer] = useState(false)
    const [energyNeeds, setEnergyNeeds] = useState([])
    const [energyInputs, setEnergyInputs] = useState({})
    const [emailError, setEmailError] = useState('')
    const [showToastError, setShowToastError] = useState(false)
    const [showModalToastError, setShowModalToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showDEPConsumerWelcomeModal, setShowDEPConsumerWelcomeModal] =
        useState(false)
    const [showPackageDetailsDrawer, setShowPackageDetailsDrawer] =
        useState(false)
    const [showDisputePackageModal, setShowDisputePackageModal] =
        useState(false)
    const [showRequestUpdateModal, setShowRequestUpdateModal] = useState(false)
    const [showNPSRatingModal, setShowNPSRatingModal] = useState(false)
    const [additionalPayload, setAdditionalPayload] = useState({})
    const [bankStatementAnalysisStatus, setBankStatementAnalysisStatus] =
        useState('')
    const [showSimulateCreditDecision, setShowSimulateCreditDecision] =
        useState(false)
    const [showAddPackageDrawer, setShowAddPackageDrawer] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState([])
    const [showActionModal, setShowActionModal] = useState({
        open: false,
        action: '',
    })
    const [showNewPackageModal, setShowNewPackageModal] = useState(false)

    const { successAlert } = useCustomToast()
    const { isMobile } = useMediaQueries()

    const kycStatus = useKYCCompletionStatus(
        estimation?.id,
        estimation?.use_type,
        estimation?.isDynamicKycUser,
    )
    const {
        showDecisionInProgressModal,
        upfrontDepositPaid,
        ranCreditDecision,
    } = useCreditDecisionStatus(estimation?.id)

    const [email, setEmail] = useState(kycData?.consumer?.user?.email)
    const useType = useTypeMapping[estimation?.use_type || 'RESIDENTIAL']
    const contact = kycData?.consumer?.user?.email !== '' ? 'email' : 'phone'
    const contact_details =
        kycData?.consumer?.user?.email !== ''
            ? kycData?.consumer?.user?.email
            : removeCountryCodefromNGNNum(kycData?.consumer?.user?.phone_number)
    const history = useHistory()
    const { setOverviewInfo, applicationStatus } = useAppContext()
    const [paymentPlanType, setPaymentPlanType] = useState('')
    const userInfo = decodeUserInfo()
    const isDEPConsumer = JSON.parse(userInfo?.isDEPConsumer || false)
    const firstName = userInfo?.firstName || ''
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    // estimation_id from params used by admin
    const { id } = useParams()
    const { requestCategories } = useUpdateRequests()
    const [clusterCode, setClusterCode] = useState('')
    const { npsState, updateNPSAndResetValues } =
        useUpdateNPSValues('kycNPSDisplayed')
    const { logOut } = useLogOut('/')
    const justCompletedKYC = history?.location?.state?.justCompletedKYC ?? false
    const payment_model =
        history.location.state?.payment_model ?? estimation?.paymentType
    const convertedOnboardingType =
        estimation?.onboardingType === 'ALL_SOLUTIONS' ||
        estimation?.onboardingType === 'ENERGY_PROFILE'
            ? 'profiles-or-all-systems'
            : 'custom-build'

    const onboardingType =
        history?.location?.state?.onboardingType ?? convertedOnboardingType
    const [isPromo, setIsPromo] = useState(false)

    const decryptedData =
        localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
        JSON.parse(
            decrypt(localStorage?.getItem('sunfiUserSSEGettingStartedInfo')),
        )
    const hasPaidOutrightly =
        estimation?.isOutrightSaleCustomer &&
        estimation?.approvalStatus === 'APPROVED'

    const { kycModules, kycModulesFetching } = useFetchKycModules(
        estimation?.id,
        isAdminUser,
        estimation?.isDynamicKycUser,
    )

    const showDEPConsumerChangePackageOption =
        process.env.REACT_APP_FLAG_SHOW_REESTIMATE_DEP_CONSUMER_PACKAGE ===
            'true' && estimation.application_status !== 'IN_PROGRESS'

    const {
        runCreditDecisionMutate,
        runCreditDecisionLoading,
        runCreditDecisionToast,
    } = useRunCreditDecision({
        estimationId: estimation?.id,
        onSuccess: () => setAction('admin-submitted-kyc-successfully'),
    })

    // fetch estimation
    const {
        refetch: fetchEstimation,
        isFetching: estimationsFetching,
        data: kyc_data,
    } = useQuery(
        'fetch-estimation',
        () =>
            isAdminUser
                ? retrieveEstimationForAdminApi(id)
                : retrieveEstimationApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setEstimation(formatAccountOverviewData(data, isAdminUser))
                setEnergyNeeds(
                    formatEnergyNeeds(
                        data?.consumer_form_questions?.appliances,
                    ),
                )
                setEnergyInputs(formatEnergyInputs(data))
                setPaymentPlanType(res?.data?.data?.payment_plan)
                setClusterCode(data?.consumer_form_questions?.cluster_code)
                setIsPromo(data?.is_promo_estimation)
                // redirect outright sale customer who hasn't paid
                const isOutrightSaleCustomer =
                    data?.payment_plan === 'Outright Sale'
                const isApproved = data?.approval_status === 'APPROVED'
                if (isOutrightSaleCustomer && !isApproved) {
                    history.push(
                        '/consumer/account-setup/outright-sale-information',
                    )
                }
            },
            onError: error => {
                setShowToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    // fetch kyc data
    const { refetch: kycRefetch, isFetching: kycFetching } = useQuery(
        'kyc',
        () =>
            isAdminUser
                ? getConsumerKYCInfoForAdmin(estimation?.id)
                : getKYCInfo(),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setKycData(data)
            },
            onError: error => {
                setShowToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    // proceed kyc later
    const proceedKYCLaterMutation = useMutation({
        mutationKey: ['proceedKYCLater'],
        mutationFn: () => proceedKYCLater({ email: email }),
        onSuccess: () => {
            if (isDEPConsumer) {
                setShowDEPConsumerWelcomeModal(false)
                setAction('progress-saved-for-dep-consumer')
            } else {
                setConfirmEmailModalOpen(false)
                setAction('progress-saved')
            }
            setActionModalOpen(true)
        },
        onError: error => {
            setShowModalToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    //submit nps rating of the kyc process
    const submitKYCRating = useMutation({
        mutationFn: () => submitKYCRatingApi(additionalPayload, estimation?.id),
        onSuccess: () => {
            setShowNPSRatingModal(false)
            successAlert('Thank you for your feedback!', isMobile ? 320 : 500)
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    // get bank analysis status
    const {
        refetch: bankAnalysisStatusRefetch,
        isFetching: bankAnalysisStatusFetching,
    } = useQuery(
        'get-bank-analysis-status',
        () => getBankAnalysisResultApi(estimation?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const bankStatementAnalysisInfo = data?.data?.data
                if (
                    bankStatementAnalysisInfo?.analysis_context?.status ===
                        'IN_PROGRESS' ||
                    bankStatementAnalysisInfo?.analysis_context?.status ===
                        'RETRYING' ||
                    bankStatementAnalysisInfo?.analysis_context?.status ===
                        'EMPTY' ||
                    bankStatementAnalysisInfo?.analysis_context?.status ===
                        'FAILED'
                ) {
                    setBankStatementAnalysisStatus('inProgress')
                }
                if (
                    bankStatementAnalysisInfo?.document_meta_data
                        ?.invalid_statement === true ||
                    (bankStatementAnalysisInfo?.analysis_context?.status ===
                        'DONE' &&
                        bankStatementAnalysisInfo?.analysis_context
                            ?.statement_complete === false)
                ) {
                    setBankStatementAnalysisStatus('updateRequired')
                }
            },
            onError: error => {
                setShowToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleSubmitKYCRating = () => {
        submitKYCRating.mutate()
        if (
            !isAdminUser &&
            kycStatus?.account_setup_completion_status === true
        ) {
            setAction('submit-kyc')
            setActionModalOpen(true)
        }
    }

    useEffect(() => {
        if (setOverviewInfo) {
            setOverviewInfo({
                estimationId: estimation?.id,
                solutionId: estimation?.solution_id,
                authUserId: kycData?.consumer?.user?.id,
                paymentPlanType: paymentPlanType,
            })
        }
    }, [
        estimation?.id,
        estimation?.solution_id,
        kycData?.consumer?.user?.id,
        history,
        setOverviewInfo,
        paymentPlanType,
        kycData?.consumer?.user?.first_name,
    ])

    useEffect(() => {
        !isAdminUser &&
            kycData &&
            kyc_data &&
            consumerAttributesMixpanel({ ...kycData, ...kyc_data?.data?.data })
    }, [kycData, kyc_data, isAdminUser])

    //save progress
    const handleSaveProgress = () => {
        if (contact === 'email') {
            setAction('save-progress')
            setActionModalOpen(true)
        } else {
            setConfirmEmailModalOpen(true)
        }
    }

    //handle switch use type warning
    const handleSwitchUseTypeWarning = () => {
        setAction('switch-use-type-warning')
        setActionModalOpen(true)
    }

    //handle edit info
    const handleEditInfo = () => {
        setAction('edit-info')
        setActionModalOpen(true)
    }

    const handleCancelEmailModal = () => {
        setConfirmEmailModalOpen(false)
    }

    const handleContinueLaterEmailSubmit = () => {
        continueLaterEmailSchema
            .validate({ email: email }, { abortEarly: false })
            .then(() => {
                setEmailError('')
                proceedKYCLaterMutation.mutate()
            })
            .catch(err => {
                if (err && err.inner && err.inner.length > 0) {
                    setEmailError(err.inner[0].message)
                }
            })
    }

    const afterSaveProgress = () => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseSaveCompletedKyc'].action,
            eventTrackers['sseSaveCompletedKyc'].label,
            eventTrackers['sseSaveCompletedKyc'].category,
        )
    }

    const toggleAddPackageDrawer = () => {
        setShowAddPackageDrawer(prev => !prev)
        showAddPackageDrawer && setSelectedPackage([])
    }

    const handleUpdatePackages = data => {
        setSelectedPackage(data)
        setShowActionModal({
            open: true,
            action: 'confirm',
        })
    }

    const continueKYC = () => {
        setActionModalOpen(false)
        setAction('')
        afterSaveProgress()
    }

    const goToSSEHome = () => {
        setActionModalOpen(false)
        afterSaveProgress()
        logOut()
    }

    const goBackToSystem = () => {
        if (history.location.state?.from) {
            history.push(history.location.state.from, history.location.state)
        } else {
            history.push({
                pathname: `/consumer/view-system/${estimation?.solution_id}`,
                state: {
                    isAuthenticatedUser: true,
                    estimation_id: estimation?.id,
                    action: 'view_from_account_setup',
                    auth_user_id: kycData?.consumer?.user?.id,
                    page: isPromo && 'promo',
                    clusterCode: clusterCode,
                    payment_model,
                    onboardingType,
                    profile_id:
                        kyc_data?.data?.data?.consumer_form_questions
                            ?.energy_profile_id,
                    returnToKyc: estimation?.isDynamicKycUser,
                },
            })
        }
    }
    const handleChangePackage = () => {
        if (isAdminUser) {
            toggleAddPackageDrawer()
        } else {
            handleAppTracking('selectedChangePackage')

            if (isDEPConsumer) {
                setAction(
                    estimation?.consumer_status === 'DECLINED_AND_RE_ESTIMATE'
                        ? 'package-change-request-in-progress'
                        : 'confirm-package-change-request',
                )

                setActionModalOpen(true)
            } else {
                const allSolutions =
                    kyc_data?.data?.data?.onboarding_type === 'ALL_SOLUTIONS'
                const profile =
                    kyc_data?.data?.data?.onboarding_type === 'ENERGY_PROFILE'
                const profile_id =
                    kyc_data?.data?.data?.consumer_form_questions
                        ?.energy_profile_id
                if (allSolutions) {
                    history.push({
                        pathname: '/consumer/all-systems',
                        state: {
                            isAuthenticatedUser: true,
                            estimation_id: estimation?.id,
                            auth_user_id: kycData?.consumer?.user?.id,
                        },
                    })
                } else if (profile) {
                    history.push({
                        pathname: `/consumer/profile-details/${profile_id}`,
                        state: {
                            isAuthenticatedUser: true,
                            estimation_id: estimation?.id,
                            auth_user_id: kycData?.consumer?.user?.id,
                        },
                    })
                } else {
                    history.push({
                        pathname: isPromo
                            ? '/consumer/promos'
                            : '/consumer/systems',
                        state: {
                            isAuthenticatedUser: true,
                            estimation_id: estimation?.id,
                            auth_user_id: kycData?.consumer?.user?.id,
                        },
                    })
                }
            }
        }
    }

    const handleSubmit = () => {
        setActionModalOpen(true)
        setAction('submit-for-credit-decision')
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseSubmitCompletedKyc'].action,
            eventTrackers['sseSubmitCompletedKyc'].label,
            eventTrackers['sseSubmitCompletedKyc'].category,
        )
    }

    const fetching =
        estimationsFetching ||
        kycFetching ||
        bankAnalysisStatusFetching ||
        kycModulesFetching

    const AppliancesFromProvider = () => (
        <p className="ASO_AppliancesText">
            {estimation?.appliances_from_provider?.map(
                (appliance, index, array) => (
                    <React.Fragment key={index}>
                        <span>{appliance?.quantity}</span> {appliance?.name}
                        {index < array.length - 1 && ', '}
                    </React.Fragment>
                ),
            )}
        </p>
    )

    const openActionModal = action => {
        setAction(action)
        setActionModalOpen(true)
    }

    useEffect(() => {
        fetchEstimation()
        if (estimation?.id) {
            kycRefetch()
            bankAnalysisStatusRefetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimation?.id])

    useEffect(() => {
        if (
            isDEPConsumer &&
            Object.keys(estimation).length !== 0 &&
            estimation?.consumer_status === null
        ) {
            if (estimation?.application_status === 'AWAITING_RE_ESTIMATION') {
                setShowNewPackageModal(true)
            } else {
                setShowDEPConsumerWelcomeModal(true)
            }
        }
    }, [isDEPConsumer, estimation])

    useEffect(() => {
        if (justCompletedKYC && !npsState?.kycNPSDisplayed) {
            setShowNPSRatingModal(true)
            updateNPSAndResetValues()
        }
    }, [justCompletedKYC, npsState?.kycNPSDisplayed, updateNPSAndResetValues])

    useEffect(() => {
        if (
            !isAdminUser &&
            !justCompletedKYC &&
            applicationStatus === 'AWAITING_DECISION' &&
            kycStatus?.account_setup_completion_status === true
        ) {
            setAction('submit-kyc')
            setActionModalOpen(true)
        }
    }, [
        applicationStatus,
        justCompletedKYC,
        isAdminUser,
        kycStatus?.account_setup_completion_status,
    ])

    useEffect(() => {
        if (!isAdminUser) handleAppTracking('viewedLP')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleShowPaymentSuccessModal = () => {
        setAction('payment-successful')
        setActionModalOpen(true)
    }

    const { changePackage } = useChangePackage({
        onSuccess: () => {
            setShowActionModal(prev => ({
                ...prev,
                action: 'success',
            }))
        },
    })

    const nudgeProviderMutation = useMutation({
        mutationKey: ['nudge_provider'],
        mutationFn: () => nudgeProviderApi(estimation.id),
        onSuccess: () => {
            setAction('provider-nudged-successfully')
            fetchEstimation()
        },
        onError: err => {
            setShowToastError(true)
            setErrorMessage(errorHandler(err?.response?.data))
        },
    })

    return (
        <>
            {/* modals */}
            <ActionModalV2
                actionModalOpen={actionModalOpen}
                onCancel={() => {
                    setActionModalOpen(false)
                    if (action === 'initiate-dispute') {
                        setShowPackageDetailsDrawer(true)
                    }
                    if (action === 'progress-saved-for-dep-consumer') {
                        goToSSEHome()
                    }
                }}
                title={actionModalTitleMapping(useType)[action]}
                actionType={actionModalIconMapping[action]}
                headerText={actionModalHeaderTextMapping(useType)[action]}
                subTitle={
                    actionModalSubtitleMapping(
                        useType,
                        estimation?.notify_provider_of_reestimation,
                    )[action]
                }
                primaryBtnText={actionModalButtonTextMapping[action]}
                handlePrimaryBtnClick={() => {
                    if (action === 'save-progress') {
                        proceedKYCLaterMutation.mutate()
                    }
                    if (
                        action === 'progress-saved' ||
                        action === 'progress-saved-for-dep-consumer'
                    ) {
                        goToSSEHome()
                    }
                    if (action === 'switch-use-type-warning') {
                        setActionModalOpen(false)
                        setGettingStartedModalOpen(true)
                    }
                    if (action === 'edit-info') {
                        setActionModalOpen(false)
                        setShowEditInputDrawer(true)
                    }
                    if (action === 'submit-for-credit-decision') {
                        history.push('/consumer/account-setup/credit-decision')
                    }
                    if (action === 'initiate-dispute') {
                        setActionModalOpen(false)
                        setShowDisputePackageModal(true)
                    }
                    if (action === 'dispute-submitted') {
                        setActionModalOpen(false)
                    }
                    if (action === 'payment-successful') {
                        setActionModalOpen(false)
                    }

                    if (action === 'submit-kyc') {
                        if (
                            process.env
                                .REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE ===
                            'true'
                        ) {
                            setActionModalOpen(false)
                            setShowSimulateCreditDecision(true)
                        } else {
                            handleSubmit()
                        }
                    }

                    if (action === 'admin-initiate-submit-kyc') {
                        runCreditDecisionMutate()
                    }

                    if (action === 'admin-submitted-kyc-successfully') {
                        setActionModalOpen(false)
                        history.push(
                            `/admin/plans/${estimation?.id}?tab=credit-review`,
                        )
                    }

                    if (action === 'confirm-package-change-request') {
                        setActionModalOpen(false)
                        setShowDisputePackageModal(true)
                    }

                    if (
                        action === 'provider-nudged-successfully' ||
                        action === 'new-package-rejected-successfully' ||
                        action === 'package-change-request-in-progress' ||
                        action === 'package-change-requested-successfully'
                    ) {
                        setActionModalOpen(false)
                    }

                    if (action === 'reject-new-package') {
                        setActionModalOpen(false)
                        setShowDisputePackageModal(true)
                    }
                }}
                primaryBtnLoading={
                    proceedKYCLaterMutation.isLoading ||
                    runCreditDecisionLoading
                }
                toastError={
                    showModalToastError || runCreditDecisionToast?.showToast
                }
                errorMessage={
                    errorMessage || runCreditDecisionToast?.toastMessage
                }
                data-testid="account-setup-overview-action-modal"
                subTitleMaxWidth={action === 'initiate-dispute' ? 290 : 294}
                countdownTimerSeconds={10}
                onTimeEnd={() => goToSSEHome()}
                footerLeftContent={
                    action === 'progress-saved' ||
                    (!estimation?.notify_provider_of_reestimation &&
                        action === 'package-change-request-in-progress')
                        ? 'button'
                        : action === 'admin-initiate-submit-kyc' ||
                          action === 'admin-submitted-kyc-successfully'
                        ? null
                        : 'chat'
                }
                secondaryBtnText={
                    actionModalSecondaryButtonTextMapping(
                        estimation?.notify_provider_of_reestimation,
                    )[action]
                }
                handleSecondaryBtnClick={() => {
                    if (action === 'progress-saved') {
                        continueKYC()
                    }

                    if (action === 'package-change-request-in-progress') {
                        nudgeProviderMutation.mutate()
                    }
                }}
                secondaryBtnLoading={nudgeProviderMutation.isLoading}
            />

            <ActionModal
                actionModalOpen={showActionModal?.open}
                headerText={
                    showActionModal?.action === 'success'
                        ? 'Consumer package changed'
                        : 'You’re about to change the package'
                }
                subTitle={
                    showActionModal?.action === 'success' ? (
                        <>
                            You have successfully changed the consumer’s
                            selected package from{' '}
                            <span
                                style={{
                                    color: '#233862',
                                    fontFamily: 'SF-Pro-Display-Medium',
                                }}
                            >
                                {estimation?.system_name}
                            </span>{' '}
                            to{' '}
                            <span
                                style={{
                                    color: '#233862',
                                    fontFamily: 'SF-Pro-Display-Medium',
                                }}
                            >
                                {selectedPackage[0]?.name}
                            </span>
                            .
                        </>
                    ) : (
                        <>
                            Kindly confirm you want to change the selected
                            package of this consumer from{' '}
                            <span
                                style={{
                                    color: '#233862',
                                    fontFamily: 'SF-Pro-Display-Medium',
                                }}
                            >
                                {estimation?.system_name}
                            </span>{' '}
                            to{' '}
                            <span
                                style={{
                                    color: '#233862',
                                    fontFamily: 'SF-Pro-Display-Medium',
                                }}
                            >
                                {selectedPackage[0]?.name}
                            </span>
                            .
                        </>
                    )
                }
                actionType={
                    showActionModal?.action === 'success'
                        ? 'success'
                        : 'warning'
                }
                noCancelBtn={showActionModal?.action === 'success'}
                actionText={
                    showActionModal?.action === 'success'
                        ? 'Okay, Got it'
                        : 'Confim'
                }
                cancelText={'Go Back'}
                actionHandler={() => {
                    if (showActionModal?.action === 'confirm') {
                        changePackage.mutate({
                            id: estimation.id,
                            payload: {
                                dtc: {
                                    id: selectedPackage[0]?.id,
                                    appliances: selectedPackage[0]?.appliances,
                                    ...(selectedPackage[0]?.payment_plan_types
                                        ?.length > 0 && {
                                        payment_plan_types:
                                            selectedPackage[0]
                                                ?.payment_plan_types,
                                    }),
                                    ...(selectedPackage[0]
                                        ?.subscription_cluster_code !== '' && {
                                        subscription_cluster_code:
                                            selectedPackage[0]
                                                ?.subscription_cluster_code,
                                    }),
                                    ...(selectedPackage[0]
                                        ?.lease_to_own_cluster_code !== '' && {
                                        lease_to_own_cluster_code:
                                            selectedPackage[0]
                                                ?.lease_to_own_cluster_code,
                                    }),
                                },
                            },
                        })
                    } else {
                        if (
                            selectedPackage[0]?.payment_plan_types[0] ===
                            'Outright Sale'
                        ) {
                            history.push(
                                `/admin/outright-sale/${id}/kyc/information`,
                            )
                        } else {
                            setShowActionModal({
                                open: false,
                                action: '',
                            })
                            setSelectedPackage([])
                            toggleAddPackageDrawer()
                            fetchEstimation()

                            if (estimation?.id) {
                                kycRefetch()
                                bankAnalysisStatusRefetch()
                            }
                        }
                    }
                }}
                closable={false}
                closeModal={() =>
                    setShowActionModal({ open: false, action: 'success' })
                }
                toastError={changePackage.isError}
                errorMessage={errorHandler(
                    changePackage.error?.response?.data ||
                        changePackage.error?.message,
                )}
                loading={changePackage.isLoading}
            />

            <SwitchUseType
                showModal={gettingStartedModalOpen}
                setShowModal={setGettingStartedModalOpen}
                useType={
                    useType === 'Residential use'
                        ? 'Business use'
                        : 'Residential use'
                }
                contact={contact}
                contact_details={contact_details}
                id={estimation?.id}
                fetchEstimation={fetchEstimation}
            />

            <ConfirmEmail
                email={email}
                setEmail={setEmail}
                emailError={emailError}
                showModal={confirmEmailModalOpen}
                onCancel={handleCancelEmailModal}
                handleClick={() => handleContinueLaterEmailSubmit()}
                loading={proceedKYCLaterMutation.isLoading}
            />

            <WelcomeModal
                showModal={showDEPConsumerWelcomeModal}
                useType={useType}
                firstName={firstName}
                onCancel={() => {
                    proceedKYCLaterMutation.mutate()
                }}
                onClick={() => {
                    setShowDEPConsumerWelcomeModal(false)
                    setShowPackageDetailsDrawer(true)
                }}
                secondaryLoading={proceedKYCLaterMutation.isLoading}
            />

            <SecondaryModal
                showModal={showNewPackageModal}
                modalWidth={'475px'}
                closable
                onCancel={() => {
                    setShowNewPackageModal(false)
                    setShowPackageDetailsDrawer(true)
                }}
                title="You have a new package"
                primaryBtnText="Proceed"
                v2
                showFooter
                footerLeftContent="chat"
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                content={
                    <div className="NewPackageModal">
                        <NewPackageIcon />
                        <h3>New Package</h3>
                        <p>
                            A new package has been suggested to you by your
                            provider. Kindly look through it.
                        </p>
                    </div>
                }
                handlePrimaryBtnClick={() => {
                    setShowNewPackageModal(false)
                    setShowPackageDetailsDrawer(true)
                }}
            />

            <DisputePackageModal
                showModal={showDisputePackageModal}
                onCancel={() => {
                    setShowDisputePackageModal(false)
                    setShowPackageDetailsDrawer(true)
                }}
                onClick={() => {
                    const _action =
                        estimation.consumer_status === null
                            ? estimation.application_status ===
                              'AWAITING_RE_ESTIMATION'
                                ? 'new-package-rejected-successfully'
                                : 'dispute-submitted'
                            : 'package-change-requested-successfully'
                    setAction(_action)
                    setShowDisputePackageModal(false)
                    setActionModalOpen(true)
                    fetchEstimation()
                }}
                estimation_id={estimation?.id}
                showDEPConsumerChangePackageOption={
                    showDEPConsumerChangePackageOption
                }
            />

            <RequestUpdateModal
                showModal={showRequestUpdateModal}
                closeFunc={() => setShowRequestUpdateModal(false)}
                estimation_id_for_admin={id}
            />

            <NPSRatingModal
                showModal={showNPSRatingModal}
                closeModal={() => {
                    setShowNPSRatingModal(false)
                    if (
                        !isAdminUser &&
                        kycStatus?.account_setup_completion_status === true
                    ) {
                        setAction('submit-kyc')
                        setActionModalOpen(true)
                    }
                }}
                feature={'completed-kyc'}
                setAdditionalPayload={setAdditionalPayload}
                handleClick={handleSubmitKYCRating}
                btnLoading={submitKYCRating.isLoading}
            />

            {!isAdminUser ? (
                <CreditDecisionInProgressModal
                    displayName={kycData?.consumer?.user?.first_name ?? ''}
                    isAdminUser={isAdminUser}
                    showDecisionInProgressModal={showDecisionInProgressModal}
                />
            ) : null}

            {/* drawers */}
            <EditApplianceInput
                showEditInputDrawer={showEditInputDrawer}
                hideDrawer={() => setShowEditInputDrawer(false)}
                energyNeeds={energyNeeds}
                energyInputs={energyInputs}
                setShowEditModal={setActionModalOpen}
                action={'update_from_account_setup'}
                auth_user_id={kycData?.consumer?.user?.id}
                test-dataid="account-setup-overview-edit-appliance-inputs-drawer"
                knowGenMonthlyCost={decryptedData?.knowsGenMonthlyCost}
            />

            {isAdminUser && (
                <AddPackageDrawer
                    selectedPackages={selectedPackage}
                    showAddPackageModal={showAddPackageDrawer}
                    setShowAddPackageModal={toggleAddPackageDrawer}
                    handleAddPackagesClick={handleUpdatePackages}
                    isOnePackage
                />
            )}

            <PackageDrawer
                open={showPackageDetailsDrawer}
                estimation={estimation}
                goBackToSystem={goBackToSystem}
                handleDisputePackage={() => {
                    setAction(
                        process.env
                            .REACT_APP_FLAG_SHOW_REESTIMATE_DEP_CONSUMER_PACKAGE ===
                            'true' &&
                            estimation?.consumer_status === null &&
                            estimation?.application_status ===
                                'AWAITING_RE_ESTIMATION'
                            ? 'reject-new-package'
                            : 'initiate-dispute',
                    )
                    setActionModalOpen(true)
                    setShowPackageDetailsDrawer(false)
                }}
                handleAcceptPackage={() => {
                    setShowPackageDetailsDrawer(false)
                    fetchEstimation()
                }}
                AppliancesFromProvider={AppliancesFromProvider}
                hasPaidOutrightly={hasPaidOutrightly}
            />

            {/*page*/}
            <SeoComponent title={pageTitle} />
            {showToastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                    data-testid="account-setup-overview-toast"
                />
            )}
            <AccountSetupNav
                handleSaveProgress={() => handleSaveProgress()}
                handleSubmit={() => handleSubmit()}
                completed={kycStatus?.account_setup_completion_status}
                hasPaidOutrightly={hasPaidOutrightly}
                showSimulateCreditDecision={showSimulateCreditDecision}
                setShowSimulateCreditDecision={setShowSimulateCreditDecision}
                estimationId={estimation?.id}
                openActionModal={openActionModal}
                consumerName={
                    kycData.consumer?.user
                        ? `${kycData.consumer.user.first_name || ''} ${
                              kycData.consumer?.user?.last_name || ''
                          }`
                        : ''
                }
                ranCreditDecision={ranCreditDecision}
            />
            <div
                className={`ASOContentWrapper ${
                    isAdminUser ? 'ASOContentWrapper_Admin' : ''
                }`}
            >
                {hasPaidOutrightly ? (
                    <VerticalSpacer top={96} />
                ) : (
                    <TopColumn
                        kycStatus={kycStatus}
                        useType={useType}
                        solution_id={estimation?.solution_id}
                        goBackToSystem={goBackToSystem}
                        hideBackNav={isDEPConsumer}
                        handleRequestUpdate={() =>
                            setShowRequestUpdateModal(true)
                        }
                        estimation_id_for_admin={id}
                        isDynamicKycUser={estimation?.isDynamicKycUser}
                        fetching={fetching}
                    />
                )}

                {fetching ? (
                    <div className={'ASOMidContent'}>
                        <LeftSkeletons />
                        <RightSkeletons />
                    </div>
                ) : (
                    <div className={'ASOMidContent'}>
                        <SelectedSolution
                            estimation={estimation}
                            handleEditInfo={() => handleEditInfo()}
                            handleChangePackage={handleChangePackage}
                            goBackToSystem={goBackToSystem}
                            isDEPConsumer={isDEPConsumer}
                            AppliancesFromProvider={AppliancesFromProvider}
                            systemAccepted={
                                estimation?.consumer_status ===
                                'CONSUMER_ACCEPT_PACKAGE'
                            }
                            systemDisputed={
                                estimation?.consumer_status ===
                                'DECLINED_AND_RE_ESTIMATE'
                            }
                            isAdminUser={isAdminUser}
                            isPromo={isPromo}
                            upfrontDepositPaid={upfrontDepositPaid}
                            hasPaidOutrightly={hasPaidOutrightly}
                        />
                        <CreateAccount
                            useType={useType}
                            handleSwitchUseTypeWarning={
                                handleSwitchUseTypeWarning
                            }
                            kycStatus={kycStatus}
                            isDEPConsumer={isDEPConsumer}
                            isDTCConsumer={
                                kyc_data?.data?.data?.survey_type === 'CFS'
                            }
                            estimation_id={estimation?.id}
                            requestCategories={requestCategories}
                            bankStatementAnalysisStatus={
                                bankStatementAnalysisStatus
                            }
                            estimation={estimation}
                            handleShowPaymentSuccessModal={
                                handleShowPaymentSuccessModal
                            }
                            hasPaidOutrightly={hasPaidOutrightly}
                            isDynamicKycUser={estimation?.isDynamicKycUser}
                            kycAppModules={kycModules}
                        />
                    </div>
                )}
            </div>
            <CookieNotification />
            {hasPaidOutrightly && <VerticalSpacer top={330} />}
            <AccountSetupFooter />
        </>
    )
}

export default AccountSetupOverview
