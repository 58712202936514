import { format } from 'date-fns'
import { useState, useRef, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
    useSSEConsumerIdentityCompare,
    useSSEConsumerIdentityUpload,
    useSSESelfieUpload,
} from 'src/api/shoppingExperience/identity-verification/queries'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import { appTracking } from 'src/utils/appTracker'
import { errorHandler } from 'src/utils/errorHandler'
import { eventTrackers } from 'src/utils/eventTrackers'
import { dataURIToBlob } from 'src/utils/imageBlob'
import { removeEmptyValues } from 'src/utils/removeEmptyValues'
import { validateIdentityInfoKyc } from 'src/utils/validationSchema'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { handleAppTrackingOnKYC } from '../utils/utils'
import { formatInputValue } from 'src/utils/formatting'

export const useUploadImage = (
    customCloseModal = undefined,
    onSelfieUploadSuccess,
) => {
    const history = useHistory()
    const [step, setStep] = useState(1)
    const [selected, setSelected] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [showIdentityModal, setShowIdentityModal] = useState(false)
    let buttonState
    const [infoSuccess, setInfoSucess] = useState(false)
    const [modalRIDType, setModalRIDType] = useState('warning')
    const [toast, setToast] = useState({ type: '', message: '' })
    const [inputs, setInputs] = useState({
        identityType: '',
        identityNumber: '',
        identityVerified: false,
        imageUrl: '',
        firstName: '',
        lastName: '',
        id: '',
        date_of_birth: '',
    })
    const [actionModal, setActionModal] = useState({ open: false, type: '' })
    const [fieldErrors, setFieldErrors] = useState({})
    const { errorAlert } = useCustomToast()
    // image states
    const [uploading, setUploading] = useState(false)
    const [successUpload, setSuccessUpload] = useState([])
    const [width, setWidth] = useState(0)
    const [pictureUrl, setPictureUrl] = useState('')
    const [fileList, setFileList] = useState([])
    const pageTitle =
        'KYC - Identity Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const [verificationFailedModal, setVerificationFailedModal] =
        useState(false)
    const [verificationFailed, setVerificationFailed] = useState(false)
    const [identityToast, setIdentityToast] = useState('')
    const [refetchIds, setRefetchIds] = useState(false)
    const [simulateLoading, setSimulateLoading] = useState(false)
    // queries
    // fetch info
    const { sseBasicInfoRefetch, sseBasicInfoFetching } =
        useSSERetrieveBasicInfo({
            enabled: false,
            onSuccess: data => {
                setInputs(
                    refetchIds
                        ? {
                              ...inputs,
                              imageUrl: data?.consumer?.user?.avatar_url ?? '',
                              firstName: data?.consumer?.user?.first_name ?? '',
                              lastName: data?.consumer?.user?.last_name ?? '',
                              id: data?.identity_verification?.id ?? '',
                              phoneNumber: data?.consumer?.user?.phone_number,
                              email: data?.consumer?.user?.email,
                              date_of_birth:
                                  data?.consumer?.user?.date_of_birth ?? '',
                          }
                        : {
                              identityType:
                                  data?.identity_verification?.type ?? '',
                              identityNumber:
                                  data?.identity_verification?.number ?? '',
                              identityVerified:
                                  data?.identity_verification?.verified ??
                                  false,
                              imageUrl: data?.consumer?.user?.avatar_url ?? '',
                              firstName: data?.consumer?.user?.first_name ?? '',
                              lastName: data?.consumer?.user?.last_name ?? '',
                              id: data?.identity_verification?.id ?? '',
                              phoneNumber: data?.consumer?.user?.phone_number,
                              email: data?.consumer?.user?.email,
                              date_of_birth:
                                  data?.consumer?.user?.date_of_birth ?? '',
                          },
                )
                setModalRIDType('warning')
                setFieldErrors({})
            },
            onError: err => {
                setToast({
                    type: 'error-secondary',
                    message: errorHandler(err),
                })
                setModalRIDType('warning')
                setFieldErrors({})
            },
        })

    // fetch info when page loads
    useEffect(() => {
        sseBasicInfoRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        submitKycResidentialIdentityCompare,
        kycResidentialIdentityCompareLoading,
    } = useSSEConsumerIdentityCompare({
        onSuccess: () => {
            setRefetchIds(false)
            setTimeout(() => {
                sseBasicInfoRefetch()
            }, 150)
            setToast({
                type: 'success-secondary',
                message: 'Identity Information Submitted Successfully',
            })
            setShowIdentityModal(false)
            setInfoSucess(true)
            setFieldErrors({})
            setTimeout(() => {
                history.push(
                    '/consumer/account-setup/residential-financial-information',
                )
            }, 1000)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSubmitIdentityInfoSuccessfully'].action,
                eventTrackers['sseSubmitIdentityInfoSuccessfully'].label,
                eventTrackers['sseSubmitIdentityInfoSuccessfully'].category,
            )
            handleAppTrackingOnKYC('completedKYCCategory', pageTitle)
            handleAppTrackingOnKYC('completedTwoKYCCategories', pageTitle)
        },
        onError: err => {
            setToast({
                type: 'error-secondary',
                message: errorHandler(err),
            })
        },
    })

    const {
        submitKycResidentialIdentity,
        kycResidentialIdentityLoading,
        kycResidentialIdentityError,
    } = useSSEConsumerIdentityUpload({
        onSuccess: data => {
            if (buttonState !== 'Save' && data?.verified) {
                setShowIdentityModal(false)
                setActionModal({ open: true, type: 'success', errMessage: '' })
                setInputs(prev => ({
                    ...prev,
                    identityVerified: data?.verified,
                }))
            } else if (buttonState !== 'Save' && data?.ivs_server_error) {
                setVerificationFailedModal(true)
                setVerificationFailed(true)
            } else {
                setIdentityToast(data?.error_message)
                setModalRIDType('countdown-timer')
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['sseSaveBasicInfoSuccessfully'].action,
                    eventTrackers['sseSaveBasicInfoSuccessfully'].label,
                    eventTrackers['sseSaveBasicInfoSuccessfully'].category,
                )
            }
        },
        onError: err => {
            if (buttonState !== 'Save') {
                setIdentityToast(
                    err?.message ||
                        'Something went wrong, please try again later',
                )
            }
        },
    })
    const { submitKycSelfie, kycSelfieLoading, kycSelfieError } =
        useSSESelfieUpload({
            onSuccess: () => {
                setRefetchIds(true)
                setTimeout(() => {
                    sseBasicInfoRefetch()
                }, 150)
                setToast({
                    type: 'success-secondary',
                    message: 'Image uploaded successfully',
                })
                setShowModal(false)
                onSelfieUploadSuccess?.()
                step !== 3 && handleRetake()
                step === 3 && setSelected('')
                step === 3 && setStep(1)
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['sseSubmitIdentityImageSuccessfully'].action,
                    eventTrackers['sseSubmitIdentityImageSuccessfully'].label,
                    eventTrackers['sseSubmitIdentityImageSuccessfully']
                        .category,
                )
            },
            onError: err => {
                errorAlert(
                    err?.message?.length > 0
                        ? err?.message
                        : 'Please Reupload image',
                )
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['sseSubmitIdentityImageUnsuccessful'].action,
                    eventTrackers['sseSubmitIdentityImageUnsuccessful'].label,
                    eventTrackers['sseSubmitIdentityImageUnsuccessful']
                        .category,
                )
            },
        })

    const { uploadToS3Loading, uploadToS3Mutate, uploadToS3Error } =
        useUploadToS3({
            onSuccess: data => {
                submitKycSelfie({
                    selfie_url: data,
                })
                buttonState = ''
            },
            onError: err => {
                setToast({
                    type: 'error-secondary',
                    message: errorHandler(err),
                })
            },
        })

    const uploadImageToS3 = canvas => {
        uploadToS3Mutate({
            category: 'AVATARS',
            file: {
                ...successUpload[0],
                type: successUpload[0]?.type || 'image/png',
                name:
                    successUpload[0]?.name ||
                    `selfie uploaded ${format(new Date(), 'd-MM-yyyy')}.png`,
                originFileObj: dataURIToBlob(canvas),
            },
        })
    }

    const persistResidentialIdentityInfo = button => {
        setIdentityToast('')
        buttonState = button
        const identityPayload = removeEmptyValues({
            button: button,
            first_name: inputs?.firstName,
            last_name: inputs?.lastName,
            number: inputs?.identityNumber,
            type: inputs?.identityType,
            ...(inputs.identityType === 'Drivers License' && {
                date_of_birth: inputs.date_of_birth,
            }),
        })

        validateIdentityInfoKyc
            .validate(
                { button, ...inputs },
                {
                    abortEarly: false,
                },
            )
            .then(() => {
                if (button === 'Compare') {
                    // call this when the consumer makes the final submission by clicking on the submit button
                    submitKycResidentialIdentityCompare({ id: inputs?.id })
                } else if (
                    button === 'Save' &&
                    inputs?.identityType &&
                    inputs?.identityNumber
                ) {
                    // call this when the consumer clicks on the save and continue later and when the indetity info has been filled
                    submitKycResidentialIdentity(identityPayload)
                } else if (button === 'Submit') {
                    // call this when the consumer submits the identity info after filling all the required fields
                    submitKycResidentialIdentity(identityPayload)
                } else {
                    // call this when the consumer clicks on the continue save later button even if no required information was provided
                    setSimulateLoading(true)
                    setTimeout(() => {
                        setModalRIDType('countdown-timer')
                        setSimulateLoading(false)
                    }, 2000)
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setFieldErrors(errList)
                errorAlert('You omitted a required field.')
            })
    }
    const handleInputChange = event => {
        const { name, value } = event.target
        name !== 'date_of_birth' && event.preventDefault()
        setInputs(preState => ({
            ...preState,
            [name]:
                name === 'date_of_birth'
                    ? format(new Date(value), 'd-MM-yyyy')
                    : formatInputValue(name, value),
        }))
        setFieldErrors(preState => ({
            ...preState,
            [name]: '',
        }))
    }

    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    const handleSelectChange = (name, value) => {
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
        setFieldErrors(preState => ({
            ...preState,
            [name]: '',
        }))
    }

    const handleRetake = () => {
        setSelected('')
        setStep(1)
        setPictureUrl('')
        setSuccessUpload([])
        setFileList([])
        setWidth(0)
    }

    const closeModal = () => {
        if (customCloseModal) {
            customCloseModal()
        } else setShowModal(false)
        step !== 3 && handleRetake()
        step === 3 && setSelected('')
        step === 3 && setStep(1)
    }

    const uploadSelfie = img => {
        setPictureUrl(img)
    }

    const webcamRef = useRef(null)
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot()
        uploadSelfie(imageSrc)
        setUploading(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webcamRef])

    useEffect(() => {
        if (width === 100) {
            setUploading(false)
            setStep(3)
        }
    }, [width])

    const modalTitle =
        step === 1
            ? 'How do you want to add a picture'
            : selected === 'upload' && step === 2
            ? 'Upload your picture'
            : 'Add your photo'

    const modalFooterLeftContent =
        step === 2 && selected === 'selfie'
            ? ''
            : step === 3
            ? 'button'
            : 'chat'
    const modalPrimaryBtnText =
        step === 2 && selected === 'selfie'
            ? 'Capture Photo'
            : step === 3
            ? 'Submit'
            : 'Continue'

    const IdentitySelfieRef = useRef(null)

    const handleImageSteps = () => {
        if (step === 1 && selected?.length > 0) {
            setStep(2)
        } else if (step === 2 && selected === 'selfie') {
            capture()
        } else if (step === 2 && selected === 'upload') {
            setUploading(true)
        } else if (step === 3) {
            const canvas = IdentitySelfieRef.current
                .getImageScaledToCanvas()
                .toDataURL()
            uploadImageToS3(canvas)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSubmitIdentityImage'].action,
                eventTrackers['sseSubmitIdentityImage'].label,
                eventTrackers['sseSubmitIdentityImage'].category,
            )
        }
    }

    const persistResidentialIdentityInfoLoading =
        kycResidentialIdentityLoading || simulateLoading

    const selfieLoading = kycSelfieLoading || uploadToS3Loading

    const persistResidentialIdentityInfoSuccess = infoSuccess
    const errorResidentialIdentityInfo =
        kycResidentialIdentityError?.response?.data ||
        errorHandler(kycSelfieError?.response?.data) ||
        errorHandler(uploadToS3Error?.response?.data)

    const disableModalPrimaryBtn =
        (selected === '' && step === 1) ||
        uploading ||
        (step === 3 && persistResidentialIdentityInfoLoading) ||
        (step === 2 && selected === 'upload' && successUpload.length === 0)

    return {
        persistResidentialIdentityInfo,
        persistResidentialIdentityInfoLoading,
        persistResidentialIdentityInfoSuccess,
        errorResidentialIdentityInfo,
        sseBasicInfoRefetch,
        sseBasicInfoFetching,
        toast,
        setToast,
        step,
        setStep,
        selected,
        setSelected,
        inputs,
        setInputs,
        showModal,
        setShowModal,
        uploading,
        successUpload,
        setSuccessUpload,
        pictureUrl,
        setPictureUrl,
        fileList,
        setFileList,
        width,
        setWidth,
        handleInputChange,
        handleBlur,
        handleSelectChange,
        closeModal,
        modalTitle,
        modalFooterLeftContent,
        modalPrimaryBtnText,
        disableModalPrimaryBtn,
        handleImageSteps,
        webcamRef,
        capture,
        handleRetake,
        uploadImageToS3,
        showIdentityModal,
        setShowIdentityModal,
        fieldErrors,
        actionModal,
        setActionModal,
        selfieLoading,
        modalRIDType,
        setModalRIDType,
        kycResidentialIdentityCompareLoading,
        IdentitySelfieRef,
        verificationFailedModal,
        setVerificationFailedModal,
        identityToast,
        setIdentityToast,
        verificationFailed,
        setVerificationFailed,
        verificationInProgress: kycResidentialIdentityLoading,
    }
}
