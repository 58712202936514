import { convertToSentenceCase } from 'src/utils/formatting'

export const filterOptions = data => {
    return [
        {
            title: 'Filter by system size',
            name: 'system_size',
            type: 'range',
            min: data?.min_system_size,
            max: data?.max_system_size,
            isActive:
                data?.max_system_size > 0 &&
                data?.min_system_size !== data?.max_system_size,
        },
        {
            title: 'Filter by cost',
            name: 'cost',
            type: 'range',
            min: data?.min_cost,
            max: data?.max_cost,
            isActive: data?.max_cost > 0 && data?.min_cost !== data?.max_cost,
        },
        {
            title: 'Filter by payment plan type',
            name: 'payment_plan',
            options: data?.payment_plan?.map(item =>
                convertToSentenceCase(item.replaceAll('_', ' ').toLowerCase()),
            ),
            type: 'radio',
            isActive: data?.payment_plan?.length > 1 ? true : false,
        },
        {
            title: 'Filter by system type',
            name: 'system_type',
            options: data?.system_types?.map(item =>
                convertToSentenceCase(item.replaceAll('_', ' ')),
            ),
            type: 'radio',
            isActive: data?.system_types?.length > 1 ? true : false,
        },
        {
            title: 'Filter by battery type',
            name: 'battery_type',
            options: data?.battery_types?.map(item =>
                convertToSentenceCase(item.replaceAll('_', ' ')),
            ),
            type: 'radio',
            isActive: data?.battery_types?.length > 1 ? true : false,
        },
        {
            // TODO: this would be reverted if the DT & BE conflicts on the title here
            // title: 'Filter by system daily backup hours',
            title: 'Filter by system backup hours',
            name: 'backup_hours',
            // TODO: this would be reverted if the PM & BE conflicts on the type this filter should be
            // options: ['9-10 hours daily', '12-14 hours daily'],
            // type: 'radio',
            type: 'range',
            min: data?.min_backup_hour,
            max: data?.max_backup_hour,
            isActive:
                data?.max_backup_hour > 0 &&
                data?.min_backup_hour !== data?.max_backup_hour,
        },
        // TODO: remove this from based on pm requirement
        // {
        //     title: 'Filter by backup system options',
        //     name: 'backup_system',
        //     options: ['Battery', 'Solar panels'],
        //     type: 'radio',
        // },
    ]
}
