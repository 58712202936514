import { useHistory, useParams } from 'react-router-dom'
import { useFetchKycModules } from 'src/pages/Consumer/SmartShoppingExperience/AccountSetupOverview/hooks/useFetchKycModules'
import {
    formatOfferData,
    getRequiredKeys,
    registeredBusinessOptions,
} from '../utils'
import useOfferInfo from '../../OutrightSaleInformation/hooks/useOfferInfo'
import { useEffect, useState } from 'react'
import { sameAddressOptions } from '../../OutrightSaleInformation/utils'
import {
    formatInputValue,
    formatPhoneNumber,
    includeHttps,
} from 'src/utils/formatting'
import {
    otpSchema,
    validateDynamicKycInfoKyc,
    verifyContactSchema,
} from 'src/utils/validationSchema'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import useFetchKycInfo from './useFetchKycInfo'
import { useInitiateVerification } from '../../OutrightSaleInformation/hooks/useVerifyContact'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { useSSEAuthValidateOtp } from 'src/api/shoppingExperience/auth/queries'
import { errorHandler } from 'src/utils/errorHandler'
import { useQueryClient } from 'react-query'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import { removeEmptyValues } from 'src/utils/removeEmptyValues'
import usePersistKycInfo from './usePersistKycInfo'
import { format } from 'date-fns'
import {
    fullEmploymentOptions,
    retiredOptions,
} from 'src/utils/employmentStatus'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import { removeCommaFromNum } from 'src/utils/NumberFormatter'
import { decodeUserInfo } from 'src/utils/auth'
import { useKYCCompletionStatus } from 'src/utils/Hooks/useKYCCompletionStatus'
import { useSSEConsumerIdentityCompare } from 'src/api/shoppingExperience/identity-verification/queries'
import { useUploadAuditReport } from '../../FinancialInformation/FinancialInfoForm/api'
import { repaymentMethodMap } from 'src/utils/repaymentMethods'

const useDynamicInformation = estimationId => {
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        phoneVerified: false,
        email: '',
        emailVerified: false,
        contactAddress: '',
        contactState: '',
        contactCity: '',
        contactCountry: '',
        sameAddress: sameAddressOptions[0],
        installationAddress: '',
        installationCountry: '',
        installationState: '',
        installationCity: '',
        employmentType: '',
        companyName: '',
        jobTitle: '',
        jobStartDate: '',
        employmentContractUrl: '',
        employmentSuccessUpload: { name: '', originFileObj: '', type: '' },
        linkedinPage: '',
        netMonthlySalary: '',
        employeeId: '',
        registeredBusiness: registeredBusinessOptions[0],
        businessName: '',
        businessIndustry: '',
        cacNumber: '',
        cacNumberVerified: false,
        numberOfYearsInOperation: '',
        businessWebsite: '',
        businessDescription: '',
        pensionManager: '',
        retirementDate: '',
        selfieImageUrl: '',
        identityType: '',
        identityNumber: '',
        identityVerificationId: '',
        identityVerified: false,
        bvn: '',
        bvnVerified: false,
        bankStatements: [],
    })
    const [errors, setErrors] = useState({})
    const [showRemoveFileModal, setShowRemoveFileModal] = useState(false)
    const [fileToBeRemoved, setFileToBeRemoved] = useState({
        key: '',
        title: '',
        url: '',
        fileType: '',
    })
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [employmentContract, setEmploymentContract] = useState([])
    const [showCalendar, setShowCalendar] = useState(false)
    const [
        successEmploymentContractUpload,
        setSuccessEmploymentContractUpload,
    ] = useState([])
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [action, setAction] = useState('') // save-progress | progress-saved
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [actionModalToast, setActionModalToast] = useState(toastData)
    const [showVerifyContactModal, setShowVerifyContactModal] = useState(false)
    const [contactTypeToVerify, setContactTypeToVerify] = useState('') // email || phone
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const [verifyContactToast, setVerifyContactToast] = useState(toastData)
    const [saveOrSubmitAction, setSaveOrSubmitAction] = useState('') // Save || Submit || Submit-id-card-info
    const [requiredKycSections, setRequiredKycSections] = useState([])
    const [showCacVerifyModal, setShowCacVerifyModal] = useState(false)
    const [cacVerificationInProgress, setCacVerificationInProgress] =
        useState(false)
    const [showSelfieImageModal, setShowSelfieImageModal] = useState(false)
    const [showIdVerifyModal, setShowIdVerifyModal] = useState(false)
    const [idVerificationInProgress, setIdVerificationInProgress] =
        useState(false)
    const [showIdOverrideModal, setShowIdOverrideModal] = useState(false)
    const [idVerificationFailedModal, setIdVerificationFailedModal] =
        useState(false)
    const [showBVNModal, setShowBVNModal] = useState(false)
    const [bvnVerificationInProgress, setBvnVerificationInProgress] =
        useState(false)

    const { id } = useParams()
    const history = useHistory()
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const [showVideoModal, setShowVideoModal] = useState(!isAdminUser)
    const [showVideo, setShowVideo] = useState(false)
    const { kycData, kycInfoFetching } = useFetchKycInfo(
        inputs,
        setInputs,
        estimationId,
    )
    const { offer, offerFetching } = useOfferInfo(formatOfferData, estimationId)
    const kycStatus = useKYCCompletionStatus(
        offer?.offerId,
        offer?.userType,
        true,
    )
    const onModulesFetchSuccess = data => {
        const requiredKeys = getRequiredKeys(data)
        setRequiredKycSections(requiredKeys)
    }
    const { kycModules, refetchKycModules, kycModulesFetching } =
        useFetchKycModules(
            offer?.offerId,
            isAdminUser,
            true,
            onModulesFetchSuccess,
        )
    const validateField = useValidateOnBlur()
    const { successAlert, errorAlert, BVNAlert } = useCustomToast()
    const queryClient = useQueryClient()

    const formattedContactTypeToVerify =
        contactTypeToVerify.charAt(0).toUpperCase() +
        contactTypeToVerify.slice(1).toLowerCase()
    const findCurrentKycModule = (id, data) => {
        return data.find(item => item.id === id)
    }
    const kycModule = findCurrentKycModule(id, kycModules) || null
    const adminSelectedEmploymentType = kycModule?.section?.find(
        item => item?.key === 'employment_type',
    )?.value

    const { sseValidateAuthOtp, sseValidateAuthOtpLoading } =
        useSSEAuthValidateOtp({
            onSuccess: () => {
                successAlert(
                    `${formattedContactTypeToVerify} verified successfully`,
                )
                setOtp(['', '', '', '', '', ''])
                setShowVerifyContactModal(false)
                queryClient.invalidateQueries('fetch-dynamic-kyc-info')
                setContactTypeToVerify('')
            },
            onError: error => {
                setVerifyContactToast({
                    showToast: true,
                    toastMessage:
                        error?.[0] ||
                        errorHandler(error?.response?.data) ||
                        'Unable to verify otp, please try again',
                    messageType: 'error-secondary',
                })
                resetToast()
            },
        })

    const resetToast = () => {
        setTimeout(() => {
            setVerifyContactToast(toastData)
            setActionModalToast(toastData)
        }, 2000)
    }

    const moduleTitleMapping = {
        personal_information: 'Personal information',
        employment_information: 'Employment information',
        identity_information: 'Identity information',
        financial_information: 'Financial information',
    }

    const handleModuleSubmissionOnSuccess = () => {
        const sortedKycModules = kycModules.sort((a, b) => a.order - b.order)
        const currentIndex = sortedKycModules.findIndex(
            module => module.id === kycModule?.id,
        )

        if (currentIndex === -1) {
            return
        }
        const isLastModule = currentIndex === sortedKycModules.length - 1

        successAlert(
            `${moduleTitleMapping[kycModule?.key]} submitted successfully`,
        )
        if (isLastModule) {
            setTimeout(() => {
                history.push({
                    pathname: '/consumer/account-setup/overview',
                    state: { justCompletedKYC: true },
                })
            }, 3000)
        } else {
            const nextModuleId = sortedKycModules[currentIndex + 1].id
            setTimeout(() => {
                history.push(`/consumer/account-setup/${nextModuleId}`)
                queryClient.invalidateQueries('fetch-dynamic-kyc-info')
            }, [3000])
        }
    }

    const onSubmitIdCardInfoSuccess = res => {
        const data = res?.data?.data
        if (data?.verified) {
            setShowIdVerifyModal(false)
            queryClient.invalidateQueries('fetch-dynamic-kyc-info')
            successAlert('ID card information submitted successfully')
            setSaveOrSubmitAction('')
        } else {
            if (data?.ivs_server_error) {
                setIdVerificationFailedModal(true)
                return
            }

            if (data?.error_message?.length) {
                setActionModalToast({
                    showToast: true,
                    toastMessage: data?.error_message,
                    messageType: 'error-secondary',
                })
                resetToast()
                return
            }
        }
    }

    const { persistInfoMutate, persistInfoLoading } = usePersistKycInfo(
        saveOrSubmitAction,
        () => setAction('progress-saved'),
        () => handleModuleSubmissionOnSuccess(),
        setActionModalToast,
        resetToast,
        kycModule?.key,
        onSubmitIdCardInfoSuccess,
    )

    const {
        submitKycResidentialIdentityCompare,
        kycResidentialIdentityCompareLoading,
    } = useSSEConsumerIdentityCompare({
        onSuccess: () => {
            handleModuleSubmissionOnSuccess()
        },
        onError: error => {
            errorAlert(
                errorHandler(
                    error?.response?.data ||
                        'An error occurred, please try again later.',
                ),
            )
        },
    })

    const {
        mutate: updateFinancialInfo,
        isLoading: updateFinancialInfoLoading,
    } = useUploadAuditReport()

    const verifyContactsModalInputs = {
        phone: inputs?.phone,
        email: inputs?.email,
    }

    const {
        initiateVerificationMutate,
        initiateEmailVerificationLoading,
        initiatePhoneVerificationLoading,
        initiateVerificationResent,
        initiateVerificationSetResent,
    } = useInitiateVerification(
        contactTypeToVerify,
        verifyContactsModalInputs,
        setShowVerifyContactModal,
        setVerifyContactToast,
        resetToast,
    )

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const initiateContactVerification = async type => {
        try {
            await verifyContactSchema
                .validate(
                    { phone: inputs?.phone, email: inputs?.email },
                    { context: { type: type }, abortEarly: false },
                )
                .then(() => {
                    const payload = {
                        [type === 'email' ? 'email' : 'phone_number']:
                            type === 'email'
                                ? inputs?.email
                                : formatPhoneNumber(inputs?.phone, '+234'),
                    }
                    setContactTypeToVerify(type)
                    initiateVerificationMutate(payload)
                    removeErrors(`${type}Verified`)
                })
        } catch (err) {
            const errorObj = {}
            err.inner.forEach(error => {
                errorObj[error.path] = error.message
            })

            setErrors(prev => ({ ...prev, ...errorObj }))
        }
    }

    const closeVerifyContactModal = () => {
        setShowVerifyContactModal(false)
        setContactTypeToVerify('')
    }

    const handleResendOtp = () => {
        const payload = {
            [contactTypeToVerify === 'email' ? 'email' : 'phone_number']:
                contactTypeToVerify === 'email' ? inputs?.email : inputs?.phone,
        }
        initiateVerificationMutate(payload)
    }

    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                const phoneNumber = {
                    phone_number: formatPhoneNumber(inputs?.phone, '+234'),
                    code: otp.join(''),
                }
                const email = {
                    email: inputs?.email,
                    code: otp.join(''),
                }
                sseValidateAuthOtp(
                    contactTypeToVerify === 'email' ? email : phoneNumber,
                )
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    const pageLoading = kycInfoFetching || kycModulesFetching || offerFetching

    const validationSchemaContext = {
        sameAddress: inputs.sameAddress?.value,
        moduleKey: kycModule?.key,
        requiredKycSections: requiredKycSections,
        isRegisteredBusiness: inputs?.registeredBusiness?.value,
        identityType: inputs?.identityType,
        bvnVerified: inputs?.bvnVerified,
        hasSubmittedBankStatement:
            kycData?.financial_verification?.bank_statements?.length > 0,
    }

    const handleOnBlur = async e => {
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateDynamicKycInfoKyc,
            setErrors,
            context: validationSchemaContext,
        })
    }

    const handleSelectOnBlur = async name => {
        await validateField({
            name,
            value: inputs[`${name}`],
            schema: validateDynamicKycInfoKyc,
            setErrors,
            context: validationSchemaContext,
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)

        if (name === 'contactState') {
            setInputs(prev => ({
                ...prev,
                contactCity: '',
            }))
        }

        if (name === 'installationState') {
            setInputs(prev => ({
                ...prev,
                installationCity: '',
            }))
        }
    }

    const handleSameAddressOptionChange = option => {
        const selectedOption = sameAddressOptions.find(
            opt => opt.title === option,
        )

        setInputs(prev => ({
            ...prev,
            sameAddress: selectedOption,
            installationAddress: '',
            installationCountry: '',
            installationState: '',
            installationCity: '',
        }))

        if (selectedOption?.value) {
            removeErrors('installationAddress')
            removeErrors('installationCountry')
            removeErrors('installationState')
            removeErrors('installationCity')
        }
    }

    const handleBusinessRegisteredChange = option => {
        const selectedOption = registeredBusinessOptions.find(
            opt => opt.title === option,
        )

        setInputs(prev => ({
            ...prev,
            registeredBusiness: selectedOption,
        }))
    }

    const handleRemoveFile = (key, title, url) => {
        setFileToBeRemoved({
            key: key,
            title: title,
            url: url,
            fileType:
                key === 'employment-contract' ? 'EMPLOYMENT_CONTRACT' : '',
        })
        setShowRemoveFileModal(true)
    }

    const closeRemoveFileModal = () => {
        setFileToBeRemoved({
            key: '',
            title: '',
            url: '',
            fileType: '',
        })
        setShowRemoveFileModal(false)
    }

    const closeRemoveFileModalAfterSuccess = () => {
        if (fileToBeRemoved.key === 'employment-contract') {
            setEmploymentContract([])
            if (inputs?.employmentContractUrl?.length > 0) {
                setInputs(prev => ({
                    ...prev,
                    employmentContractUrl: '',
                }))
            }
        }

        setShowRemoveFileModal(false)
    }

    const handleCloseUploadModal = () => {
        setShowUploadModal(false)
        setSuccessEmploymentContractUpload([])
    }

    const closeActionModal = () => {
        setAction('')
        setActionModalOpen(false)
    }

    const goToSSEHome = () => {
        setActionModalOpen(false)
        history.push('/')
    }

    const storeActionAndOpenModal = action => {
        setAction(action)
        setActionModalOpen(true)
    }

    const handleSaveInfo = () => {
        storeActionAndOpenModal('save-progress')
        setSaveOrSubmitAction('Save')
    }

    const handleSubmitInfo = () => {
        setSaveOrSubmitAction('Submit')
        handleSaveOrSubmit('Submit')
    }

    const formatPersonalInfo = action => {
        let payload = {
            first_name: inputs?.firstName,
            last_name: inputs?.lastName,
            contact_address: removeEmptyValues({
                street_address: inputs?.contactAddress,
                city: inputs?.contactCity,
                state_of_residence: inputs?.contactState,
                country: inputs?.contactCountry,
            }),
            button: action,
        }

        if (inputs?.sameAddress?.value) {
            payload.installation_address = removeEmptyValues({
                street_address: inputs?.contactAddress,
                city: inputs?.contactCity,
                state: inputs?.contactState,
                country: inputs?.contactCountry,
            })
        } else {
            payload.installation_address = removeEmptyValues({
                street_address: inputs?.installationAddress,
                city: inputs?.installationCity,
                state: inputs?.installationState,
                country: inputs?.installationCountry,
            })
        }

        payload = removeEmptyValues(payload)

        persistInfoMutate(payload)
    }

    const { uploadToS3Mutate, uploadToS3Loading } = useUploadToS3({
        onSuccess: data => {
            formatEmploymentInfo(saveOrSubmitAction, {
                employment_contract_url: data,
            })
        },
    })

    const handleSaveOrSubmitEmploymentInfo = action => {
        // full time, part time, or contract
        if (
            adminSelectedEmploymentType === 'full_employment' ||
            requiredKycSections?.includes('work_info')
        ) {
            if (inputs?.employmentSuccessUpload?.name?.length) {
                uploadToS3Mutate({
                    category: 'EMPLOYMENT_CONTRACT',
                    file: inputs?.employmentSuccessUpload,
                })
            } else {
                formatEmploymentInfo(action)
            }
        }

        // self employed
        if (
            adminSelectedEmploymentType === 'business' ||
            requiredKycSections?.includes('business')
        ) {
            formatSelfEmployedInfo(action)
        }

        // retired
        if (
            adminSelectedEmploymentType === 'pension' ||
            requiredKycSections?.includes('pension_details')
        ) {
            formatRetiredInfo(action)
        }
    }

    const formatEmploymentInfo = (action, additionalPayload = {}) => {
        let payload = {
            employment_type: inputs?.employmentType,
            employment_start_date:
                inputs?.jobStartDate?.length > 0 &&
                fullEmploymentOptions.includes(inputs?.employmentType)
                    ? format(new Date(inputs?.jobStartDate), 'yyyy-MM-dd')
                    : '',
            employment_contract_url: inputs?.employmentContractUrl,
            linkedin_url: includeHttps(inputs?.linkedinPage),
            company_name: inputs?.companyName,
            job_title: inputs?.jobTitle,
            net_monthly_salary: removeCommaFromNum(inputs?.netMonthlySalary),
            employee_id: inputs?.employeeId,
            ...additionalPayload,
            button: action,
        }
        payload = removeEmptyValues(payload)
        persistInfoMutate(payload)
    }

    const formatSelfEmployedInfo = action => {
        let payload = {
            employment_type: inputs?.employmentType,
            is_business_registered: inputs?.registeredBusiness?.value,
            button: action,
        }

        payload.business_details = removeEmptyValues({
            business_name: inputs?.businessName,
            business_industry: inputs?.businessIndustry,
            years_in_operation: inputs?.numberOfYearsInOperation,
            business_website: includeHttps(inputs?.businessWebsite),
            business_description: inputs?.businessDescription,
        })

        payload = removeEmptyValues(payload)
        persistInfoMutate(payload)
    }

    const formatRetiredInfo = action => {
        let payload = {
            employment_type: inputs?.employmentType,
            linkedin_url: includeHttps(inputs?.linkedinPage),
            pension_manager: inputs?.pensionManager,
            retirement_date:
                inputs?.retirementDate?.length > 0 &&
                retiredOptions.includes(inputs?.employmentType)
                    ? format(new Date(inputs?.retirementDate), 'yyyy-MM-dd')
                    : '',
            button: action,
        }

        payload = removeEmptyValues(payload)
        persistInfoMutate(payload)
    }

    const handleSaveOrSubmitIdentityInfo = action => {
        if (action === 'Save') {
            let payload = {
                first_name: inputs?.firstName,
                last_name: inputs?.lastName,
                type: inputs?.identityType,
                number: inputs?.identityNumber,
                button: action,
            }

            payload = removeEmptyValues(payload)
            persistInfoMutate(payload)
            return
        }

        if (action === 'Submit') {
            submitKycResidentialIdentityCompare({
                id: inputs?.identityVerificationId,
            })
            return
        }
    }

    const handleSubmitIdCardInfo = modalInputs => {
        let payload = {
            first_name: modalInputs?.firstName,
            last_name: modalInputs?.lastName,
            type: modalInputs?.identityType,
            number: modalInputs?.identityNumber,
            button: 'Submit',
        }

        payload = removeEmptyValues(payload)
        persistInfoMutate(payload)
    }

    const handleSaveOrSubmitFinancialInfo = action => {
        let payload = {
            repayment_method:
                inputs?.repaymentMethod === ''
                    ? ''
                    : repaymentMethodMap[inputs?.repaymentMethod],
            estimationId: offer?.offerId,
        }

        payload = removeEmptyValues(payload)

        updateFinancialInfo(payload, {
            onSuccess: () => {
                if (action === 'Save') {
                    setAction('progress-saved')
                } else if (action === 'Submit')
                    handleModuleSubmissionOnSuccess()
            },
        })
    }

    const saveOrSubmitFormatterMapping = {
        personal_information: formatPersonalInfo,
        employment_information: handleSaveOrSubmitEmploymentInfo,
        identity_information: handleSaveOrSubmitIdentityInfo,
        financial_information: handleSaveOrSubmitFinancialInfo,
    }

    const handleSaveOrSubmit = action => {
        if (action === 'Save') {
            saveOrSubmitFormatterMapping[kycModule?.key](action)
        } else if (action === 'Submit') {
            validateDynamicKycInfoKyc
                .validate(inputs, {
                    context: validationSchemaContext,
                    abortEarly: false,
                })
                .then(() => {
                    saveOrSubmitFormatterMapping[kycModule?.key](action)
                })
                .catch(err => {
                    let errList = {}
                    err?.inner?.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        }
    }

    const actionModalHandler = () => {
        switch (action) {
            case 'save-progress':
                handleSaveOrSubmit('Save')
                break
            case 'progress-saved':
                goToSSEHome()
                break
        }
    }

    const actionModalSecondaryHandler = () => {
        switch (action) {
            case 'progress-saved':
                setActionModalOpen(false)
                setAction('')
                break
        }
    }

    const toggleShowCacVerifyModal = () => {
        setShowCacVerifyModal(prev => !prev)
    }

    const cacHelpPayload = {
        email: inputs?.email || 'support@sunfi.co',
        name: inputs?.businessName,
        message: 'CAC Verification failed and consumer is requesting for help',
        phone_number: formatPhoneNumber(inputs?.phone) || '+2347080639920',
    }

    const toggleSelfieImageModal = () => {
        setShowSelfieImageModal(prev => !prev)
    }

    const toggleIdOverrideModal = () => {
        setShowIdOverrideModal(prev => !prev)
    }

    const openIdVerifyModal = () => {
        setShowIdVerifyModal(true)
        setSaveOrSubmitAction('Submit-id-card-info')
    }

    const closeIdVerifyModal = () => {
        setShowIdVerifyModal('')
        setSaveOrSubmitAction('')
    }

    const openBVNModal = () => {
        setShowBVNModal(true)
    }

    const closeBVNModal = () => {
        setShowBVNModal(false)
    }

    const bvnVerifiedSuccessfully = () => {
        queryClient.invalidateQueries('fetch-dynamic-kyc-info')
        closeBVNModal()
        successAlert('Your BVN has been verified successfully')
        removeErrors('bvnVerified')
    }

    const handleBvnVerifyLater = () => {
        closeBVNModal()
        BVNAlert('Please, go ahead to provide your other details', '511px')
    }

    useEffect(() => {
        if (offer?.offerId) {
            refetchKycModules()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offer])

    useEffect(() => {
        if (employmentContract[0]?.name?.length > 0) {
            setInputs(prev => ({
                ...prev,
                employmentSuccessUpload: employmentContract[0],
            }))
        }
    }, [employmentContract, setInputs])

    useOTPVerify({ otp, handleOtpSubmit })

    return {
        kycModule,
        pageLoading,
        inputs,
        errors,
        handleChange,
        handleSelectChange,
        handleOnBlur,
        handleSelectOnBlur,
        handleSameAddressOptionChange,
        showRemoveFileModal,
        fileToBeRemoved,
        closeRemoveFileModal,
        closeRemoveFileModalAfterSuccess,
        showCalendar,
        setShowCalendar,
        setShowUploadModal,
        handleRemoveFile,
        employmentContract,
        setEmploymentContract,
        showUploadModal,
        handleCloseUploadModal,
        successEmploymentContractUpload,
        setSuccessEmploymentContractUpload,
        actionModalToast,
        showVerifyContactModal,
        initiateContactVerification,
        closeVerifyContactModal,
        contactTypeToVerify,
        verifyContactsModalInputs,
        otp,
        setOtp,
        otpError,
        handleResendOtp,
        verifyContactToast,
        initiateVerificationResent,
        initiateVerificationSetResent,
        handleOtpSubmit,
        initiateEmailVerificationLoading,
        initiatePhoneVerificationLoading,
        sseValidateAuthOtpLoading,
        action,
        actionModalOpen,
        closeActionModal,
        goToSSEHome,
        actionModalHandler,
        actionModalSecondaryHandler,
        handleSaveInfo,
        handleSubmitInfo,
        saveLoading:
            (persistInfoLoading && saveOrSubmitAction === 'Save') ||
            (uploadToS3Loading && saveOrSubmitAction === 'Save') ||
            (updateFinancialInfoLoading && saveOrSubmitAction === 'Save'),
        submitLoading:
            (persistInfoLoading && saveOrSubmitAction === 'Submit') ||
            (uploadToS3Loading && saveOrSubmitAction === 'Submit') ||
            kycResidentialIdentityCompareLoading ||
            (updateFinancialInfoLoading && saveOrSubmitAction === 'Submit'),
        handleBusinessRegisteredChange,
        showCacVerifyModal,
        toggleShowCacVerifyModal,
        cacHelpPayload,
        cacVerificationInProgress,
        setCacVerificationInProgress,
        isCustomerApproved: offer?.isCustomerApproved,
        showSelfieImageModal,
        toggleSelfieImageModal,
        showIdVerifyModal,
        openIdVerifyModal,
        closeIdVerifyModal,
        idVerificationInProgress,
        setIdVerificationInProgress,
        showIdOverrideModal,
        toggleIdOverrideModal,
        kycStatus,
        handleSubmitIdCardInfo,
        submitIdCardLoading:
            persistInfoLoading && saveOrSubmitAction === 'Submit-id-card-info',
        idVerificationFailedModal,
        showBVNModal,
        openBVNModal,
        closeBVNModal,
        offer,
        bvnVerifiedSuccessfully,
        handleBvnVerifyLater,
        bvnVerificationInProgress,
        setBvnVerificationInProgress,
        kycData,
        showVideoModal,
        setShowVideoModal,
        showVideo,
        setShowVideo,
        removeErrors,
    }
}

export default useDynamicInformation
