import PropTypes from 'prop-types'

export const footerPropTypes = {
    footerLeftContent: PropTypes.oneOf(['chat', 'button', null]),
    chatCTAText: PropTypes.string,
    secondaryBtnWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    secondaryBtnHeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    handleSecondaryBtnClick: PropTypes.func,
    secondaryBtnDisabled: PropTypes.bool,
    secondaryBtnLoading: PropTypes.bool,
    secondaryBtnText: PropTypes.string,
    primaryBtnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    primaryBtnHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handlePrimaryBtnClick: PropTypes.func,
    primaryBtnDisabled: PropTypes.bool,
    primaryBtnLoading: PropTypes.bool,
    primaryBtnText: PropTypes.string,
    footerClassName: PropTypes.string,
}

export const mobileModalPropTypes = {
    mobileHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mobilePadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mobileContentStyle: PropTypes.object,
}

export const standardModalPropTypes = {
    standardWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    standardHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    standardContentStyle: PropTypes.object,
}
