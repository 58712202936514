import BackNav from 'src/components/BackNav'
import PropTypes from 'prop-types'
import SeoComponent from 'src/components/Seo'
import './outrightSaleDetails.scss'
import AdminApprovedProvider from '../../PaymentPlans/Details/hooks/planProvider'
import MainDetails from '../../PaymentPlans/Details/components/mainDetails/MainDetails'
import AsideDetails from '../../PaymentPlans/Details/components/asideDetails/AsideDetails'
import { useParams } from 'react-router-dom'
import useGetPlanInfo from '../../PaymentPlans/Details/hooks/useGetPlanInfo'
import './outrightSaleDetails.scss'

const OutrightSaleDetails = ({ recommendationType = 'auto' }) => {
    const params = useParams()
    const {
        paymentPlanData,
        isPaymentPlanLoading,
        paymentPlanDataRefetch,
        isRecommendedSolutionsLoading,
    } = useGetPlanInfo(params?.id, recommendationType)
    const pageTitle = 'SunFi - Admin Outright Sale | Sales Details'
    return (
        <AdminApprovedProvider>
            <>
                <SeoComponent title={pageTitle} />
                <main className="outrightSaleContainer">
                    <BackNav
                        title="Back to Outright sale"
                        top="-40px"
                        url="/admin/outright-sale"
                        v2
                    />

                    <div className="outrightSaleContainer__content">
                        <MainDetails
                            paymentPlanData={paymentPlanData}
                            isPaymentPlanLoading={isPaymentPlanLoading}
                            recommendationType={recommendationType}
                            isOutrightSale={true}
                        />
                        <div
                            className="outrightSaleContainer__content__divider
                            "
                        />
                        <AsideDetails
                            paymentPlanData={paymentPlanData}
                            isPaymentPlanLoading={isPaymentPlanLoading}
                            paymentPlanDataRefetch={paymentPlanDataRefetch}
                            isRecommendedSolutionsLoading={
                                isRecommendedSolutionsLoading
                            }
                            recommendationType={recommendationType}
                            isOutrightSale={true}
                        />
                    </div>
                </main>
            </>
        </AdminApprovedProvider>
    )
}

OutrightSaleDetails.propTypes = {
    recommendationType: PropTypes.oneOf(['auto', 'alternative']),
}

export default OutrightSaleDetails
