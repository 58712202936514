/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { Menu, Dropdown } from 'antd'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
    CreateAccountPhoneValidationSchema,
    CreateAccountEmailValidationSchema,
} from '../../utils/validationSchema'
import { resendEmailVerification } from '../../api/signup/emailVerification'
import { registerApi } from '../../api/signup/register'
import Verification from '../Verification'
import Confirmation from '../Confirmation'
import ConfirmationImage from '../../assets/images/confirmationImage.svg'
import Toast from '../Toast'

import { InputFields } from '../InputFields'
import { SelectCountryCode } from '../InputFields/SelectCountryCode'
import Checkbox from '../Checkbox'
import Formbox from '../FormBox'
import FormTitleBar from '../FormTitleBar'
import ButtonComponent from '../Button'
import GetModal from '../Modal'
import { SelectCheckbox } from '../Checkbox'

import styles from './createaccount.module.scss'
import './createaccount.scss'
import { formatInputValue, formatPhoneNumber } from '../../utils/formatting'
import { ReactComponent as DropdownArrow } from '../../assets/images/DownArrow.svg'
import { ASI, DISI, OEMI, Consumer } from '../../utils/termsOfServiceData'

import useErrorHandler from '../../utils/Hooks/useErrorHandler'
import SecondaryModal from '../Modal/SecondaryModal'
import PrivacyPolicy from '../../pages/PrivacyPolicy/index'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

const {
    CheckboxLink,
    ButtonWrapper,
    LinkWrapper,
    Wrapper,
    CheckBoxWrapper,
    FormBoxText,
    BtnLoading,
    LoadingWrapper,
    LinkResend,
    LinkResendText,
    ResendErrorText,
    resendText,
    HeaderText,
    PartnerName,
    PartnerDropdown,
    HeaderWrapper,
} = styles

const CreateAccount = ({ partnerId, showTextHandler }) => {
    const [inputs, setInputs] = useState({
        phonenumber: '',
        email: '',
        countryCode: '+234',
    })
    const [errors, setErrors] = useState({})
    const [checked, setChecked] = useState(false)
    const [countDown, setCountDown] = useState(0)
    const [count, setCount] = useState(1)
    const [showVerification, setShowVerification] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [, setToastError] = useState(false)
    const [verificationType, setVerificationType] = useState(
        partnerId === 1 ? 'phone' : 'email',
    )
    const [errs, setErrs] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedPartner, setSelectedPartner] = useState(partnerId)
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false)

    const pageTitle = 'SunFi - Create Account | Partners'
    const pageUrlName = window.location.pathname
    const pageTracker = 'PartnersSignup'

    const handleModal = () => {
        setModalOpen(true)
    }

    const providerTermsOfService = {
        1: <ASI signUpPageModal />,
        2: <DISI signUpPageModal />,
        3: <OEMI signUpPageModal />,
        4: <Consumer signUpPageModal />,
    }

    const changeVerificationType = type => {
        setVerificationType(type)
        setInputs({
            email: '',
            phonenumber: '',
            countryCode: '+234',
        })
        setErrors('')
        setErrs(null)
        setToastError(false)
        setChecked(false)
    }
    const handleChangePhone = () => {
        setShowVerification(false)
    }
    //countdown timer
    useEffect(() => {
        const handleTimer = () => {
            if (countDown > 0) {
                setCountDown(prev => prev - 1)
            }
        }
        const reduceTimer = setTimeout(handleTimer, 1000)
        return () => clearTimeout(reduceTimer)
    }, [countDown])

    const handleChange = e => {
        setErrors('')
        const { name, value } = e.target
        if (name === 'phonenumber') {
            setInputs(prev => ({
                ...prev,
                [name]: formatInputValue(name, value),
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }
    }

    const handleBlur = e => {
        setErrors('')
        const { name, value } = e.target
        const validationSchema =
            name === 'phonenumber'
                ? CreateAccountPhoneValidationSchema
                : CreateAccountEmailValidationSchema

        validationSchema
            .validate({ [name]: value }, { abortEarly: false })
            .then(() => {
                setErrors('')
                setInputs(prev => ({ ...prev, [name]: value.trim() }))
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleSelectChange = value => {
        setInputs(prev => ({ ...prev, countryCode: value }))
    }

    const handleChecked = () => {
        setChecked(!checked)
    }

    const emailPayload = {
        email: inputs.email,
        provider_type:
            partnerId === 1 ? 'AI' : partnerId === 2 ? 'DISI' : 'OEMI',
    }

    const phonePayload = {
        phone_number: formatPhoneNumber(inputs.phonenumber, inputs.countryCode),
        provider_type:
            partnerId === 1 ? 'AI' : partnerId === 2 ? 'DISI' : 'OEMI',
    }

    const { isLoading, refetch } = useQuery(
        'verifyPhoneAndEmail',
        () =>
            registerApi(
                verificationType,
                verificationType === 'phone' ? phonePayload : emailPayload,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                if (data) {
                    setCountDown(60)
                    verificationType === 'phone'
                        ? setShowVerification(true)
                        : setShowConfirmation(true)
                }
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['partnerSignup'].action,
                    eventTrackers['partnerSignup'].label,
                    eventTrackers['partnerSignup'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event:
                            verificationType === 'phone'
                                ? phonePayload
                                : emailPayload,
                    },
                )
            },
            onError: error => {
                const errObj = error?.response?.data?.errors

                setErrs(errObj)
            },
        },
    )

    const handleClick = () => {
        refetch()
    }

    const handleSubmit = () => {
        setToastError(false)
        if (verificationType === 'phone') {
            CreateAccountPhoneValidationSchema.validate(inputs, {
                abortEarly: false,
            })
                .then(() => {
                    setErrors('')
                    handleClick()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        } else {
            CreateAccountEmailValidationSchema.validate(inputs, {
                abortEarly: false,
            })
                .then(() => {
                    setErrors('')
                    handleClick()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        }
    }

    //hide email
    const hideEmail = email => {
        let newEmail = ''
        for (let i = 0; i < email.length; i++) {
            if (i > 3 && i < 15) newEmail += '*'
            else newEmail += email[i]
        }
        return newEmail
    }

    //resend verification link
    const {
        isLoading: resendLoading,
        error: resendError,
        refetch: resendRefetch,
    } = useQuery(
        'resendLink',
        () =>
            resendEmailVerification({
                email: inputs.email,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => setCountDown(60 * count),
        },
    )

    const handleResendEmail = () => {
        resendRefetch()
        setCount(prev => prev + 1)
    }
    const partners = [
        {
            id: 1,
            partnerName: 'Associate Installer',
        },
        {
            id: 2,
            partnerName: 'Distributor that installs',
        },
        {
            id: 3,
            partnerName: 'Manufacturer that installs',
        },
        {
            id: 4,
            partnerName: 'Consumer',
        },
    ]

    const menu = (
        <Menu>
            {partners.map(partner => (
                <Menu.Item
                    onClick={() => setSelectedPartner(partner.id)}
                    key={partner.id}
                >
                    <SelectCheckbox
                        isChecked={partner.id === selectedPartner}
                    />
                    {partner.partnerName}
                </Menu.Item>
            ))}
        </Menu>
    )

    const errorValue = useErrorHandler(errs)

    return (
        <>
            <SecondaryModal
                showModal={showPrivacyPolicyModal}
                modalHeight="550px"
                modalWidth="624px"
                modalClass="RecommendedEnergy"
                onCancel={() => setShowPrivacyPolicyModal(false)}
                content={<PrivacyPolicy isModal="true" />}
            />
            <GetModal
                modalHeight="550px"
                modalWidth="624px"
                showModal={modalOpen}
                onCancel={() => setModalOpen(false)}
                closable={true}
                signUpPageModal
                content={
                    <>
                        <Dropdown
                            overlay={menu}
                            trigger={['click']}
                            placement="bottomCenter"
                            overlayClassName="TermsOfServiceDropdown"
                        >
                            <div className={PartnerDropdown}>
                                <p className={PartnerName}>
                                    {
                                        partners.find(
                                            partner =>
                                                partner.id === selectedPartner,
                                        )?.partnerName
                                    }
                                </p>
                                <DropdownArrow />
                            </div>
                        </Dropdown>
                        <div className={HeaderWrapper}>
                            <p className={HeaderText}>Terms Of Service</p>
                        </div>
                        {providerTermsOfService[selectedPartner]}
                    </>
                }
            />
            {showVerification ? (
                <Verification
                    fullPhoneNumber={`${formatPhoneNumber(
                        inputs?.phonenumber,
                    )}`}
                    phoneNumber={inputs?.phonenumber}
                    handleChangePhone={handleChangePhone}
                    countDown={countDown}
                    setCountDown={setCountDown}
                    showTextHandler={showTextHandler}
                />
            ) : showConfirmation ? (
                <Confirmation
                    imgUrl={ConfirmationImage}
                    header="Confirmation Link Sent"
                    subHeader={`We sent a confirmation link to your email address ${hideEmail(
                        inputs?.email,
                    )}                                                                                                                                                                                                                                               `}
                    btnText="Open Email App"
                    mailTo
                >
                    <div className={LinkResend}>
                        <p className={LinkResendText}>
                            You didn’t get the link?
                        </p>
                        <p>
                            {countDown < 1 ? (
                                resendError ? (
                                    <p className={ResendErrorText}>
                                        {resendError?.response?.data?.message}
                                    </p>
                                ) : (
                                    <span
                                        onClick={handleResendEmail}
                                        disabled={resendLoading}
                                        className={resendText}
                                    >
                                        Resend Link
                                    </span>
                                )
                            ) : (
                                `Resend Link (${
                                    countDown > 60
                                        ? `${Math.floor(countDown / 60)}m ${
                                              countDown % 60 > 0
                                                  ? `${countDown % 60}s`
                                                  : ''
                                          }`
                                        : `${countDown}s`
                                })`
                            )}
                        </p>
                    </div>
                </Confirmation>
            ) : (
                <>
                    <Formbox>
                        <div className={Wrapper}>
                            <FormTitleBar
                                title="Create Account"
                                subtitle="Enter your details to get started"
                            />
                            <form>
                                {errs && errorValue['msg'] && (
                                    <Toast
                                        message={
                                            isLoading
                                                ? ''
                                                : errs?.phone_number ||
                                                  errs?.email
                                        }
                                        messageType="error"
                                    />
                                )}
                                {verificationType === 'phone' ? (
                                    <p
                                        className={FormBoxText}
                                        style={{ cursor: 'auto' }}
                                    >
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                changeVerificationType('email')
                                            }
                                        >
                                            Switch to Email
                                        </span>
                                    </p>
                                ) : (
                                    <p
                                        style={{ cursor: 'auto' }}
                                        className={FormBoxText}
                                    >
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                changeVerificationType('phone')
                                            }
                                        >
                                            Switch to Phone
                                        </span>
                                    </p>
                                )}
                                {verificationType === 'phone' ? (
                                    <SelectCountryCode
                                        handleChange={handleChange}
                                        handleSelectChange={handleSelectChange}
                                        title="Phone Number"
                                        name="phonenumber"
                                        inputValue={inputs.phonenumber}
                                        errorMessage={
                                            errors.phonenumber ||
                                            errorValue.phone_number
                                        }
                                        onBlur={handleBlur}
                                    />
                                ) : partnerId !== 1 ||
                                  verificationType === 'email' ? (
                                    <InputFields
                                        handleChange={handleChange}
                                        name="email"
                                        title="Email Address"
                                        value={inputs.email}
                                        errorMessage={
                                            errors.email || errorValue.email
                                        }
                                        onBlur={handleBlur}
                                    />
                                ) : (
                                    ''
                                )}
                                <div>
                                    <Checkbox
                                        handleChecked={handleChecked}
                                        signupchecked={checked}
                                        isChecked={checked}
                                        className={CheckBoxWrapper}
                                    >
                                        I agree to the
                                        <p
                                            className={CheckboxLink}
                                            onClick={handleModal}
                                        >
                                            terms of service
                                        </p>
                                        and
                                        <p
                                            className={CheckboxLink}
                                            onClick={() =>
                                                setShowPrivacyPolicyModal(true)
                                            }
                                        >
                                            privacy policy
                                        </p>
                                    </Checkbox>
                                </div>

                                <div className={ButtonWrapper}>
                                    <ButtonComponent
                                        btnBgColor="var(--blue)"
                                        btnTextColor="var(--white)"
                                        disabled={
                                            verificationType === 'phone'
                                                ? inputs.phonenumber === '' ||
                                                  checked === false
                                                : inputs.email === '' ||
                                                  checked === false
                                        }
                                        handleClick={handleSubmit}
                                    >
                                        {isLoading ? (
                                            <div className={LoadingWrapper}>
                                                <div
                                                    className={BtnLoading}
                                                ></div>
                                            </div>
                                        ) : (
                                            'Continue'
                                        )}
                                    </ButtonComponent>
                                </div>
                                <div
                                    className={LinkWrapper}
                                    style={{ textAlign: 'center' }}
                                >
                                    <p>
                                        Already have an account?{' '}
                                        <span>
                                            <Link
                                                to="/login"
                                                style={{ color: 'var(--blue)' }}
                                            >
                                                Sign In
                                            </Link>
                                        </span>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </Formbox>
                </>
            )}
        </>
    )
}

export default CreateAccount
