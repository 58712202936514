import axios from '../../config/axios'

export const updateAiInformationApi = async inputs => {
  const request = await axios.patch('/users/me', inputs)
  return request
}

export const getAiInformationApi = async () => {
  const request = await axios.get('/users/me')
  return request
}

export const changeAiPasswordApi = async inputs => {
  const request = await axios.post('/users/me/change-password', inputs)
  return request
}
