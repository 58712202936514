export const formatKYCCompletionStatus = (
    data,
    customer_type,
    isDynamicKycUser,
) => {
    if (isDynamicKycUser) {
        let values = {}

        // dynamic kyc modules
        const requiredModules = {
            RESIDENTIAL: [
                'personal_information',
                'employment_information',
                'identity_information',
                'financial_information',
            ],
            BUSINESS: [
                'business_information',
                'verification',
                'financial_information',
            ],
        }[customer_type]

        requiredModules.forEach(key => {
            if (key in data) {
                let isModuleCompleted = data[key].length === 0
                values[`${key}_completion_status`] = isModuleCompleted
            }
        })

        values['account_setup_completion_status'] = requiredModules.every(
            module =>
                !(module in data) ||
                values[`${module}_completion_status`] === true,
        )

        return values
    } else {
        let values = {}
        if (customer_type === 'RESIDENTIAL') {
            values = {
                residential_basic_information_completion_status:
                    !data?.basic_information?.length,
                residential_financial_information_completion_status:
                    !data?.financial_information?.length,
                residential_identity_information_completion_status:
                    !data?.identity_information?.length,
                account_setup_completion_status:
                    !data?.basic_information?.length &&
                    !data?.financial_information?.length &&
                    !data?.identity_information?.length,
                residential_financial_information: data?.financial_information,
                bvn_completed: data?.financial_information?.includes('bvn')
                    ? false
                    : true,
            }
        } else if (customer_type === 'BUSINESS') {
            values = {
                business_information_completion_status:
                    !data?.business_information?.length,
                business_verification_completion_status:
                    !data?.business_verification?.length,

                bvn_completed: data?.business_verification?.includes('bvn')
                    ? false
                    : true,
                business_financial_information_completion_status:
                    !data?.financial_information?.length,
                account_setup_completion_status:
                    !data?.business_information?.length &&
                    !data?.business_verification?.length &&
                    !data?.financial_information?.length,
                business_financial_information: data?.financial_information,
            }
        }
        return values
    }
}
