import PropTypes from 'prop-types'
import { useState } from 'react'
import styles from './CreditReviewTab.module.scss'
import './crtooltip.scss'
import EmptyState from 'src/components/EmptyState'
import PlanView from './PlanView'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import DisputeCreditDecision from './DisputeCreditDecion'
import { denyPermission } from 'src/utils/permissionFramework'
import { useKYCCompletionStatus } from 'src/utils/Hooks/useKYCCompletionStatus'
import DisputeCreditDecionModal from './DisputeCreditDecisionModal'
import ManualDispute from './ManualDispute'
import { reviewTypeData, documentTypeReMapping } from './data'
import { ReactComponent as CaretDown } from '../../../../../../assets/images/Caret-Circle-Down.svg'
import { ReactComponent as CaretUp } from '../../../../../../assets/images/Caret_Circle_Up.svg'
import { ReactComponent as FileAttachmentIcon } from 'src/assets/images/attach-blue.svg'

const CreditReviewTab = ({
    creditDecisionData,
    planType,
    customerType,
    estimationId,
    status,
}) => {
    const plans = creditDecisionData?.payment_plans
    const creditReviewProcessUndecided =
        creditDecisionData?.context?.final_decision === 'UNDECIDED'

    const [showDisputeCreditDecisionModal, setShowDisputeCreditDecisonModal] =
        useState(false)
    const [showDisputeCreditEngine, setDisputeCreditEngine] = useState(false)
    const [showManualDispute, setShowManualDispute] = useState(false)
    const [categoryId, setCategoryId] = useState(0)
    const [showDoc, setShowDoc] = useState(false)

    const kycStatus = useKYCCompletionStatus(estimationId, customerType)
    let businessKYCCompleted
    let residentialKYCCompleted

    const isFinancialInformationComplete = financialInfo => {
        return (
            financialInfo.length === 1 &&
            financialInfo[0] === 'bank_statement_analysis'
        )
    }

    if (customerType === 'BUSINESS') {
        const {
            business_information_completion_status,
            business_verification_completion_status,
            business_financial_information,
            account_setup_completion_status,
        } = kycStatus

        businessKYCCompleted =
            account_setup_completion_status === true ||
            (business_information_completion_status === true &&
                business_verification_completion_status === true &&
                isFinancialInformationComplete(business_financial_information))
    }

    if (customerType === 'RESIDENTIAL') {
        const {
            residential_basic_information_completion_status,
            residential_identity_information_completion_status,
            residential_financial_information,
            account_setup_completion_status,
        } = kycStatus

        residentialKYCCompleted =
            account_setup_completion_status === true ||
            (residential_basic_information_completion_status === true &&
                residential_identity_information_completion_status === true &&
                isFinancialInformationComplete(
                    residential_financial_information,
                ))
    }

    if (
        !creditDecisionData?.id &&
        !businessKYCCompleted &&
        !residentialKYCCompleted
    )
        return (
            <EmptyState
                title="Credit review not available"
                subTitle="KYC must be completed"
            />
        )
    const showDisputeCreditDecisionBtn = denyPermission(
        'admin',
        'dispute_credit_decision',
        'can_dispute',
    )

    return (
        <>
            {/* dispute credit decision modal */}
            <SecondaryModal
                showModal={showDisputeCreditDecisionModal}
                modalWidth={
                    showDisputeCreditEngine || showManualDispute
                        ? '624px'
                        : ' 459px'
                }
                modalHeight={
                    showManualDispute && categoryId === 0
                        ? '755px'
                        : showManualDispute
                        ? '572px'
                        : showDisputeCreditEngine
                        ? '659px'
                        : ' 445px'
                }
                onCancel={() => setShowDisputeCreditDecisonModal(false)}
                closable={
                    showDisputeCreditEngine || showManualDispute ? false : true
                }
                content={
                    showManualDispute ? (
                        <ManualDispute
                            closeModal={() => {
                                setShowDisputeCreditDecisonModal(false)
                                setShowManualDispute(false)
                            }}
                            paymentPlans={plans}
                            estimationId={estimationId}
                            categoryId={categoryId}
                            setCategoryId={setCategoryId}
                        />
                    ) : showDisputeCreditEngine ? (
                        <DisputeCreditDecision
                            closeModal={() => {
                                setShowDisputeCreditDecisonModal(false)
                                setDisputeCreditEngine(false)
                            }}
                            openModal={() =>
                                setShowDisputeCreditDecisonModal(true)
                            }
                            evaluationCriteria={
                                plans?.[0]?.credit_decision?.evaluation_criteria
                            }
                            customerType={customerType}
                            estimationId={estimationId}
                            accountType={
                                creditDecisionData?.bank_statement_analysis
                                    ?.is_salary_account
                            }
                            adminRunCreditDecision={!creditDecisionData?.id}
                        />
                    ) : (
                        <DisputeCreditDecionModal
                            setDisputeCreditEngine={setDisputeCreditEngine}
                            setShowManualDispute={setShowManualDispute}
                        />
                    )
                }
            />
            {!creditDecisionData?.id ? (
                (businessKYCCompleted || residentialKYCCompleted) && (
                    <EmptyState
                        title="Credit review not available"
                        buttonText="Run Credit Decision"
                        buttonClick={() => {
                            setShowDisputeCreditDecisonModal(true)
                        }}
                    />
                )
            ) : (
                <section className={styles.reviewWrapper}>
                    <div className={styles.creditReview}>
                        <div className={styles.creditReview__header}>
                            <div className={styles.creditReview__header__top}>
                                <h3>Consumer Scorecard</h3>
                                <p>
                                    {
                                        creditDecisionData?.credit_score_card
                                            .title
                                    }
                                </p>
                            </div>

                            <div className={styles.creditReview__body__item}>
                                <p>Package cost</p>
                                <span>
                                    {plans[0].currency.symbol}
                                    {plans[0].solution_amount}
                                </span>
                            </div>
                            <div className={styles.creditReview__body__item}>
                                <p>Required upfront deposit</p>
                                <span>
                                    <strong>
                                        {plans[0]?.initial_deposit_percentage}
                                    </strong>
                                    ({plans[0].currency.symbol}
                                    {plans[0]?.initial_deposit})
                                </span>
                            </div>
                            <div className={styles.creditReview__body__item}>
                                <p>Payment plan type</p>
                                <span>{planType}</span>
                            </div>
                            <div className={styles.creditReview__body__item}>
                                <p>Credit score processing</p>
                                {creditReviewProcessUndecided ? (
                                    <span className={styles.creditEngineError}>
                                        Error Occurred
                                    </span>
                                ) : (
                                    <span
                                        className={styles.creditEngineSuccess}
                                    >
                                        Successful
                                    </span>
                                )}
                            </div>
                            <div className={styles.creditReview__body__item}>
                                <p>Credit review type</p>

                                <span>
                                    {
                                        reviewTypeData[
                                            creditDecisionData?.review_type
                                        ]
                                    }
                                </span>
                            </div>
                            {creditDecisionData?.context
                                ?.credit_review_documents && (
                                <div
                                    className={styles.creditReview__body__item}
                                >
                                    <p>Documents added</p>

                                    <span onClick={() => setShowDoc(!showDoc)}>
                                        <span>
                                            {
                                                creditDecisionData?.context
                                                    ?.credit_review_documents
                                                    ?.length
                                            }{' '}
                                            Documents
                                        </span>
                                        {showDoc ? <CaretUp /> : <CaretDown />}
                                    </span>
                                </div>
                            )}
                            <div
                                className={`${
                                    !showDoc
                                        ? styles.hideScore
                                        : styles.showScore
                                }`}
                            >
                                <div
                                    className={`${styles.dropdown} ${styles.creditReviewDoc}`}
                                >
                                    {creditDecisionData?.context?.credit_review_documents?.map(
                                        (doc, index) => (
                                            <div key={index}>
                                                <div>
                                                    <p>
                                                        {
                                                            documentTypeReMapping[
                                                                doc
                                                                    .document_type
                                                            ]
                                                        }
                                                    </p>
                                                    <div>
                                                        <FileAttachmentIcon />
                                                        <a
                                                            href={doc?.file_url}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            download
                                                        >
                                                            {`${
                                                                documentTypeReMapping[
                                                                    doc
                                                                        .document_type
                                                                ]
                                                            }.pdf`}
                                                        </a>
                                                    </div>
                                                </div>
                                                <p className={styles.note}>
                                                    {doc.note}
                                                </p>
                                            </div>
                                        ),
                                    )}
                                </div>
                            </div>
                        </div>
                        {status !== 'APPROVED' &&
                            !showDisputeCreditDecisionBtn && (
                                <div
                                    className={`${styles.creditReview__body__item} ${styles.disputeCreditDecisionStyle}`}
                                >
                                    <p>Have a second opinion?</p>
                                    <span
                                        onClick={() =>
                                            setShowDisputeCreditDecisonModal(
                                                true,
                                            )
                                        }
                                    >
                                        Dispute credit decision
                                    </span>
                                </div>
                            )}
                        {plans?.map(plan => (
                            <PlanView
                                plan={plan}
                                key={plan.id}
                                reviewType={creditDecisionData?.review_type}
                            />
                        ))}
                    </div>
                </section>
            )}
        </>
    )
}

export default CreditReviewTab

CreditReviewTab.propTypes = {
    creditDecisionData: PropTypes.object,
    planType: PropTypes.string,
    customerType: PropTypes.string,
    estimationId: PropTypes.string,
    status: PropTypes.string,
}
