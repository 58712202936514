/* eslint-disable react/prop-types */
import React from 'react'
import { Modal } from 'antd'
import './modal.scss'

const GetModal = ({
    showModal,
    onCancel,
    content,
    app,
    closable,
    noPadding,
    noPaddingBoth,
    modalWidth,
    modalHeight,
    modalBorderRadius,
    extraModalBoxProps = {},
    signUpPageModal,
}) => {
    const [width] = React.useState(window.innerWidth)

    let newWidth = ''
    let newHeight = ''

    if (width < 500) {
        newWidth = '100%'
        newHeight = modalHeight || '40em'
    } else if (width > 500 && width < 900) {
        newWidth = app === 'promo' ? '90%' : '60%'
    } else {
        newWidth = app === 'promo' ? '55%' : '60%'
        newHeight = modalHeight || '55em'
    }

    return (
        <Modal
            width={app === 'workspace' ? 'fit-content' : newWidth}
            bodyStyle={{
                height: app === 'workspace' ? 'fit-content' : newHeight,
                padding: noPadding || noPaddingBoth,
                width: modalWidth,
                borderRadius: '5px',
            }}
            open={showModal}
            onCancel={onCancel ? onCancel : () => {}}
            footer={null}
            wrapClassName={
                noPadding
                    ? 'ModalBox NoPadding'
                    : app === 'energy' && noPaddingBoth
                    ? 'energyModalBox NoPaddingBoth ModalBox'
                    : noPaddingBoth
                    ? 'ModalBox NoPaddingBoth'
                    : modalBorderRadius === '10px'
                    ? 'ModalBox TenPxModalRadius'
                    : app === 'workspace' && modalBorderRadius !== 'small'
                    ? 'ModalBox'
                    : app === 'workspace' && modalBorderRadius === 'small'
                    ? 'ModalBox2'
                    : app === 'adminsolution'
                    ? 'adminSolutionBox NoPaddingBoth'
                    : signUpPageModal
                    ? 'ModalBox3'
                    : app === 'promo'
                    ? 'PromoModal'
                    : ''
            }
            closable={closable}
            {...extraModalBoxProps}
        >
            {content}
        </Modal>
    )
}

export default GetModal
