/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { Upload, message } from 'antd'
import { useMutation } from 'react-query'
import { WebCamCapture } from '../Webcam'
import ImageEditor from '../ImageEditor'
import FormSubBox from '../../FormSubBox'
import FormTitleBar from '../../../../components/FormTitleBar'
import { SelectField } from '../../../../components/InputFields'
import { ActionSection } from '../../index'
import { meansOfIdentification } from './data'
import {
    uploadImageApi,
    uploadIdentificationApi,
} from '../../../../api/verificationIdentity'
import Button from '../../../../components/Button'
import ProfilePicture from '../../../../assets/images/profilepic.svg'
import UploadLogo from '../../../../assets/images/upload.svg'
import SuccessUpload from '../../../../assets/images/successUpload.svg'
import Toast from '../../../../components/Toast'
import { errorHandler } from '../../../../utils/errorHandler'

import './identityverification.scss'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { IdentityVerificationDEPOnboardingValidationSchema } from 'src/utils/validationSchema'
import { uploadToS3 } from 'src/api/utils/file-upload'
import { format } from 'date-fns'
import { dataURIToBlob } from 'src/utils/imageBlob'

const IdentityVerification = ({ prevHandler, nextHandler }) => {
    const pageTitle = 'SunFi - Onboarding | Identity Verification'
    const pageUrlName = window.location.pathname
    const pageTracker = 'Onboarding'
    const [identity, setIdentity] = useState({ identification: '' })
    const [uploading, setUploading] = useState(false)
    const [successUpload, setSuccessUpload] = useState([])
    const [width, setWidth] = useState(40)
    const [enableCamera, setEnableCamera] = useState(false)
    const [pictureUrl, setPictureUrl] = useState('')
    const [showEditor, setShowEditor] = useState(false)
    const [fileList, setFileList] = useState([])
    const [errors, setErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [fileValid, setFileValid] = useState(true)
    const [editedPicture, setEditedPicture] = useState('')
    const [type, setType] = useState('')

    const handleSelectChange = (name, value) => {
        setIdentity(preState => ({
            ...preState,
            [name]: value,
        }))
        setErrors(prev => ({
            ...prev,
            id_type: '',
        }))
    }

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    const { Dragger } = Upload

    const props = {
        name: 'file',

        onChange({ fileList }) {
            setUploading(true)
            setSuccessUpload(fileList)
            setUploading(false)
            setErrors(prev => ({
                ...prev,
                id_image: '',
            }))
        },
        onDrop(e) {
            setUploading(false)
        },
    }

    const beforeUpload = file => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        const isLt2M = file.size / 1000 / 1000 < 3

        if (!isJPG) {
            message.error('You can only upload JPG or PNG file!')
            setFileValid(false)
            return false
        } else if (!isLt2M) {
            message.error('Image should be less than 3mb')
            setFileValid(false)
            return false
        } else {
            return true
        }
    }

    const beforeFileUpload = file => {
        const isLt2M = file.size / 1000 / 1000 < 3

        if (!isLt2M) {
            message.error('Image should be less than 3mb')
            return Upload.LIST_IGNORE
        } else {
            return true
        }
    }

    const onChange = ({ fileList: newFileList }) => {
        onPreview(newFileList[0])
        setFileList(newFileList)
        setFileValid(true)
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        if (fileValid) {
            setShowEditor(true)
            setPictureUrl(src)
            setType('upload')

            setErrors(prev => ({
                ...prev,
                passport: '',
            }))
        }
    }

    const uploadSelfie = img => {
        setType('selfie')
        setPictureUrl(img)
        setShowEditor(true)
    }

    const {
        error: uploadImageError,
        isLoading: uploadImageLoading,
        mutate: uploadImageMutate,
    } = useMutation({
        mutationFn: s3Url => uploadImageApi({ image_url: s3Url }),
        onError: error => setErrorMessage(errorHandler(error)),
    })

    const { isLoading: uploadToS3Loading, mutate: uploadToS3Mutate } =
        useMutation({
            mutationFn: ({ file, category }) => uploadToS3(category, file),
            onSuccess: data => {
                const s3Url = data?.url + data?.fields?.key
                uploadImageMutate(s3Url)
            },
            onError: error => {
                setErrorMessage(errorHandler(error))
            },
        })

    const {
        isLoading: uploadVerificationToS3Loading,
        mutate: uploadVerificationToS3Mutate,
    } = useMutation({
        mutationFn: ({ file, category }) => uploadToS3(category, file),
        onSuccess: data => {
            const s3Url = data?.url + data?.fields?.key
            IdentityMutate(s3Url)
        },
        onError: error => {
            setErrorMessage(errorHandler(error))
        },
    })

    const {
        mutate: IdentityMutate,
        isLoading: identityLoading,
        error: identityError,
    } = useMutation({
        mutationFn: s3Url =>
            uploadIdentificationApi({
                type: identity.identification,
                image_url: s3Url,
            }),

        onSuccess: () => {
            nextHandler()
        },
        onError: error => setErrorMessage(errorHandler(error)),
    })

    const handleSubmit = () => {
        IdentityVerificationDEPOnboardingValidationSchema.validate(
            {
                passport: fileList[0]?.name || pictureUrl,
                id_type: identity?.identification,
                id_image: successUpload[0]?.name,
            },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                setErrors({})
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['onboardingIdentityVerification'].action,
                    eventTrackers['onboardingIdentityVerification'].label,
                    eventTrackers['onboardingIdentityVerification'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {
                            pictureUrl,
                            type: identity.identification,
                            image: successUpload[0].originFileObj,
                        },
                    },
                )

                uploadToS3Mutate({
                    category: 'AVATARS',
                    file: fileList[0] || {
                        name: `selfie uploaded ${format(
                            new Date(),
                            'd-MM-yyyy',
                        )}.png`,
                        type: 'image/png',
                        originFileObj: dataURIToBlob(pictureUrl),
                    },
                })
                uploadVerificationToS3Mutate({
                    category: 'VERIFICATION_ID',
                    file: successUpload[0],
                })
            })
            .catch(error => {
                let errList = {}
                error.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    return (
        <>
            {showEditor ? (
                <ImageEditor
                    setShowEditor={setShowEditor}
                    pictureUrl={pictureUrl}
                    setEditedPicture={setEditedPicture}
                    setEnableCamera={setEnableCamera}
                    type={type === 'selfie' ? 'selfie' : 'upload'}
                    onSave={() => {
                        setErrors(prev => ({
                            ...prev,
                            id_type: '',
                        }))
                    }}
                />
            ) : enableCamera ? (
                <WebCamCapture
                    uploadSelfie={uploadSelfie}
                    setEnableCamera={setEnableCamera}
                    mediumWidth="650"
                />
            ) : (
                <>
                    <div className="IdentityWrapper">
                        <FormSubBox>
                            <FormTitleBar
                                title="Identity Verification"
                                subtitle="We’ve solved the problems that make it difficult for"
                            />
                            {(uploadImageError || identityError) && (
                                <Toast
                                    messageType="error"
                                    message={errorMessage}
                                />
                            )}
                            <div className="pictureWrapper">
                                <p className="Passport">Passport Photograph</p>
                                <div className="ProfileWrapper">
                                    <img
                                        src={
                                            editedPicture
                                                ? editedPicture
                                                : ProfilePicture
                                        }
                                        className="ProfileImg"
                                        alt="profile"
                                    />

                                    <Upload
                                        fileList={fileList}
                                        beforeUpload={beforeUpload}
                                        showUploadList={false}
                                        maxCount={1}
                                        onChange={onChange}
                                        customRequest={dummyRequest}
                                        accept="image/jpeg,image/png"
                                    >
                                        <Button
                                            btnBgColor="var(--purple-light)"
                                            btnTextColor="var(--blue)"
                                            btnTextColorOutline="var(--blue)"
                                            btnOutlineColor="var(--purple-light)"
                                            btnBgColorOutline="#E2EEFF"
                                            type="outline"
                                            btnWidth="130px"
                                            btnHeight="46px"
                                            fontWeight="500"
                                        >
                                            Upload Picture
                                        </Button>
                                    </Upload>

                                    <p className="SelfieWrapper">
                                        OR
                                        <span
                                            className="Selfie"
                                            onClick={() =>
                                                setEnableCamera(true)
                                            }
                                        >
                                            Take a Selfie{' '}
                                        </span>
                                    </p>
                                </div>
                                <p className="ErrorText">{errors?.passport}</p>
                                <div className="Identification">
                                    <p className="Passport">
                                        Means of Identification
                                    </p>
                                    <SelectField
                                        selectWidth="100%"
                                        withCheckBox={true}
                                        values={meansOfIdentification.map(
                                            identity => ({
                                                value: identity.value,
                                            }),
                                        )}
                                        name="identification"
                                        initialOption="Select Identification"
                                        selectedValue={identity.identification}
                                        currentSelected={
                                            identity.identification
                                        }
                                        handleChange={handleSelectChange}
                                        errorMessage={errors?.id_type}
                                        marginBottom="10px"
                                    />
                                    <Dragger
                                        className="DraggerArea"
                                        fileList={successUpload}
                                        maxCount={1}
                                        beforeUpload={beforeFileUpload}
                                        showUploadList={true}
                                        accept="image/jpeg,image/png"
                                        customRequest={() => {}}
                                        {...props}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            {uploading ? (
                                                <div className="SlideContainer">
                                                    <p
                                                        className="Slider"
                                                        style={{
                                                            width: `${width}%`,
                                                        }}
                                                    ></p>
                                                </div>
                                            ) : successUpload.length > 0 ? (
                                                <img
                                                    src={SuccessUpload}
                                                    className="UploadLogo"
                                                    alt="success logo"
                                                />
                                            ) : (
                                                <img
                                                    src={UploadLogo}
                                                    className="UploadLogo"
                                                    alt="upload logo"
                                                />
                                            )}
                                        </p>
                                        <p className="UploadText">
                                            {uploading ? (
                                                <div>Uploading document...</div>
                                            ) : successUpload.length > 0 ? (
                                                <>
                                                    <p className="Upload">
                                                        <u
                                                            className="Browse"
                                                            style={{
                                                                marginTop:
                                                                    '20px',
                                                            }}
                                                        >
                                                            Replace document
                                                        </u>
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        Upload file. Drag and
                                                        drop or
                                                        <u className="Browse">
                                                            browse
                                                        </u>
                                                    </p>
                                                    <p className="OIDSupportedFormatText">
                                                        Supported format: .jpeg,
                                                        .png
                                                    </p>
                                                </>
                                            )}
                                        </p>
                                    </Dragger>
                                    <p className="ErrorText">
                                        {errors?.id_image}
                                    </p>
                                </div>
                            </div>
                            <br />
                        </FormSubBox>
                    </div>
                    <ActionSection
                        stage={3}
                        loading={
                            uploadImageLoading ||
                            identityLoading ||
                            uploadToS3Loading ||
                            uploadVerificationToS3Loading
                        }
                        prevHandler={prevHandler}
                        nextHandler={handleSubmit}
                    />
                </>
            )}
        </>
    )
}

export default IdentityVerification
