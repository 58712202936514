import axios from '../../../config/axios'

export const sseRetrieveBasicInfo = async () => {
    const request = axios.get('consumers/kyc/info')
    return request
}

export const sseSubmitPersonalInfo = async payload => {
    const request = axios.post(`/consumers/kyc/personal-info`, payload)
    return request
}

export const sseSubmitEmploymentInfo = async payload => {
    const request = axios.post(`/consumers/kyc/employment-info`, payload)
    return request
}

export const sseVerifyConsumerBusiness = async payload => {
    const request = axios.post(`/consumers/kyc/verify-business`, payload)
    return request
}

export const getRequestedUpdatesApi = async () => {
    const request = axios.get(
        '/consumers/estimations/requested-updates?paginate=false',
    )
    return request
}

export const removeKYCFileApi = async payload => {
    const request = axios.post('/consumers/kyc/remove-file', payload)
    return request
}
