import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import UseType from 'src/components/GettingStarted/components/UseType'
import 'src/components/GettingStarted/gettingstarted.scss'
import Verify from 'src/components/GettingStarted/components/Verify'
import RequiredInfo from 'src/components/GettingStarted/components/RequiredInfo'
import AddEmail from 'src/components/GettingStarted/components/AddEmail'
import EmailSent from 'src/components/GettingStarted/components/EmailSent'
import {
    useTypeSchema,
    otpSchema,
    continueLaterEmailSchema,
} from 'src/utils/validationSchema'
import { isMobile } from 'src/utils/mediaQueries'
import {
    changeUseTypeApi,
    resendOTP,
    validateOTP,
} from 'src/api/shoppingExperience/account-overview'
import { proceedKYCLater } from 'src/api/shoppingExperience/get-system'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { formatPhoneNumber } from 'src/utils/formatting'

const SwitchUseType = ({
    showModal,
    setShowModal,
    useType = '',
    contact,
    contact_details,
    id,
    fetchEstimation,
}) => {
    const [step, setStep] = useState(1)
    const [inputs, setInputs] = useState({})
    const [errors, setErrors] = useState({})
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const [resent, setResent] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [showToastError, setShowToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        setInputs({
            useType: useType,
            ...(contact === 'email'
                ? { email: contact_details }
                : { phone: contact_details }),
        })
    }, [contact, contact_details, useType])

    // change use type
    const changeUseTypeMutation = useMutation({
        mutationFn: () =>
            changeUseTypeApi(id, {
                customer_type:
                    useType === 'Business use' ? 'BUSINESS' : 'RESIDENTIAL',
                ...(contact === 'email'
                    ? { email: inputs.email }
                    : { phone_number: formatPhoneNumber(inputs.phone) }),
            }),
        onSuccess: () => {
            if (
                contact_details ===
                inputs[`${contact === 'email' ? 'email' : 'phone'}`]
            ) {
                setStep(3)
                fetchEstimation()
            } else {
                setStep(2)
            }
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    // submit otp when contact details are changed
    const validateContactOTP = useMutation({
        mutationKey: ['validate-otp'],
        mutationFn: () =>
            validateOTP({
                code: otp.join(''),
                ...(contact === 'email'
                    ? { email: inputs.email }
                    : { phone_number: formatPhoneNumber(inputs.phone) }),
            }),
        onSuccess: () => {
            setStep(3)
            fetchEstimation()
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    //resend OTP
    const handleResendSignupOtp = useMutation({
        mutationFn: () =>
            resendOTP(
                contact === 'email'
                    ? { email: inputs.email }
                    : { phone_number: formatPhoneNumber(inputs.phone) },
            ),
        onSuccess: () => {
            setResent(true)
            const timeout = setTimeout(() => setResent(false), [2000])
            return () => clearTimeout(timeout)
        },
        onError: error => {
            setShowToastError(true)
            setResent(false)
            setErrorMessage(errorHandler(error?.response.data))
        },
    })

    // proceed kyc later
    const proceedKYCLaterMutation = useMutation({
        mutationKey: ['proceedKYCLater'],
        mutationFn: () => proceedKYCLater({ email: inputs.email }),
        onSuccess: () => {
            setStep(5)
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const stepMapping = {
        1: (
            <UseType
                contact={contact}
                inputs={inputs}
                setInputs={setInputs}
                errors={errors}
                setErrors={setErrors}
                useTypeContent={'confirm-otp-method'}
            />
        ),
        2: (
            <Verify
                contact={contact}
                otp={otp}
                setOtp={setOtp}
                otpError={otpError}
                inputs={inputs}
                handleResendOtp={() => handleResendSignupOtp.mutate()}
                resent={resent}
                setResent={setResent}
            />
        ),
        3: <RequiredInfo inputs={inputs} />,
        4: (
            <AddEmail
                email={email}
                setEmail={setEmail}
                emailError={emailError}
            />
        ),
        5: <EmailSent />,
    }

    const btnTextMapping = {
        1: 'Confirm',
        2: `Verify ${contact === 'email' ? 'Email' : 'Phone'}`,
        3: 'Yes, Continue',
        4: 'Confirm',
        5: 'Great, Thank you',
    }

    const handleSaveUseType = () => {
        useTypeSchema
            .validate(inputs, {
                context: { contact: contact },
                abortEarly: false,
            })
            .then(() => {
                setErrors('')
                changeUseTypeMutation.mutate()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    // submit otp
    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                validateContactOTP.mutate()
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    // submit email to continue later
    const handleContinueLaterEmailSubmit = () => {
        continueLaterEmailSchema
            .validate({ email: email }, { abortEarly: false })
            .then(() => {
                setEmailError('')
                proceedKYCLaterMutation.mutate()
            })
            .catch(err => {
                if (err && err.inner && err.inner.length > 0) {
                    setEmailError(err.inner[0].message)
                }
            })
    }

    const closeModal = () => {
        setShowModal(false)
        setStep(1)
        setInputs({
            useType: useType,
            ...(contact === 'email'
                ? { email: contact_details }
                : { phone: contact_details }),
        })
        setErrors({})
        setOtp(['', '', '', '', '', ''])
        setOtpError(false)
        setEmail('')
        setEmailError('')
    }

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={() => closeModal()}
            modalWidth={'475px'}
            title={
                [1, 2, 3].includes(step)
                    ? 'Getting Started on SunFi'
                    : 'Save to continue later'
            }
            v2
            content={
                <>
                    {showToastError && (
                        <Toast
                            messageType="error-secondary"
                            message={errorMessage}
                            closable={true}
                        />
                    )}
                    {stepMapping[step]}
                </>
            }
            showFooter
            footerLeftContent={step === 3 ? 'button' : step === 5 ? 'chat' : ''}
            primaryBtnText={btnTextMapping[step]}
            handlePrimaryBtnClick={() => {
                if (step === 1) {
                    handleSaveUseType()
                }
                if (step === 2) {
                    handleOtpSubmit()
                }
                if (step === 3) {
                    closeModal()
                }

                if (step === 4) {
                    handleContinueLaterEmailSubmit()
                }

                if (step === 5) {
                    window.location.href = '/'
                }
            }}
            primaryBtnWidth={isMobile ? '127.15px' : '160px'}
            primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            primaryBtnLoading={
                changeUseTypeMutation.isLoading ||
                validateContactOTP.isLoading ||
                proceedKYCLaterMutation.isLoading
            }
            secondaryBtnWidth={isMobile ? '140px' : '160px'}
            secondaryBtnHeight={isMobile ? '44.5px' : '53px'}
            secondaryBtnText={"I don't have these"}
            handleSecondaryBtnClick={() => {
                if (step === 3) {
                    if (contact === 'email') {
                        setStep(5)
                    } else {
                        setStep(4)
                    }
                }
            }}
            data-testid="switch-use-type-modal"
        />
    )
}

SwitchUseType.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    useType: PropTypes.string,
    contact: PropTypes.oneOf(['email', 'phone']),
    contact_details: PropTypes.string,
    id: PropTypes.string,
    fetchEstimation: PropTypes.func,
}

export default SwitchUseType
