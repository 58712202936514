import { useState, useEffect } from 'react'
import { ProductHeader } from '../ProductHeader'
import Button from '../../Button'
import { InlineLoader } from '../../Loader'
import SeoComponent from '../../Seo'
import PropTypes from 'prop-types'
import './addimage.scss'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { ReactComponent as CloseIcon } from 'src/assets/images/consumer-close-icon.svg'
import { ReactComponent as PlusIcon } from 'src/assets/images/Plus-Blue.svg'
import { useUploadMultipleToS3 } from 'src/utils/Hooks/useUploadToS3'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import ActionModal from '../../ActionModal'
import ImageUpload from './ImageUpload'

const AddImage = ({
    isEdit,
    handleStage,
    setImages,
    imageFiles,
    setImageFiles,
    saveLoading,
    handleSavePackage,
}) => {
    const { errorAlert } = useCustomToast()
    const [uploadElements, setUploadElements] = useState([[]])
    const [showActionModal, setShowActionModal] = useState(false)
    const [hasPendingUploads, setHasPendingUploads] = useState(false)
    const [currentElementIndex, setCurrentElementIndex] = useState(null)

    // Image upload
    const { uploadMultipleFilesToS3Loading, uploadMultipleToS3Mutate } =
        useUploadMultipleToS3({
            onSuccess: data => {
                setImages(data)
                setHasPendingUploads(false)

                if (isEdit) {
                    handleSavePackage()
                } else {
                    handleStage('components')
                }
            },
            onError: e => {
                errorAlert(errorHandler(e) || 'Something went wrong')
            },
        })

    // app tracker variables
    const pageTitle = isEdit
        ? 'SunFi - Edit Product | Edit Images of this product'
        : 'SunFi - Add A Package | Add Images of this package'
    const pageUrlName = window.location.pathname
    const pageTracker = 'ADD_PACKAGE_TRACKER'

    const handleSetSuccessUpload = (index, imageList) => {
        const updatedUploadElements = [...uploadElements]
        updatedUploadElements[index] = imageList
        setUploadElements(updatedUploadElements)
        setImageFiles?.(updatedUploadElements.flat())
        setHasPendingUploads(true)
    }

    const handleAddUploadElement = () => {
        if (uploadElements.length < 3) {
            setUploadElements(prev => [...prev, []])
        }
    }

    const handleRemoveUploadElement = index => {
        if (uploadElements[index]) {
            if (!uploadElements[index].length) {
                if (uploadElements.length > 1) {
                    const updatedUploadElements = [...uploadElements]
                    updatedUploadElements.splice(index, 1)
                    setUploadElements(updatedUploadElements)
                }
            } else {
                setCurrentElementIndex(index)
                setShowActionModal(true)
            }
        }
    }

    const removeUploadElementActionHandler = () => {
        if (
            currentElementIndex !== null &&
            uploadElements[currentElementIndex]
        ) {
            const updatedUploadElements = [...uploadElements]

            if (currentElementIndex === 0 && uploadElements.length === 1) {
                updatedUploadElements.splice(currentElementIndex, 1, [])
            } else {
                updatedUploadElements.splice(currentElementIndex, 1)
            }

            setUploadElements(updatedUploadElements)
            setImageFiles?.(updatedUploadElements.flat())

            setShowActionModal(false)
            setCurrentElementIndex(null)
            setHasPendingUploads(true)
        }
    }

    const handleActionModalClose = () => {
        setShowActionModal(false)
        setCurrentElementIndex(null)
    }

    const handleUploadImages = () => {
        if (imageFiles.length) {
            if (hasPendingUploads) {
                uploadMultipleToS3Mutate({
                    category: 'COMPONENTS',
                    files: imageFiles,
                })
            }
        }
    }

    useEffect(() => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['addPackageImage'].action,
            eventTrackers['addPackageImage'].label,
            eventTrackers['addPackageImage'].category,
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (imageFiles?.length) {
            const elements = imageFiles.map(file => [file])
            setUploadElements(elements)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <SeoComponent
                title={
                    isEdit
                        ? 'SunFi - Edit Product | Edit Images of this product'
                        : 'SunFi - Add A Package | Add Images of this package'
                }
                tracker="AddPackage"
            />
            <ActionModal
                actionModalOpen={showActionModal}
                headerText="Confirm you want to remove this image"
                subTitle="You’re about to remove an image already uploaded for this package"
                closeModal={handleActionModalClose}
                actionHandler={removeUploadElementActionHandler}
                actionType="warning"
                actionText="Confirm"
                cancelText="Cancel"
            />

            <div className="AddImageContentWrapper">
                <div className="AddImageRow">
                    <div className="AddImageLeftContent">
                        <ProductHeader
                            title={isEdit ? '' : 'Create New Product Package'}
                            subtitle={
                                isEdit
                                    ? 'Edit Images of this product'
                                    : 'Add Images of this package'
                            }
                        />
                        <div className="AddImageParentWrapper">
                            {uploadElements.map((successUpload, index) => (
                                <div key={index} className="AddImageWrapper">
                                    <ImageUpload
                                        successUpload={successUpload}
                                        setSuccessUpload={imageList =>
                                            handleSetSuccessUpload(
                                                index,
                                                imageList,
                                            )
                                        }
                                        isLoading={
                                            uploadMultipleFilesToS3Loading
                                        }
                                    />
                                    <button
                                        onClick={() =>
                                            handleRemoveUploadElement(index)
                                        }
                                        disabled={
                                            uploadElements.length === 1 &&
                                            !uploadElements[0].length
                                        }
                                        style={{
                                            cursor:
                                                uploadElements.length === 1 &&
                                                !uploadElements[0].length
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                        }}
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                            ))}
                        </div>
                        {uploadElements.length < 3 && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '10px',
                                }}
                            >
                                <button
                                    className={`AddNewImageButton ${
                                        imageFiles.length <
                                        uploadElements.length
                                            ? 'BtnDisabled'
                                            : ''
                                    }`}
                                    onClick={handleAddUploadElement}
                                    disabled={
                                        imageFiles.length <
                                        uploadElements.length
                                    }
                                >
                                    <PlusIcon />
                                    <span>Add a new image</span>
                                </button>
                            </div>
                        )}
                        <div className="AddImageBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage(isEdit ? '' : 'name')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={handleUploadImages}
                                disabled={
                                    uploadMultipleFilesToS3Loading ||
                                    saveLoading ||
                                    !imageFiles?.length
                                }
                            >
                                {uploadMultipleFilesToS3Loading ||
                                saveLoading ? (
                                    <InlineLoader />
                                ) : isEdit ? (
                                    'Save Changes'
                                ) : (
                                    'Continue'
                                )}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <DescriptionComponent text="We’ve solved the problems that make it difficult for energy providers to transition millions" />
                        <div className="MobileAddImageBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage(isEdit ? '' : 'name')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={handleUploadImages}
                                disabled={
                                    uploadMultipleFilesToS3Loading ||
                                    saveLoading ||
                                    !imageFiles?.length
                                }
                            >
                                {uploadMultipleFilesToS3Loading ||
                                saveLoading ? (
                                    <InlineLoader />
                                ) : isEdit ? (
                                    'Save Changes'
                                ) : (
                                    'Continue'
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

AddImage.propTypes = {
    isEdit: PropTypes.Boolean,
    handleStage: PropTypes.func,
    handleSavePackage: PropTypes.func,
    saveLoading: PropTypes.Boolean,
    images: PropTypes.array,
    setImages: PropTypes.func,
    imageFiles: PropTypes.array,
    setImageFiles: PropTypes.func,
}

export default AddImage
