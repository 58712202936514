import axios from 'src/config/axios'

export const getPackages = async (
    pageNumber,
    searchText = '',
    providerId = '',
    clusterPackagesCode = '',
    minCapacity = '',
    maxCapacity = '',
) => {
    const response = await axios.get(
        `/admin/packages?page=${pageNumber}&name=${searchText}&provider_id=${providerId}&cluster_packages_code=${clusterPackagesCode}&minimum_capacity=${minCapacity}&maximum_capacity=${maxCapacity}`,
    )
    return response
}

export const createProfile = async data => {
    const response = await axios.post('/admin/energy-profiles', data)
    return response
}

export const getProfile = async id => {
    const response = await axios.get(`/admin/energy-profiles/${id}`)
    return response
}

export const updateProfile = async data => {
    const response = await axios.patch(
        `/admin/energy-profiles/${data.id}`,
        data,
    )
    return response
}

export const getAllInverterCapacities = async (sortChoice = 'ascending') => {
    const response = await axios.get(
        `/admin/energy-profiles/global-packages-inverter-capacity?sort_choice=${sortChoice}`,
    )
    return response
}
