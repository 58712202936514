import axios from '../../config/axios'

import { dataURIToBlob } from '../../utils/imageBlob'

export const verifyConsumerEmail = async inputs => {
    const request = await axios.post(
        '/auth/consumers/email-verification/validate',
        inputs,
    )
    return request
}

export const updateConsumerInformation = async inputs => {
    let formData = new FormData()
    if (inputs.tab === 2 && inputs.avatar !== null) {
        const file = dataURIToBlob(inputs.avatar)
        formData.append('avatar', file, 'avatar.jpg')
    }

    if (inputs.tab === 2) {
        formData.append('city', inputs.city)
        formData.append('email', inputs.email)
        formData.append('first_name', inputs.first_name)
        formData.append('last_name', inputs.last_name)
        formData.append('phone_number', inputs.phone_number)
        formData.append('state_of_residence', inputs.state_of_residence)
        formData.append('street_address', inputs.street_address)
        inputs.identity_verification.type !== undefined &&
            formData.append(
                'identity_verification',
                JSON.stringify(inputs.identity_verification),
            )
    }

    if (
        inputs.tab === 2 &&
        inputs.business_name !== undefined &&
        inputs.business_registration_number !== undefined
    ) {
        formData.append('business_name', inputs.business_name)
        formData.append(
            'business_registration_number',
            inputs.business_registration_number,
        )
    }

    if (inputs.tab === 3) {
        formData.append('appliances', JSON.stringify(inputs.appliances))
        formData.append('backup_hours', inputs.backup_hours)
        formData.append('can_mount_solar', inputs.can_mount_solar)
        formData.append('residence_type', inputs.residence_type)
    }

    if (
        inputs.tab === 4 &&
        inputs.bank_statement !== false &&
        inputs.bank_statement !== undefined
    ) {
        formData.append('bank_statement', inputs.bank_statement)
    }

    if (
        inputs.tab === 4 &&
        inputs.mono_code !== undefined &&
        inputs.mono_code !== ''
    ) {
        formData.append('mono_code', inputs.mono_code)
    }

    if (
        inputs.tab === 4 &&
        inputs.employer_business_registration_number !== undefined &&
        inputs.employer_business_registration_number !== ''
    ) {
        formData.append(
            'employer_business_registration_number',
            inputs.employer_business_registration_number,
        )
    }

    if (inputs.tab === 4) {
        formData.append('income_amount_range', inputs.income_amount_range)
        inputs.employment_status !== null &&
            formData.append('employment_status', inputs.employment_status)
        inputs.employer_business_name !== undefined &&
            formData.append(
                'employer_business_name',
                inputs.employer_business_name,
            )
    }
    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    const request = await axios.post(
        `consumers/offer/${inputs.estimation_id}/update`,
        formData,
        {
            headers,
        },
    )

    return request
}
