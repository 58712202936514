import ChatCTA from 'src/components/ChatCTA'
import styles from '../solarsystemslistingsv2.module.scss'
import PropTypes from 'prop-types'
import SolarCity from 'src/assets/images/frame.svg'
import HandShake from 'src/assets/images/handshake.svg'
import ButtonComponent from 'src/components/Button'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { useSharedForm } from '../hooks/useSharedForm'
import '../sharedform.scss'
import { InlineLoader } from 'src/components/Loader'
import { useSolarSystemsContext } from '../hooks/useSolarSystems'

const SharedForm = ({ view }) => {
    const { SharedFormWrapper, ClosedClusterWrapper } = styles
    const isPackage = view?.isPackage

    const { isClosedCluster, getEnergyProfileData } = useSolarSystemsContext()
    const closedClusterData = getEnergyProfileData?.data?.data

    const {
        errors,
        inputs,
        setInputs,
        handleInputChange,
        validateUserIdMutation,
        handleGetStarted,
        registerDropOffLoading,
        handleClosedClusterGetStarted,
    } = useSharedForm(closedClusterData, isClosedCluster)

    const handleSelectChange = (name, value, errors, setInputs) => {
        setInputs(prev => ({ ...prev, [name]: value }))
        if (errors[name]) {
            delete errors[name]
        }
    }

    const closedClusterLogo = closedClusterData?.cluster_origination?.logo
    return (
        <div
            className={`${SharedFormWrapper} ${
                isClosedCluster && ClosedClusterWrapper
            }`}
        >
            <div>
                <div>
                    <img
                        src={closedClusterLogo ?? HandShake}
                        alt="handshake"
                        width={234}
                        height={104}
                    />
                    <h2>
                        {isClosedCluster
                            ? `Hey, welcome to SunFi!`
                            : `Welcome to SunFi`}
                    </h2>
                    {isPackage ? (
                        <h3>
                            The link to this package;
                            <span> {view.name} </span>
                            has been shared with you. Please enter your email
                            address to continue.
                        </h3>
                    ) : isClosedCluster ? (
                        <>
                            <h3>
                                You have been invited to select a system. Select
                                a system that best fit your energy needs
                            </h3>
                        </>
                    ) : (
                        <h3>
                            We&apos;re thrilled to have you here. Feel free to
                            explore and discover all that we have to offer.
                            Please enter your email address to continue.
                        </h3>
                    )}

                    <div>
                        <div className={'SharedFormNewInputsWrapper'}>
                            {isClosedCluster && (
                                <div className="NewFormInputsClosedClusterText">
                                    <h4>Add your work email address</h4>
                                    <p>
                                        Ensure the provided email is your work
                                        email address.
                                    </p>
                                </div>
                            )}
                            <InputFields
                                name="firstName"
                                value={inputs.firstName}
                                handleChange={handleInputChange}
                                paddingBottom="18px"
                                title="Enter your first name"
                                errorMessage={errors['firstName']}
                                exemptMouseOut
                            />
                            <InputFields
                                name="email"
                                value={inputs.email}
                                handleChange={handleInputChange}
                                paddingBottom="18px"
                                title={
                                    isClosedCluster
                                        ? 'Your work email address'
                                        : 'Enter your email address'
                                }
                                errorMessage={
                                    errors['email'] ||
                                    errors['checkExistingStatus']
                                }
                                activateEnterClick
                            />
                            <SelectCountryCode
                                handleChange={handleInputChange}
                                handleSelectChange={(name, value) =>
                                    handleSelectChange(
                                        name,
                                        value,
                                        errors,
                                        setInputs,
                                    )
                                }
                                title="Enter your phone number"
                                name="phone"
                                inputValue={inputs.phone}
                                errorMessage={
                                    errors['phone'] ||
                                    errors['checkExistingStatus']
                                }
                                sseUse={true}
                                activateEnterClick
                            />
                        </div>
                    </div>

                    <div>
                        <ButtonComponent
                            btnBgColor="#004AAD"
                            btnTextColor="#fff"
                            btnWidth={'100%'}
                            marginTop={'16px'}
                            handleClick={() => {
                                isClosedCluster
                                    ? handleClosedClusterGetStarted()
                                    : handleGetStarted()
                            }}
                        >
                            {registerDropOffLoading ||
                            validateUserIdMutation.isLoading ? (
                                <InlineLoader />
                            ) : (
                                'Get Started'
                            )}
                        </ButtonComponent>
                        <ChatCTA />
                    </div>
                </div>
                <div>
                    <img src={SolarCity} alt="shared-form-layout-right-img" />
                </div>
            </div>
        </div>
    )
}

SharedForm.propTypes = {
    view: PropTypes.object,
}

export default SharedForm
