import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import FormTitleBar from 'src/components/FormTitleBar'
import ActionModal from 'src/components/ActionModal'
import { TextArea, SelectField } from 'src/components/InputFields'
import Button from 'src/components/Button'
import CloseIcon from 'src/assets/images/black-close-icon.svg'
import Toast from 'src/components/Toast'
import { InlineLoader } from 'src/components/Loader'
import {
  changeProviderTypeApi,
  updateProviderStatusApi,
} from 'src/api/admin/provider'
import { errorHandler } from 'src/utils/errorHandler'
import './pending-provider-modal.scss'

const PendingProviderModal = ({ selected, headerText, btnText, closeModal, providerId }) => {

  const [reason, setReason] = useState('')
  const [providerType, setProviderType] = useState('')
  const [declineProvider, setDeclineProvider] = useState(false)
  const [changeProvider, setChangeProvider] = useState(false)
  const [changeSuccess, setChangeSuccess] = useState(false)
  const [toastError, setToastError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState('')
  const [changeProviderInputs, setChangeProviderInputs] = useState('')
  const [providerTypeAbbreviation, setProviderTypeAbbreviation] = useState('')

  const handleInputChange = event => {
    setReason(event.target.value)
  }

  const providerTypes = [
    'Manufacturer that installs',
    'Distributor that installs',
    'Associate Installer',
  ]

  const handleModalAction = () => {
    setChangeProvider(false)
  }

  const handleChangeProvider = () => {
    setChangeSuccess(true)
    changeProviderTypeRefetch()
  }


  const handleSubmit = () => {
    if (selected === 'Change Type') {
      if (providerType === '') {
        setErrors({ providerType: 'This field is required' })
      } else {
        if (providerType === 'Manufacturer that installs') {
          setChangeProviderInputs('OEM Installer')
        } else if (providerType === 'Distributor that installs') {
          setChangeProviderInputs('Distributor Installer')
        } else {
          setChangeProviderInputs('Associate Installer')
        }
        closeModal()
        setChangeProvider(true)
      }
    }

    if (selected === 'Decline Provider') {
        if (reason === '') {
            setErrors({ reason: 'This field is required' })
        } else {
          declineProviderRefetch()
        }
    }
  }

  //change provider type
  const {
    refetch: changeProviderTypeRefetch,
    isLoading: changeProviderTypeLoading,
  } = useQuery(
    ['change-active-provider-type'],
    () =>
      changeProviderTypeApi(
        providerId, changeProviderInputs
      ),
    {
      enabled: false,
      retry: false,
      onSuccess: () => {
        setChangeProvider(false)
        setDeclineProvider(true)
      },
      onError: error => {
        setToastError(true)
        setErrorMessage(errorHandler(error?.response?.data))
      },
    },
  )

  const declineProviderPayload = {
    reason,
    status: 'SUSPENDED'
  }

  //decline a provider 
  const {
    refetch: declineProviderRefetch,
    isLoading: declineProviderLoading,
} = useQuery(
    ['decline-admin-provider'],
    () => updateProviderStatusApi(providerId, declineProviderPayload),
    {
        enabled: false,
        retry: false,
        onSuccess: () => {
          setDeclineProvider(true)
          closeModal()
        },
        onError: error => {
            setErrorMessage(errorHandler(error?.response?.data))
        },
    },
)

  // get shortened version of provider name for the warning modal
  useEffect(() => {
    setProviderTypeAbbreviation(
      providerType === 'Manufacturer that installs'
        ? 'OEMI'
        : providerType === 'Distributor that installs'
          ? 'DISI'
          : 'AI'
    )
  }, [providerType])

  const isMobile = window.innerWidth < 501


  return (
    <>
      <ActionModal
        actionModalOpen={declineProvider}
        headerText={changeSuccess ? 'Type Successfully Changed' : "Provider Declined Successfully"}
        subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
        actionHandler={() => {
          setDeclineProvider(false)
          window.location.href='/admin/providers?tab=3'
        }}
        actionType="success"
        actionText="Okay, Got it"
        loading={''}
        noCancelBtn={true}
        noBackLink
      />
      <ActionModal
        actionModalOpen={changeProvider}
        headerText={`Change Provider to ${providerTypeAbbreviation}?`}
        subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
        closeModal={handleModalAction}
        actionHandler={handleChangeProvider}
        actionType="warning"
        actionText="Change"
        loading={changeProviderTypeLoading}
        cancelText='Cancel'
        noBackLink
      />
      <div className='APPModal'>
        <div className='APPModalTitle'>
          <span className="AWCloseIconWrapper">
            <img
              src={CloseIcon}
              alt="close icon"
              onClick={closeModal}
              style={{ width: '27px' }}
            />
          </span>
          <FormTitleBar
            title={headerText}
            subtitle='Enter the details of the request below to continue'
            fontFamily='SF-Pro-Display-Semibold'
            titleFontSize='20px'
            alignLeft={isMobile}
          />
        </div>
        {toastError && <Toast messageType="error" message={errorMessage} />}
        <div className='APPModalInputs'>
          {selected === 'Change Type' ?
            <SelectField
              initialOption={'Select Provider Type'}
              selectWidth="464px"
              withCheckBox={true}
              name='providerType'
              currentSelected={providerType}
              selectedValue={providerType}
              values={providerTypes.map(
                option => ({
                  value: option,
                }),
              )}
              handleChange={(name, value) => {
                setProviderType(value)
              }}
              type="approvedModal"
              errorMessage={errors?.providerType}
            />
            : <TextArea
              placeholder="Enter Reason"
              name="declineReason"
              width="464px"
              height="146px"
              maxHeight="146px"
              value={reason}
              handleChange={handleInputChange}
              errorMessage={errors?.reason}
            />}
        </div>
        <div className='APPModalBtn'>
          <Button
            btnHeight='56px'
            btnWidth='160px'
            btnBgColor='var(--blue)'
            btnTextColor='var(--white)'
            handleClick={handleSubmit}
          >
            {declineProviderLoading ? <InlineLoader /> : btnText}
          </Button>
        </div>
      </div>
    </>
  )
}

PendingProviderModal.propTypes = {
  selected: PropTypes.string,
  headerText: PropTypes.string,
  btnText: PropTypes.string,
  closeModal: PropTypes.func,
  providerId: PropTypes.string,
}

export default PendingProviderModal