import 'src/pages/Admin/PaymentPlans/Listings/paymentplanslistings.scss'
import { Dropdown, Tabs } from 'antd'
import ButtonComponent from 'src/components/Button'
import s from './profileslistings.module.scss'
import './adminprofileslistings.scss'
import ProfilesListingsTable from './components/Table'
import SeoComponent from 'src/components/Seo'
import { ReactComponent as AddIcon } from 'src/assets/images/plus-icon-filled.svg'
import ListingsMenu from './components/Menu'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import { profileCreationModalData, tabs } from './data'
import EmptyState from 'src/components/EmptyState'
import useAdminProfiles from './hooks/useAdminProfiles'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ProfileCreationModal from './components/ProfileCreationModal'
import { canCreateEnergyProfile, canViewProfileGroups } from './formatter'

const AdminProfiles = () => {
    const {
        currentTab,
        profiles,
        profilesFetching,
        onTabChange,
        paginationBlock,
        goToCreateProfile,
        searchTextInput,
        setSearchTextInput,
        handleFilterChange,
        addSearchTextToFilter,
        profilesRefetch,
        goToProfileGroups,
        showProfileCreation,
        closeProfileCreationModal,
        displayProfileCreationModal,
    } = useAdminProfiles()

    const showOldFlow = () => {
        const filteredResults = profileCreationModalData?.filter(
            item => !item?.disabled,
        )
        const result =
            filteredResults?.length === 1 && filteredResults[0]?.id === 2
        return result
    }

    return (
        <>
            <SeoComponent title="SunFi | Admin | Consumer Profiles" />

            {/* page */}
            <div className={s.wrapper} data-testid="admin-profiles-wrapper">
                <div className={s.titleAndMobileBtn}>
                    <h1 className={s.title}>Consumer Profiles</h1>
                    <Dropdown
                        overlay={
                            <ListingsMenu
                                handleProfileGroups={goToProfileGroups}
                                handleCreateProfile={() => {
                                    showOldFlow()
                                        ? goToCreateProfile()
                                        : displayProfileCreationModal()
                                }}
                            />
                        }
                        placement="bottomCenter"
                        trigger={['click']}
                        className={s.dropdown}
                    >
                        <a
                            onClick={e => e.preventDefault()}
                            data-testid="add-icon"
                        >
                            <AddIcon />
                        </a>
                    </Dropdown>
                </div>
                <div className={s.searchAndBtn}>
                    <div className={s.search}>
                        <input
                            placeholder="Search"
                            value={searchTextInput}
                            onChange={e => {
                                if (e.target.value === '') {
                                    setSearchTextInput('')
                                    handleFilterChange({ searchText: '' })
                                } else {
                                    const value = e.target.value
                                    setSearchTextInput(value)
                                }
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    addSearchTextToFilter()
                                    e.target.blur()
                                }
                            }}
                            onBlur={() => addSearchTextToFilter()}
                            data-testid="search-input"
                        />
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role={'button'}
                            onClick={() => profilesRefetch()}
                            data-testid="search-icon"
                        >
                            <path
                                d="M11.0207 10.0777L13.876 12.9323L12.9327 13.8757L10.078 11.0203C9.01586 11.8718 7.69471 12.3349 6.33337 12.333C3.02137 12.333 0.333374 9.64501 0.333374 6.33301C0.333374 3.02101 3.02137 0.333008 6.33337 0.333008C9.64537 0.333008 12.3334 3.02101 12.3334 6.33301C12.3353 7.69434 11.8722 9.0155 11.0207 10.0777ZM9.68337 9.58301C10.5294 8.71293 11.0019 7.54663 11 6.33301C11 3.75434 8.91137 1.66634 6.33337 1.66634C3.75471 1.66634 1.66671 3.75434 1.66671 6.33301C1.66671 8.91101 3.75471 10.9997 6.33337 10.9997C7.54699 11.0016 8.7133 10.5291 9.58337 9.68301L9.68337 9.58301Z"
                                fill={'#000000'}
                            />
                        </svg>
                    </div>
                    <div className={s.createBtnWrapper}>
                        <ButtonComponent
                            btnWidth={'170px'}
                            btnHeight={'50px'}
                            type="secondary"
                            handleClick={() => goToProfileGroups()}
                            dataTestId={
                                'admin-profiles-listings-go-to-groups-btn'
                            }
                            disabled={!canViewProfileGroups}
                        >
                            Manage profile groups
                        </ButtonComponent>
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnWidth={'204.43px'}
                            btnHeight={'50px'}
                            btnTextColor={'#FFFFFF'}
                            handleClick={() => {
                                showOldFlow()
                                    ? goToCreateProfile()
                                    : displayProfileCreationModal()
                            }}
                            dataTestId={
                                'admin-profiles-listings-create-profile-btn'
                            }
                            disabled={!canCreateEnergyProfile}
                        >
                            Create a new consumer profile
                        </ButtonComponent>
                    </div>
                </div>

                <SecondaryModal
                    closable={true}
                    showModal={showProfileCreation}
                    onCancel={closeProfileCreationModal}
                    modalWidth="459px"
                    content={<ProfileCreationModal />}
                />

                {profilesFetching ? (
                    <ListingsSkeletalCards total={2} columnCount={5} />
                ) : (
                    <Tabs activeKey={currentTab} onChange={onTabChange}>
                        {tabs?.map(tab => (
                            <Tabs.TabPane tab={tab?.tab} key={tab?.key}>
                                {profiles?.length > 0 ? (
                                    <ProfilesListingsTable
                                        profiles={profiles}
                                    />
                                ) : (
                                    <EmptyState title="No consumer profiles found" />
                                )}
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                )}
            </div>

            {profiles?.length > 0 && !profilesFetching && (
                <div className="PaymentPlansListings_Pagination">
                    {paginationBlock()}
                </div>
            )}
        </>
    )
}

export default AdminProfiles
