import styles from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'
import '../../GettingStarted/gettingstarted.scss'
import { kycPageTypes } from 'src/components/GettingStarted/utils'
import PropTypes from 'prop-types'

const KYCPageType = ({ kycPageType, handleKYCPageSelection }) => {
    const { methodDiv, methodDivActive, methodText, customStrokeAndFillStyle } =
        styles
    return (
        <div className={'GettingStartedWrapper UseType'}>
            <div className={'GettingStartedHeadingAndText'}>
                <h3>How would you like to complete your KYC process?</h3>
                <p>
                    You can either use the streamlined one-page KYC process or
                    choose the old multi-step design. Both options help us
                    verify your account and get started with your KYC.
                </p>
            </div>
            {kycPageTypes?.map((each, i) => (
                <div
                    key={i}
                    role="button"
                    className={`${methodDiv} ${
                        kycPageType === each?.key ? methodDivActive : ''
                    } ${
                        each?.key === 'one-page-kyc'
                            ? customStrokeAndFillStyle
                            : ''
                    }`}
                    onClick={() => handleKYCPageSelection(each?.key)}
                >
                    <div className={methodText}>
                        <h4>{each?.title}</h4>
                        <p>{each?.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

KYCPageType.propTypes = {
    kycPageType: PropTypes.string,
    handleKYCPageSelection: PropTypes.func,
}

export default KYCPageType
