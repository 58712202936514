import { useState } from 'react'
import PropTypes from 'prop-types'
import { InputFields, SelectField } from 'src/components/InputFields'
import Label from '../../_components/Label'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import {
    emailRegex,
    otpSchema,
    phoneNumberRegex,
} from 'src/utils/validationSchema'
import Verify from 'src/components/GettingStarted/components/Verify'

import Toast from 'src/components/Toast'
import { handlePhoneNumberValidation } from 'src/utils/validatePhoneNumber'
import { formatPhoneNumber } from 'src/utils/formatting'
import {
    useSSEAuthInitiateOtp,
    useSSEAuthValidateOtp,
} from 'src/api/shoppingExperience/auth/queries'
import { usePersonalInfoContext } from '../../context'
import CaretCircle from '../../../../../../../assets/images/circle-right-white.svg'
import { localGovtData, stateData } from 'src/utils/stateData'
import { decodeUserInfo } from 'src/utils/auth'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import useScrollToError from '../../../hooks/useScrollToError'
import FormSkeleton from '../../../_components/FormSkeleton'
import { useAppContext } from 'src/appContext/AppContext'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const PersonalInfo = ({
    updateErrors,
    removeFromUpdateErrs,
    setShowVideoModal,
}) => {
    const { isMobile } = useMediaQueries()
    const { sseBasicInfoData, sseBasicInfoFetching } = useSSERetrieveBasicInfo({
        enabled: true,
    })
    const basicInfo = sseBasicInfoData?.data?.data
    const isBvnVerified =
        basicInfo?.financial_verification?.bvn?.verified || false
    const isIdVerified = basicInfo?.identity_verification?.verified || false

    const pageTitle =
        'KYC - Residential Basic Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const { personalInfoFormik, persistInfo, persistResidentialInfoLoading } =
        usePersonalInfoContext()
    const { isCustomerApproved } = useAppContext()
    const {
        handleBlur,
        values,
        errors,
        handleChange,
        touched,
        setFieldTouched,
        setFieldError,
        setFieldValue,
        setValues,
    } = personalInfoFormik
    const [verificationModal, setVerificationModal] = useState({
        open: false,
        type: '',
    })
    const [otpResent, setOtpResent] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [verifyToast, setVerifyToast] = useState({
        type: '',
        message: '',
    })
    const [validateOtpLoading, setValidateOtpLoading] = useState(false)
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)

    const installationAddressConfirmed = values?.confirmAddress === 'yes'
    const installationAddressChanged = values?.confirmAddress === 'no'
    const countries = ['Nigeria']
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const closeModal = () => {
        setVerificationModal({ open: false, type: '' })
        setOtpResent(false)
    }
    const { errorAlert } = useCustomToast()

    const { sseInitiateAuthOtp } = useSSEAuthInitiateOtp({
        onSuccess: (responseData, type) => {
            if (verificationModal?.open === false) {
                setVerificationModal({
                    open: true,
                    type: type,
                })
            }
            setVerifyToast({
                type: 'success-secondary',
                message: `Verification code ${
                    !otpResent ? 'sent' : 'resent'
                } to your ${
                    type === 'email' ? 'email address' : 'phone number'
                }`,
            })
        },
        onError: err => {
            setVerifyToast({ type: 'error-secondary', message: err })
            if (verificationModal?.open === false) {
                errorAlert(errorHandler(err))
            }
        },
    })

    const { sseValidateAuthOtp } = useSSEAuthValidateOtp({
        onSuccess: () => {
            setValidateOtpLoading(false)
            closeModal()
            setToastMessage(
                `Your ${
                    verificationModal?.type === 'email'
                        ? 'email'
                        : 'Phone number'
                } has been verified successfully`,
            )
            verificationModal?.type === 'email'
                ? setValues(prev => ({ ...prev, emailVerified: true }))
                : setValues(prev => ({ ...prev, phoneVerified: true }))
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers[
                    verificationModal?.type === 'email'
                        ? 'sseEmailVerificationSuccessful'
                        : 'ssePhoneVerificationSuccessful'
                ].action,
                eventTrackers[
                    verificationModal?.type === 'email'
                        ? 'sseEmailVerificationSuccessful'
                        : 'ssePhoneVerificationSuccessful'
                ].label,
                eventTrackers[
                    verificationModal?.type === 'email'
                        ? 'sseEmailVerificationSuccessful'
                        : 'ssePhoneVerificationSuccessful'
                ].category,
            )
        },
        onError: err => {
            setValidateOtpLoading(false)
            setVerifyToast({ type: 'error-secondary', message: err })
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers[
                    verificationModal?.type === 'email'
                        ? 'sseEmailVerificationUnsuccessful'
                        : 'ssePhoneVerificationUnsuccessful'
                ].action,
                eventTrackers[
                    verificationModal?.type === 'email'
                        ? 'sseEmailVerificationUnsuccessful'
                        : 'ssePhoneVerificationUnsuccessful'
                ].label,
                eventTrackers[
                    verificationModal?.type === 'email'
                        ? 'sseEmailVerificationUnsuccessful'
                        : 'ssePhoneVerificationUnsuccessful'
                ].category,
            )
        },
    })

    // submit otp
    const validateOtpRequest = type => {
        const phoneNumber = {
            phone_number: formatPhoneNumber(values.phone, '+234'),
            code: otp.join(''),
        }
        const email = {
            email: values?.email,
            code: otp.join(''),
        }
        sseValidateAuthOtp(type === 'email' ? email : phoneNumber)
    }

    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                setValidateOtpLoading(true)
                validateOtpRequest(verificationModal?.type)
            })
            .catch(() => {
                setOtpError(true)
            })
    }
    const initiateOtpRequest = type => {
        const payload = {
            data:
                type === 'email'
                    ? { email: values?.email }
                    : { phone_number: formatPhoneNumber(values.phone, '+234') },
            type: type,
        }
        sseInitiateAuthOtp(payload)
    }

    useScrollToError(updateErrors)

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_PERSONAL_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    useOTPVerify({ otp, handleOtpSubmit })

    return (
        <>
            {/* Modal start */}
            <SecondaryModal
                showModal={verificationModal?.open}
                onCancel={() => closeModal()}
                modalWidth={'475px'}
                title={`Verify ${
                    verificationModal?.type === 'email'
                        ? 'email address'
                        : 'phone number'
                }`}
                v2
                content={
                    <div>
                        {verifyToast?.message?.length > 0 && (
                            <>
                                <VerticalSpacer top="24px" />
                                <Toast
                                    messageType={verifyToast?.type}
                                    message={verifyToast?.message}
                                    setToast={setVerifyToast}
                                    closable={true}
                                />
                            </>
                        )}

                        <Verify
                            contact={verificationModal?.type}
                            otp={otp}
                            setOtp={setOtp}
                            otpError={otpError}
                            inputs={values}
                            handleResendOtp={() => {
                                initiateOtpRequest(verificationModal?.type)
                                setOtpResent(true)
                            }}
                            disabled={setValidateOtpLoading}
                        />
                    </div>
                }
                showFooter
                footerLeftContent="none"
                primaryBtnText={`Verify ${
                    verificationModal?.type === 'email'
                        ? 'email address'
                        : 'phone number'
                }`}
                handlePrimaryBtnClick={() => {
                    handleOtpSubmit()
                }}
                primaryBtnDisabled={validateOtpLoading || otp?.length < 6}
                primaryBtnLoading={validateOtpLoading}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            />

            {/* Modal end */}
            <div>
                <Toast
                    message={toastMessage}
                    messageType="success-secondary"
                    setToast={setToastMessage}
                    closable={true}
                    position="absolute"
                    top="-125px"
                    zIndex="5"
                    minHeight="55px"
                />
            </div>

            {sseBasicInfoFetching ? (
                <FormSkeleton />
            ) : (
                <>
                    {!isAdminUser && (
                        <div
                            className="BusinessFormPageVideoMobile"
                            onClick={() => setShowVideoModal(true)}
                        >
                            <PlayIcon />
                            <p>Watch how to fill your basic info</p>
                        </div>
                    )}
                    <Label
                        title={
                            isAdminUser
                                ? "Customer's Name"
                                : "What's your name?"
                        }
                        subTitle={
                            isAdminUser
                                ? ''
                                : 'Please provide your first and last name as it appears on your ID card.'
                        }
                    />
                    <InputFields
                        name="firstName"
                        title="First Name"
                        handleChange={e => {
                            const value = e.target.value.replace(/^\d+$/, '')
                            setFieldValue('firstName', value.trim())
                            removeFromUpdateErrs('firstName')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={values.firstName}
                        errorMessage={
                            updateErrors.firstName ||
                            (touched.firstName && errors.firstName)
                        }
                        disabled={
                            isBvnVerified || isIdVerified || isCustomerApproved
                        }
                    />
                    <InputFields
                        name="lastName"
                        title="Surname/Last Name"
                        handleChange={e => {
                            const value = e.target.value.replace(/^\d+$/, '')
                            setFieldValue('lastName', value.trim())
                            removeFromUpdateErrs('lastName')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={values?.lastName}
                        errorMessage={
                            updateErrors.lastName ||
                            (touched?.lastName && errors.lastName)
                        }
                        disabled={
                            isBvnVerified || isIdVerified || isCustomerApproved
                        }
                    />
                    <VerticalSpacer bottom="8px" />

                    <Label
                        title={
                            isAdminUser
                                ? "Customer's Phone Number and Email"
                                : 'Your phone number and email'
                        }
                        subTitle={
                            isAdminUser
                                ? ''
                                : 'Provide us with your phone number and confirm your email address.'
                        }
                        showHelpIcon
                        tooltipText={
                            'Type in your phone number for verification, and confirm your email address.'
                        }
                    />
                    <div className="VerifyInputWrapper">
                        <SelectCountryCode
                            name="phone"
                            title="Phone Number"
                            handleChange={e => {
                                const val = e.target.value
                                if (
                                    val === '' ||
                                    (!isNaN(val) && /^\d+$/.test(val))
                                ) {
                                    setFieldValue('phone', val)
                                }
                                removeFromUpdateErrs('phone')
                            }}
                            inputType={'tel'}
                            onBlur={e => {
                                handleTracking(e)
                                if (values?.phone.length < 10) {
                                    handleBlur(e)
                                }
                            }}
                            onMouseOut={e => {
                                if (values?.phone.length > 9) {
                                    handleBlur(e)
                                }
                            }}
                            inputValue={values?.phone}
                            disabled={values?.phoneVerified || isAdminUser}
                            verified={
                                values?.phoneVerified || isCustomerApproved
                            }
                            selectWidth="114px"
                            inputStyle={{ width: '100%' }}
                            errorMessage={
                                updateErrors.phone ||
                                (touched.phone &&
                                    (errors?.phone || errors?.phoneVerified))
                            }
                            marginBottom={
                                values?.phoneVerified || isAdminUser ? 16 : 8
                            }
                        />
                        {values?.phoneVerified !== true && !isAdminUser && (
                            <div className="VerifyCTA">
                                <button
                                    onClick={() => {
                                        initiateOtpRequest('phone')
                                    }}
                                    hidden={values?.phoneVerified}
                                    disabled={
                                        values?.phoneVerified ||
                                        isCustomerApproved ||
                                        handlePhoneNumberValidation(
                                            '',
                                            formatPhoneNumber(
                                                values?.phone,
                                                '+234',
                                            ),
                                        ) === false ||
                                        phoneNumberRegex.test(values.phone) ===
                                            false ||
                                        isAdminUser
                                    }
                                >
                                    {isMobile
                                        ? 'Verify'
                                        : 'Verify Phone Number'}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="VerifyInputWrapper">
                        <InputFields
                            name="email"
                            title="Your email address"
                            handleChange={e => {
                                handleChange(e)
                                removeFromUpdateErrs('email')
                            }}
                            onBlur={e => {
                                handleBlur(e)
                                handleTracking(e)
                            }}
                            value={values?.email}
                            verified={values?.emailVerified}
                            disabled={
                                values?.emailVerified ||
                                isAdminUser ||
                                isCustomerApproved
                            }
                            errorMessage={
                                updateErrors.email ||
                                (touched?.email &&
                                    (errors.email || errors?.emailVerified))
                            }
                            marginBottom={
                                values?.emailVerified || isAdminUser
                                    ? '16px'
                                    : '8px'
                            }
                        />
                        {!isAdminUser && (
                            <div className="VerifyCTA">
                                <button
                                    onClick={() => {
                                        initiateOtpRequest('email')
                                    }}
                                    hidden={values?.emailVerified}
                                    disabled={
                                        values?.emailVerified ||
                                        isCustomerApproved ||
                                        emailRegex.test(values?.email) !==
                                            true ||
                                        isAdminUser
                                    }
                                    style={{ width: '84px' }}
                                >
                                    {isMobile ? 'Verify' : 'Verify email'}
                                </button>
                            </div>
                        )}
                    </div>
                    <VerticalSpacer bottom="8px" />

                    <Label
                        title={
                            isAdminUser
                                ? "Customer's Contact Address"
                                : 'Your contact address'
                        }
                        subTitle={
                            isAdminUser
                                ? ''
                                : 'Please provide your contact address. This does not have to be the same as your installation address.'
                        }
                    />
                    <InputFields
                        name="contactAddress"
                        title="House no. and street address"
                        handleChange={e => {
                            handleChange(e)
                            removeFromUpdateErrs('contactAddress')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={values?.contactAddress}
                        errorMessage={
                            updateErrors.contactAddress ||
                            (touched?.contactAddress && errors.contactAddress)
                        }
                        disabled={isCustomerApproved}
                    />
                    <div className="PersonalInfo_DualSelectField-Wrapper">
                        <SelectField
                            floatLabelOnSelectedValue
                            name="contactCountry"
                            floatingLabel="Country"
                            initialOption="Country"
                            selectWidth="100%"
                            handleChange={(_, value) => {
                                setFieldValue('contactCountry', value)
                                removeFromUpdateErrs('contactCountry')
                                appTracking(
                                    pageUrlName,
                                    pageTracker,
                                    pageTitle,
                                    `FILLED_${_?.toUpperCase()}`,
                                    'SMART_SHOPPING_EXPERIENCE_KYC',
                                    'KYC_PERSONAL_INFO',
                                    ['GA', 'MP'],
                                    'event',
                                    { value },
                                )
                            }}
                            onBlur={() => {
                                setFieldTouched('contactCountry', true)
                                if (!values.contactCountry)
                                    setFieldError(
                                        'contactCountry',
                                        errors.contactCountry,
                                    )
                            }}
                            values={countries.map(option => ({
                                value: option,
                            }))}
                            value={values?.contactCountry}
                            errorMessage={
                                updateErrors.contactCountry ||
                                (touched.contactCountry &&
                                    errors?.contactCountry)
                            }
                            showSearch
                            dropdownPositionRelative
                            disabled={isCustomerApproved}
                            withCheckBox
                            currentSelected={values?.contactCountry}
                            selectedValue={values?.contactCountry}
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="contactState"
                            floatingLabel="State"
                            initialOption="State"
                            selectWidth="100%"
                            values={stateData?.map(option => ({
                                value: option,
                            }))}
                            value={values.contactState}
                            handleChange={(_, value) => {
                                setFieldValue('contactState', value)
                                setFieldValue('contactCity', '')
                                removeFromUpdateErrs('contactState')
                                appTracking(
                                    pageUrlName,
                                    pageTracker,
                                    pageTitle,
                                    `FILLED_${_?.toUpperCase()}`,
                                    'SMART_SHOPPING_EXPERIENCE_KYC',
                                    'KYC_PERSONAL_INFO',
                                    ['GA', 'MP'],
                                    'event',
                                    { value },
                                )
                            }}
                            onBlur={() => {
                                setFieldTouched('contactState', true)
                                if (!values.contactState)
                                    setFieldError(
                                        'contactState',
                                        errors.contactState,
                                    )
                            }}
                            errorMessage={
                                updateErrors.contactState ||
                                (touched?.contactState && errors.contactState)
                            }
                            showSearch
                            dropdownPositionRelative
                            disabled={isCustomerApproved}
                            withCheckBox
                            currentSelected={values?.contactState}
                            selectedValue={values?.contactState}
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="contactCity"
                            floatingLabel="L.G.A."
                            initialOption="L.G.A."
                            selectWidth="100%"
                            values={localGovtData[
                                values?.contactState || 'Lagos'
                            ]?.map(option => ({
                                value: option,
                            }))}
                            disabled={
                                values?.contactState?.length < 1 ||
                                isCustomerApproved
                            }
                            value={values.contactCity}
                            handleChange={(_, value) => {
                                setFieldValue('contactCity', value)
                                removeFromUpdateErrs('contactCity')
                                appTracking(
                                    pageUrlName,
                                    pageTracker,
                                    pageTitle,
                                    `FILLED_${_?.toUpperCase()}`,
                                    'SMART_SHOPPING_EXPERIENCE_KYC',
                                    'KYC_PERSONAL_INFO',
                                    ['GA', 'MP'],
                                    'event',
                                    { value },
                                )
                            }}
                            onBlur={() => {
                                setFieldTouched('contactCity', true)
                                if (!values.contactCity)
                                    setFieldError(
                                        'contactCity',
                                        errors.contactCity,
                                    )
                            }}
                            errorMessage={
                                updateErrors.contactCity ||
                                (touched.contactCity && errors.contactCity)
                            }
                            showSearch
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={values?.contactCity}
                            selectedValue={values?.contactCity}
                        />
                    </div>
                    <div className="ContactAddress_Confirmation-Wrapper">
                        <p>
                            {isAdminUser
                                ? "Is the customer's address the same as the preferred address for installation?"
                                : 'Is your contact address the same as where you want to install the system?'}
                        </p>
                        <div>
                            <button
                                onClick={() => {
                                    setValues(prev => ({
                                        ...prev,
                                        confirmAddress: 'yes',
                                        installationAddress:
                                            values?.contactAddress,
                                        installationCity: values?.contactCity,
                                        installationState: values?.contactState,
                                        installationCountry:
                                            values?.contactCountry,
                                    }))
                                }}
                                style={{
                                    color: installationAddressConfirmed
                                        ? '#ffffff'
                                        : '',
                                    background: installationAddressConfirmed
                                        ? '#011A3C'
                                        : '',
                                }}
                                className="ContactAddress_Confirmation-CTA"
                                disabled={isCustomerApproved}
                            >
                                Yes, It is
                            </button>
                            <button
                                onClick={() => {
                                    setValues(prev => ({
                                        ...prev,
                                        confirmAddress: 'no',
                                        installationAddress: '',
                                        installationCity: '',
                                        installationState: '',
                                        installationCountry: '',
                                    }))
                                }}
                                style={{
                                    color: installationAddressChanged
                                        ? '#ffffff'
                                        : '',
                                    background: installationAddressChanged
                                        ? '#011A3C'
                                        : '',
                                }}
                                className="ContactAddress_Confirmation-CTA"
                                disabled={isCustomerApproved}
                            >
                                No, Its not
                            </button>
                        </div>
                        {touched.confirmAddress && errors?.confirmAddress && (
                            <span className="fieldError">
                                {errors?.confirmAddress}
                            </span>
                        )}
                    </div>
                    {installationAddressChanged && (
                        <>
                            <VerticalSpacer top="24px" />
                            <div>
                                <Label
                                    title="Your installation address"
                                    subTitle="Please provide us with where you want to have the inverter installed."
                                />
                                <InputFields
                                    name="installationAddress"
                                    title="House no. and street address"
                                    handleChange={e => {
                                        handleChange(e)
                                        removeFromUpdateErrs(
                                            'installationAddress',
                                        )
                                    }}
                                    onBlur={e => {
                                        handleBlur(e)
                                        handleTracking(e)
                                    }}
                                    value={values.installationAddress}
                                    errorMessage={
                                        updateErrors.installationAddress ||
                                        (touched.installationAddress &&
                                            errors.installationAddress)
                                    }
                                    disabled={isCustomerApproved}
                                />
                                <div className="PersonalInfo_DualSelectField-Wrapper">
                                    <SelectField
                                        floatLabelOnSelectedValue
                                        name="installationCountry"
                                        floatingLabel="Country"
                                        initialOption="Country"
                                        selectWidth="100%"
                                        values={countries.map(option => ({
                                            value: option,
                                        }))}
                                        value={values?.installationCountry}
                                        handleChange={(_, value) => {
                                            setFieldValue(
                                                'installationCountry',
                                                value,
                                            )
                                            removeFromUpdateErrs(
                                                'installationCountry',
                                            )
                                            appTracking(
                                                pageUrlName,
                                                pageTracker,
                                                pageTitle,
                                                `FILLED_${_?.toUpperCase()}`,
                                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                                'KYC_PERSONAL_INFO',
                                                ['GA', 'MP'],
                                                'event',
                                                { value },
                                            )
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(
                                                'installationCountry',
                                                true,
                                            )
                                            if (!values.installationCountry)
                                                setFieldError(
                                                    'installationCountry',
                                                    errors.installationCountry,
                                                )
                                        }}
                                        errorMessage={
                                            updateErrors.installationCountry ||
                                            (touched.installationCountry &&
                                                errors.installationCountry)
                                        }
                                        showSearch
                                        dropdownPositionRelative
                                        disabled={isCustomerApproved}
                                        withCheckBox
                                        selectedValue={
                                            values?.installationCountry
                                        }
                                        currentSelected={
                                            values?.installationCountry
                                        }
                                    />
                                    <SelectField
                                        floatLabelOnSelectedValue
                                        name="installationState"
                                        floatingLabel="State"
                                        initialOption="State"
                                        selectWidth="100%"
                                        handleChange={(_, value) => {
                                            setFieldValue(
                                                'installationState',
                                                value,
                                            )
                                            setFieldValue(
                                                'installationCity',
                                                '',
                                            )
                                            removeFromUpdateErrs(
                                                'installationState',
                                            )
                                            appTracking(
                                                pageUrlName,
                                                pageTracker,
                                                pageTitle,
                                                `FILLED_${_?.toUpperCase()}`,
                                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                                'KYC_PERSONAL_INFO',
                                                ['GA', 'MP'],
                                                'event',
                                                { value },
                                            )
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(
                                                'installationState',
                                                true,
                                            )
                                            if (!values.installationState)
                                                setFieldError(
                                                    'installationState',
                                                    errors.installationState,
                                                )
                                        }}
                                        values={stateData?.map(option => ({
                                            value: option,
                                        }))}
                                        value={values?.installationState}
                                        errorMessage={
                                            updateErrors.installationState ||
                                            (touched.installationState &&
                                                errors?.installationState)
                                        }
                                        showSearch
                                        dropdownPositionRelative
                                        disabled={isCustomerApproved}
                                        withCheckBox
                                        selectedValue={
                                            values?.installationState
                                        }
                                        currentSelected={
                                            values?.installationState
                                        }
                                    />
                                    <SelectField
                                        floatLabelOnSelectedValue
                                        name="installationCity"
                                        floatingLabel="L.G.A."
                                        initialOption="L.G.A."
                                        selectWidth="100%"
                                        handleChange={(_, value) => {
                                            setFieldValue(
                                                'installationCity',
                                                value,
                                            )
                                            removeFromUpdateErrs(
                                                'installationCity',
                                            )
                                            appTracking(
                                                pageUrlName,
                                                pageTracker,
                                                pageTitle,
                                                `FILLED_${_?.toUpperCase()}`,
                                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                                'KYC_PERSONAL_INFO',
                                                ['GA', 'MP'],
                                                'event',
                                                { value },
                                            )
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(
                                                'installationCity',
                                                true,
                                            )
                                            if (!values.installationCity)
                                                setFieldError(
                                                    'installationCity',
                                                    errors.installationCity,
                                                )
                                        }}
                                        values={localGovtData[
                                            values?.installationState
                                        ]?.map(option => ({
                                            value: option,
                                        }))}
                                        disabled={
                                            values?.installationState?.length <
                                                1 || isCustomerApproved
                                        }
                                        value={values?.installationCity}
                                        errorMessage={
                                            updateErrors.installationCity ||
                                            (touched.installationCity &&
                                                errors.installationCity)
                                        }
                                        showSearch
                                        dropdownPositionRelative
                                        withCheckBox
                                        selectedValue={values?.installationCity}
                                        currentSelected={
                                            values?.installationCity
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="formStep">
                        <p>
                            {isAdminUser
                                ? "Finished reviewing customer's personal info?"
                                : 'Completed your personal info?'}
                        </p>
                        <button
                            disabled={persistResidentialInfoLoading}
                            onClick={() => {
                                persistInfo('Submit')
                            }}
                        >
                            Go to employment info{' '}
                            <span>
                                <img
                                    src={CaretCircle}
                                    alt="right icon"
                                    width={14}
                                    height={14}
                                />
                            </span>
                        </button>
                    </div>
                </>
            )}
        </>
    )
}

PersonalInfo.propTypes = {
    updateErrors: PropTypes.object,
    removeFromUpdateErrs: PropTypes.func,
    setShowVideoModal: PropTypes.func,
}

export default PersonalInfo
