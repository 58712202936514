import { useState } from 'react'
import { formatOtpVerifyMethods } from 'src/components/GettingStarted/utils'
import useVerifyLoginOTP from 'src/components/LoginComponent/hooks/useVerifyLoginOTP'
import useInitiateLoginMutation from '../hooks/useInitiateLoginMutation'
import { errorHandler } from 'src/utils/errorHandler'

const useLoginModal = (
    onCancel,
    loginModalContent,
    setLoginModalContent,
    inputs,
    setShowModal,
    otpMethod,
    setOtpMethod,
) => {
    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const userType = 'consumer'

    const {
        otpError,
        toastError: verifyLoginToastErr,
        errorMessage: verifyLoginErrMsg,
        resent,
        resendOTPMutation,
        fullMethodName,
        closeModal,
        handleOtpSubmit,
        verifyLoginMutation,
        otp,
        setOtp,
        setResent,
    } = useVerifyLoginOTP(setShowModal, otpMethod, inputs, userType)

    const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_LOGIN'

    const { mutate: initiateLogin, isLoading: initiateLoginLoading } =
        useInitiateLoginMutation(
            inputs,
            () => {
                setLoginModalContent('enter-otp')
                setErrors({})
            },
            err => {
                setToastError(true)
                setErrorMessage(errorHandler(err))
            },
            userType,
            pageUrlName,
            pageTracker,
            pageTitle,
        )

    const otpMethods = formatOtpVerifyMethods(inputs)

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleSelectOtpMethod = key => {
        setOtpMethod(key)
        removeErrors('otpMethod')
    }

    const handleClick = () => {
        if (loginModalContent === 'select-otp-method') {
            if (otpMethod === '') {
                setErrors(prev => ({
                    ...prev,
                    otpMethod: 'Please select one option',
                }))
            } else {
                removeErrors('otpMethod')
                initiateLogin(otpMethod)
            }
            return
        }
        if (loginModalContent === 'enter-otp') {
            handleOtpSubmit()
            return
        }
    }

    const handleClose = () => {
        setOtpMethod('')
        setErrors({})
        closeModal()
        onCancel?.()
    }

    return {
        handleClose,
        fullMethodName,
        handleClick,
        verifyLoginMutation,
        initiateLoginLoading,
        toastError,
        verifyLoginToastErr,
        errorMessage,
        verifyLoginErrMsg,
        otpMethods,
        otpMethod,
        handleSelectOtpMethod,
        errors,
        otp,
        setOtp,
        otpError,
        resendOTPMutation,
        resent,
        setResent,
    }
}

export default useLoginModal
