import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { DateField } from 'src/components/InputFields'
import ButtonComponent from 'src/components/Button'
import { format } from 'date-fns'

const FilterDrawer = ({ visible, onClose, filterState, setFilterState }) => {
    const [showCalendar, setShowCalendar] = useState(null)
    const [localInputs, setLocalInputs] = useState({
        start_date: null,
        end_date: null,
    })
    const reset = {
        start_date: null,
        end_date: null,
    }
    const [currentStartDate, setCurrentStartDate] = useState(null)
    const [currentEndDate, setCurrentEndDate] = useState(null)

    const resetFilter = () => {
        setLocalInputs(reset)
        setFilterState(prevState => ({
            ...prevState,
            filterInputs: reset,
            currentPage: 1,
            status: [],
        }))
        onClose()
    }

    const applyFilter = () => {
        setFilterState(prevState => ({
            ...prevState,
            filterInputs: localInputs,
            currentPage: 1,
        }))
        onClose()
    }

    const handleDateChange = (name, date) => {
        if (name === 'start_date') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'end_date') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setLocalInputs(prev => ({
            ...prev,
            [name]: name === 'start_date' ? currentStartDate : currentEndDate,
        }))
    }

    useEffect(() => {
        setLocalInputs(filterState?.filterInputs)

        return () => {
            setLocalInputs()
        }
    }, [filterState?.filterInputs])
    return (
        <Drawer
            placement="right"
            visible={visible}
            onClose={() => onClose()}
            closable={false}
            className="PaymentPlansListings_Drawer"
            width={367}
        >
            <div className="PaymentPlansListings_Drawer_Content">
                <h2>Filter Payment Plans</h2>
                <h3>Filter by Date Created</h3>
                <div className="PaymentPlansListings_Drawer_DateFields">
                    <DateField
                        placeholder="Start Date"
                        name="start_date"
                        caretStyle="caretStyle"
                        dateValue={
                            localInputs?.start_date === null
                                ? null
                                : new Date(localInputs?.start_date)
                        }
                        handleDateChange={(name, date) =>
                            handleDateChange(name, date)
                        }
                        openCalendar={showCalendar === 1}
                        openCalendarHandler={() => setShowCalendar(1)}
                        closeCalendarHandler={() => setShowCalendar(null)}
                        setHandleDateChange={name => setHandleDateChange(name)}
                    />
                    <DateField
                        placeholder="End Date"
                        name="end_date"
                        caretStyle="caretStyle"
                        dateValue={
                            localInputs?.end_date === null
                                ? null
                                : new Date(localInputs?.end_date)
                        }
                        handleDateChange={(name, date) =>
                            handleDateChange(name, date)
                        }
                        openCalendar={showCalendar === 2}
                        openCalendarHandler={() => setShowCalendar(2)}
                        closeCalendarHandler={() => setShowCalendar(null)}
                        setHandleDateChange={name => setHandleDateChange(name)}
                        placePopperRight
                    />
                </div>
            </div>
            <div className="PaymentPlansListings_Drawer_Buttons">
                <ButtonComponent
                    btnBgColor={'#E2EEFF'}
                    btnTextColor={'#004AAD'}
                    width={'160px'}
                    handleClick={() => resetFilter()}
                >
                    Reset Filter
                </ButtonComponent>
                <ButtonComponent
                    btnBgColor={'#004AAD'}
                    btnTextColor={'#FFFFFF'}
                    width={'160px'}
                    handleClick={() => applyFilter()}
                >
                    Apply Filter
                </ButtonComponent>
            </div>
        </Drawer>
    )
}

FilterDrawer.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    filterState: PropTypes.shape({
        filterInputs: PropTypes.shape({
            start_date: PropTypes.string,
            end_date: PropTypes.string,
        }).isRequired,
        currentPage: PropTypes.number.isRequired,
    }).isRequired,
    setFilterState: PropTypes.func.isRequired,
    currentTab: PropTypes.string,
}

export default FilterDrawer
