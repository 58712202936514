import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import Toast from '../../Toast'
import { InlineLoader } from '../../Loader'
import { ProductHeader } from '../ProductHeader'
import {
    InputFields,
    MultipleSelectField,
    SelectField,
} from '../../InputFields'
import Button from '../../Button'
import Editor from '../../Editor'
import SeoComponent from '../../Seo'
import styles from './global_package.module.scss'
import {
    validatePackageNameApi,
    getMaxWarranty,
} from '../../../api/products/packages'
import useErrorHandler from '../../../utils/Hooks/useErrorHandler'
import RadioSelected from 'src/assets/images/radio-selected-icon.svg'
import RadioUnselected from 'src/assets/images/radio-unselected-icon.svg'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import { decodeUserInfo } from 'src/utils/auth'
import { remoteMonitoringData } from './data'
import { isNumeric } from 'src/utils/formatting'
import usePaymentPlanTypes from '../hooks/usePaymentPlanTypes'
import { errorHandler } from 'src/utils/errorHandler'
import CustomToolTip from 'src/components/CustomToolTip'

const GlobalPackage = ({
    handleStage,
    handlePackageName,
    handlePackageDescription,
    handlePackageIsGlobal,
    handleUsePowerCurveReco,
    handlePackageWarranty,
    packageName,
    packageDescription,
    isGlobal,
    usePowerCurveReco,
    packageWarranty,
    handlePackagePaymentPlanTypes,
    packagePaymentPlanTypes,
    remoteMonitoringCapabilities,
    setRemoteMonitoringCapabilities,
}) => {
    const {
        Wrapper,
        BtnWrapper,
        Row,
        LeftComponent,
        GlobalPackageTitle,
        PowerCurveButton,
    } = styles
    const [errMessageName, setErrMessageName] = useState('')
    const [errMessageDescription, setErrMessageDescription] = useState('')
    const [errMessageWarranty, setErrMessageWarranty] = useState('')
    // eslint-disable-next-line no-unused-vars
    const [input, setInputs] = useState('')
    const [, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errs, setErrs] = useState(null)
    const [, setEditorIsFocus] = useState(false)
    const [errorPaymentPlanTypes, setErrorPaymentPlanTypes] = useState('')
    const [errors, setErrors] = useState({ remoteMonitoringCapabilities: '' })
    const [maxWarranty, setMaxWarranty] = useState('')
    const minWarranty = 3

    const userInfo = decodeUserInfo()
    const paymentPlanTypesData = usePaymentPlanTypes()

    const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'
    const isGlobalContributor = userInfo?.globalContributor === 'true'
    const isSubscriptionProvider = userInfo?.isSubscriptionProvider === 'true'
    const showPackageImageUpload =
        process.env.REACT_APP_FLAG_SHOW_PACKAGE_IMAGE_UPLOAD

    // radio button border styles
    const yes =
        isGlobal === 'none' ? 'red' : isGlobal === true ? '#004aad' : '#e5e5e5'

    const no =
        isGlobal === 'none' ? 'red' : isGlobal === false ? '#004aad' : '#e5e5e5'

    const PowerCurveBorder =
        usePowerCurveReco === 'none'
            ? 'red'
            : usePowerCurveReco === true
            ? '#004aad'
            : '#e5e5e5'

    const PowerCurveNoBorder =
        usePowerCurveReco === 'none'
            ? 'red'
            : usePowerCurveReco === false
            ? '#004aad'
            : '#e5e5e5'

    const editorRef = useRef()
    const editor = editorRef?.current?.getEditor()
    const unprivilegedEditor =
        editorRef?.current?.makeUnprivilegedEditor(editor)

    const handleNameChange = e => {
        if (e.target.value === '') {
            setErrMessageName('This field is required')
        } else if (e.target.value?.length < 2) {
            setErrMessageName('This field must be at least 2 characters')
        } else setErrMessageName('')

        handlePackageName(e.target.value)
        setInputs(e.target.value)
    }
    const handleWarrantyChange = e => {
        const value = e.target.value.replaceAll(' ', '')
        let val = ''
        if (value === '') {
            setErrMessageWarranty('This field is required')
        } else if (!isNumeric(value)) {
            value.replaceAll(/[a-zA-Z]/g, '')
            return
        } else if (
            parseFloat(value) < minWarranty ||
            (maxWarranty !== '' && parseFloat(value) > maxWarranty)
        ) {
            setErrMessageWarranty(
                maxWarranty === ''
                    ? 'Warranty should not be less than 12 months'
                    : `Your warranty period must be between 12 and ${maxWarranty} months`,
            )
        } else if (
            isNumeric(value) &&
            value.length > 1 &&
            value.startsWith('0')
        ) {
            val = parseInt(value.replaceAll(/[a-zA-Z]/g, ''))
            handlePackageWarranty(val)
            setInputs(val)
            return
        } else setErrMessageWarranty('')

        handlePackageWarranty(value)
        setInputs(value)
    }
    const handlePackageWarrantyBlur = e => {
        const { value } = e.target
        handlePackageWarranty(value.trim())
    }

    const handleBlur = e => {
        const { value } = e.target
        handlePackageName(value.trim())
        setInputs(value.trim())
    }

    const handleChangeDescription = value => {
        handlePackageDescription(value)
        setErrMessageDescription('')
    }

    const { refetch: warrantyRefetch } = useQuery(
        ['getmaxwarranty'],
        () => getMaxWarranty(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setMaxWarranty(data?.data?.data?.max_warranty)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const { isFetching, error, refetch } = useQuery(
        ['validatePackageName'],
        () => validatePackageNameApi(packageName),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                handleStage(showPackageImageUpload ? 'image' : 'components')
            },
            onError: error => {
                setToastError(true)
                if (error?.response?.data?.errors) {
                    setErrs(error?.response?.data)
                } else {
                    setErrorMessage(['Failed to validate package name.'])
                }
                setToastError(true)
            },
        },
    )

    //get admin protective device api

    const handleClick = () => {
        setEditorIsFocus(true)
        const editorValue = unprivilegedEditor?.getText()?.trim()
        if (packageName === '') {
            setErrMessageName('This field is required')
        } else if (editorValue?.trim()?.length < 1) {
            setErrMessageDescription('This is required')
        } else if (packageDescription?.length < 1) {
            setErrMessageDescription('This field is required')
        } else if (packageName?.length < 2) {
            setErrMessageName('This field must be at least 2 characters')
        } else if (packageWarranty === '') {
            setErrMessageWarranty('This field is required')
        } else if (
            parseFloat(packageWarranty) < minWarranty ||
            (maxWarranty !== '' && parseFloat(packageWarranty) > maxWarranty)
        ) {
            setErrMessageWarranty(
                `${
                    maxWarranty === ''
                        ? 'Warranty should not be less than 12 months'
                        : `Your warranty period must be between 12 and ${maxWarranty} months`
                }`,
            )
        } else if (
            isGlobalContributor &&
            (isGlobal === null || isGlobal === 'none')
        ) {
            handlePackageIsGlobal('none')
        } else if (
            (isAdminWorkspace &&
                process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE === 'true' &&
                usePowerCurveReco === null) ||
            (process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE === 'true' &&
                usePowerCurveReco === 'none')
        ) {
            handleUsePowerCurveReco('none')
        } else if (
            (isAdminWorkspace || isSubscriptionProvider) &&
            !packagePaymentPlanTypes.length > 0
        ) {
            setErrorPaymentPlanTypes(
                'Supported payment plan types should not be empty',
            )
        } else if (remoteMonitoringCapabilities === '') {
            setErrors({
                remoteMonitoringCapabilities: 'This field is required',
            })
        } else {
            refetch()
        }
    }

    useEffect(() => {
        if (packageName) {
            setInputs(packageName)
        }
        !isAdminWorkspace && warrantyRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setRemoteMonitoringCapabilities(value)
    }

    const truncateRemoteMonitoringCapabilities =
        remoteMonitoringCapabilities && remoteMonitoringCapabilities.length > 40
            ? remoteMonitoringCapabilities.substring(0, 40) + '...'
            : remoteMonitoringCapabilities

    const errorValue = useErrorHandler(errs)
    return (
        <>
            <SeoComponent
                title="SunFi - Add A Package | Package Name - Package Description - Global Package"
                tracker="AddPackage"
            />
            <div className={Wrapper}>
                {error && (errorMessage[0] || errorValue) && (
                    <Toast
                        messageType="error"
                        message={
                            isFetching
                                ? ''
                                : errorMessage[0] || errorValue['name']?.[0]
                        }
                    />
                )}
                <div className={Row}>
                    <div className={LeftComponent}>
                        <ProductHeader
                            title="Create New Product Package"
                            subtitle="Enter Package Information"
                        />
                        <InputFields
                            title="Package Name"
                            inputWidth="380px"
                            name="packageName"
                            value={packageName}
                            handleChange={handleNameChange}
                            errorMessage={errMessageName}
                            onBlur={handleBlur}
                        />
                        <Editor
                            value={packageDescription || ''}
                            placeholder="Package Description"
                            onChangeHandler={handleChangeDescription}
                            editorWidth="380px"
                            editorHeight="140px"
                            errorMessage={errMessageDescription}
                            editorRef={editorRef}
                        />
                        <CustomToolTip
                            text={
                                'Please note that the warranty here has to be the same as the warranty of the inverter component to be selected.'
                            }
                            toolTipPopupWidth={174}
                            arrowPositionLeft={true}
                            toolTipMarginTop={-22}
                            toolTipMarginLeft={0}
                        >
                            <InputFields
                                title="Package Warranty (Months)"
                                inputWidth="380px"
                                name="packageWarranty"
                                value={packageWarranty}
                                handleChange={handleWarrantyChange}
                                errorMessage={errMessageWarranty}
                                onBlur={handlePackageWarrantyBlur}
                            />
                        </CustomToolTip>
                        {(isAdminWorkspace || isSubscriptionProvider) && (
                            <div style={{ maxWidth: '380px' }}>
                                <MultipleSelectField
                                    title="Supported Payment Plan Types"
                                    floatingLabel="Supported Payment Plan Types"
                                    selectWidth="100%"
                                    name="payment_plan_models"
                                    values={paymentPlanTypesData}
                                    value={packagePaymentPlanTypes?.map(
                                        item => item.value,
                                    )}
                                    inputValue={packagePaymentPlanTypes?.map(
                                        item => item.value,
                                    )}
                                    dropDownWidth="150px"
                                    dropdownMatchSelectWidth={false}
                                    handleMultipleSelectChange={value => {
                                        const values = value?.map(item => ({
                                            value: item,
                                            label: item,
                                        }))
                                        handlePackagePaymentPlanTypes(values)
                                    }}
                                    errorMessage={errorPaymentPlanTypes}
                                />
                            </div>
                        )}
                        <SelectField
                            selectWidth="100%"
                            initialOption={
                                remoteMonitoringCapabilities ||
                                'Select Remote Monitoring Capabilities'
                            }
                            floatingLabel={
                                'Select Remote Monitoring Capabilities'
                            }
                            floatLabelOnSelectedValue
                            name="remoteMonitoringCapabilities"
                            withCheckBox={true}
                            currentSelected={remoteMonitoringCapabilities}
                            handleChange={handleSelectChange}
                            errorMessage={errors?.remoteMonitoringCapabilities}
                            value={truncateRemoteMonitoringCapabilities}
                            values={remoteMonitoringData.map(option => ({
                                value: option,
                            }))}
                            marginBottom="20px"
                            dropdownPositionRelative
                            showSearch
                        />
                        {isGlobalContributor && (
                            <>
                                <p className={GlobalPackageTitle}>
                                    Is this a global package?
                                </p>
                                <div>
                                    <div className={`InputsContainer`}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <button
                                                className="RadioContainer"
                                                style={{
                                                    border: `0.7px solid ${yes}`,
                                                    fontWeight:
                                                        isGlobal === true
                                                            ? 600
                                                            : 400,
                                                    marginRight: '3.5px',
                                                }}
                                                onClick={e => {
                                                    e.preventDefault()
                                                    handlePackageIsGlobal(true)
                                                }}
                                            >
                                                <input
                                                    value={true}
                                                    onClick
                                                    name="is_global"
                                                    defaultChecked={
                                                        isGlobal === true
                                                    }
                                                    type="radio"
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                                <img
                                                    src={
                                                        isGlobal === true
                                                            ? RadioSelected
                                                            : RadioUnselected
                                                    }
                                                    alt="radio"
                                                />
                                                Yes, It is
                                            </button>
                                            <button
                                                className="RadioContainer"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    handlePackageIsGlobal(false)
                                                }}
                                                style={{
                                                    border: `0.7px solid ${no}`,
                                                    fontWeight:
                                                        isGlobal === false
                                                            ? 600
                                                            : 400,
                                                    marginLeft: '3.5px',
                                                }}
                                            >
                                                <input
                                                    value={false}
                                                    name="is_global"
                                                    defaultChecked={
                                                        isGlobal === false
                                                    }
                                                    type="radio"
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                                <img
                                                    src={
                                                        isGlobal === false
                                                            ? RadioSelected
                                                            : RadioUnselected
                                                    }
                                                    alt="radio"
                                                />
                                                No, It’s not
                                            </button>
                                        </div>
                                    </div>
                                    {isGlobal === 'none' ? (
                                        <p className="CreditCheckErrorText">
                                            Please select an option
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </>
                        )}
                        {process.env
                            .REACT_APP_FLAG_SHOW_POWER_CURVE_RECOMM_BUTTON ===
                            'true' && isAdminWorkspace ? (
                            <div className={PowerCurveButton}>
                                <p>Use for Power curve recommendation?</p>
                                <div>
                                    <div className={`InputsContainer`}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <button
                                                className="RadioContainer"
                                                style={{
                                                    border: `0.7px solid ${PowerCurveBorder}`,
                                                    fontWeight:
                                                        usePowerCurveReco ===
                                                        true
                                                            ? 600
                                                            : 400,
                                                    marginRight: '3.5px',
                                                }}
                                                onClick={e => {
                                                    e.preventDefault()
                                                    handleUsePowerCurveReco(
                                                        true,
                                                    )
                                                }}
                                            >
                                                <input
                                                    value={true}
                                                    onClick
                                                    name="is_global"
                                                    defaultChecked={
                                                        usePowerCurveReco ===
                                                        true
                                                    }
                                                    type="radio"
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                                <img
                                                    src={
                                                        usePowerCurveReco ===
                                                        true
                                                            ? RadioSelected
                                                            : RadioUnselected
                                                    }
                                                    alt="radio"
                                                />
                                                Yes, use it
                                            </button>
                                            <button
                                                className="RadioContainerWide"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    handleUsePowerCurveReco(
                                                        false,
                                                    )
                                                }}
                                                style={{
                                                    border: `0.7px solid ${PowerCurveNoBorder}`,
                                                    fontWeight:
                                                        usePowerCurveReco ===
                                                        false
                                                            ? 600
                                                            : 400,
                                                    marginLeft: '3.5px',
                                                }}
                                            >
                                                <input
                                                    value={false}
                                                    name="is_global"
                                                    defaultChecked={
                                                        usePowerCurveReco ===
                                                        false
                                                    }
                                                    type="radio"
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                                <img
                                                    src={
                                                        usePowerCurveReco ===
                                                        false
                                                            ? RadioSelected
                                                            : RadioUnselected
                                                    }
                                                    alt="radio"
                                                />
                                                No, keep it manual
                                            </button>
                                        </div>
                                    </div>
                                    {usePowerCurveReco === 'none' ? (
                                        <p className="CreditCheckErrorText">
                                            Please select an option
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        ) : null}

                        <div className={BtnWrapper}>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                handleClick={handleClick}
                                disabled={isFetching ? true : false}
                            >
                                {isFetching ? <InlineLoader /> : 'Continue'}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <DescriptionComponent
                            type="package"
                            text="Creating packages that contain predefined components helps you serve your customers with solutions faster."
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

GlobalPackage.propTypes = {
    handleStage: PropTypes.func,
    handlePackageName: PropTypes.func,
    handlePackageDescription: PropTypes.func,
    handlePackageIsGlobal: PropTypes.func,
    handleUsePowerCurveReco: PropTypes.func,
    handlePackageWarranty: PropTypes.func,
    packageName: PropTypes.string,
    packageDescription: PropTypes.string,
    isGlobal: PropTypes.bool,
    usePowerCurveReco: PropTypes.bool,
    packageWarranty: PropTypes.any,
    handlePackagePaymentPlanTypes: PropTypes.func,
    packagePaymentPlanTypes: PropTypes.any,
    handleProtectiveDevice: PropTypes.func,
    selectedProtectiveDevices: PropTypes.any,
    setSelectedProtectiveDevices: PropTypes.func,
    remoteMonitoringCapabilities: PropTypes.any,
    setRemoteMonitoringCapabilities: PropTypes.func,
}

export default GlobalPackage
