import { useEffect, useState } from 'react'
import SeoComponent from 'src/components/Seo'
import './alternaterecomlistings.scss'
import { ReactComponent as SearchIcon } from 'src/assets/images/search.svg'
import { ReactComponent as FilterIcon } from 'src/assets/images/filter.svg'
import { MultipleSelectField } from 'src/components/InputFields'
import {
    alternateStatusOptions,
    mapStatusValue,
    reverseStatusMap,
} from './utils'
import FilterDrawer from './components/FilterDrawer'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import EmptyState from 'src/components/EmptyState'
import PlansTable from './components/PlansTable'
import { useQuery } from 'react-query'
import { getAlternateRecommendations } from 'src/api/admin/payment-plans/listings'
import { format } from 'date-fns'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { Tabs, Pagination } from 'antd'
import styles from './alternateRecommListings.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateAdminDTCReviewFilters,
    updatedAdminDTCReviewFilters,
} from 'src/redux/reducers/admin/dtc-review/listing'
import { convertQueryParamsToObject } from 'src/utils/formatting'

const AlternateRecommendations = () => {
    const defaultQueries = useSelector(updatedAdminDTCReviewFilters)
    const dispatch = useDispatch()
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [currentTab, setCurrentTab] = useState(
        defaultQueries?.current_tab ?? '1',
    )
    const [searchTextInput, setSearchTextInput] = useState(
        defaultQueries?.name ?? '',
    )
    const [plans, setPlans] = useState([])
    const [totalPages, setTotalPages] = useState(null)
    const [toastError, setToastError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [filterState, setFilterState] = useState({
        surveyType: 'CFS,UFS',
        currentPage: Number(defaultQueries?.page) ?? 1,
        searchText: defaultQueries?.name ?? '',
        status:
            defaultQueries?.status?.length > 0
                ? [defaultQueries?.status].map(item => ({
                      value: item,
                      label: reverseStatusMap[item]?.label,
                  }))
                : [],
        filterInputs: {
            start_date: defaultQueries.start_date || null,
            end_date: defaultQueries.end_date || null,
            payment_model: '',
        },
    })

    const handleDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const handleFilterChange = newFilters => {
        setFilterState(prevState => ({
            ...prevState,
            currentPage: 1,
            ...newFilters,
        }))
    }

    const addSearchTextToFilter = () => {
        if (searchTextInput !== '') {
            handleFilterChange({ searchText: searchTextInput })
        }
    }

    const updatePage = page => {
        handleFilterChange({ currentPage: page })
    }

    const onTabChange = key => {
        setCurrentTab(key)
        const newSurveyType = key === '1' ? 'CFS,UFS' : 'DFS'
        handleFilterChange({
            surveyType: newSurveyType,
            status: [],
            current_page: '1',
            searchText: '',
            filterInputs: {
                start_date: null,
                end_date: null,
                payment_model:
                    key === '1'
                        ? ''
                        : key === '2'
                        ? 'SUBSCRIPTION'
                        : 'LEASE_TO_OWN',
            },
        })
        setSearchTextInput('')
    }

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={1}
                current={filterState?.currentPage}
                total={parseInt(totalPages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={updatePage}
            />
        )
    }

    const queryKey = [
        'fetch-alternative-recommendations',
        filterState?.currentPage,
        filterState?.searchText,
        filterState?.status,
        filterState?.filterInputs?.start_date,
        filterState?.filterInputs?.end_date,
        currentTab,
    ]

    const { refetch: plansRefetch, isFetching: plansFetching } = useQuery(
        queryKey,
        () =>
            getAlternateRecommendations(
                filterState?.currentPage,
                filterState?.searchText,
                filterState?.status?.map(item => item?.value)?.join(',') || '',
                (filterState?.filterInputs?.start_date !== null &&
                    format(
                        new Date(filterState?.filterInputs?.start_date),
                        'yyyy-MM-dd',
                    )) ||
                    '',
                (filterState?.filterInputs?.end_date !== null &&
                    format(
                        new Date(filterState?.filterInputs?.end_date),
                        'yyyy-MM-dd',
                    )) ||
                    '',
                filterState?.filterInputs?.payment_model,
            ),
        {
            enabled: true,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: res => {
                const queries = res?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateAdminDTCReviewFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                        ]),
                    ),
                )
                const data = res?.data?.data
                setPlans(data)
                setTotalPages(res?.data?.total_pages)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        plansRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState])

    return (
        <>
            <SeoComponent title="SunFi | Admin | Alternate Recommendations" />
            <FilterDrawer
                visible={drawerVisible}
                onClose={handleDrawer}
                filterState={filterState}
                setFilterState={setFilterState}
                currentTab={currentTab}
            />
            {toastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                />
            )}
            <div className={styles.alternateRecomm}>
                <header>
                    <h1>Alternative Recommendation</h1>
                    <div className={styles.alternateRecomm__filter}>
                        <div className={styles.alternateRecomm__filter_search}>
                            <input
                                placeholder={
                                    'Search by consumer name, email or phone number'
                                }
                                value={searchTextInput}
                                onChange={e => {
                                    e.preventDefault()
                                    if (e.target.value === '') {
                                        setSearchTextInput('')
                                        handleFilterChange({ searchText: '' })
                                    } else {
                                        const value = e.target.value.trim()
                                        setSearchTextInput(value)
                                    }
                                }}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        addSearchTextToFilter()
                                        e.target.blur()
                                    }
                                }}
                                onBlur={() => addSearchTextToFilter()}
                            />
                            <SearchIcon
                                role="button"
                                onClick={() => plansRefetch()}
                            />
                        </div>
                        <div
                            className={styles.alternateRecomm__filter_btn}
                            role="button"
                            onClick={() => handleDrawer()}
                        >
                            <FilterIcon role="presentation" />
                            <span>Filters</span>
                        </div>
                    </div>
                </header>

                <main>
                    <div className="DtcReviewListings_Tabs">
                        <div
                            className={`DtcReviewListings_Tabs_Filter ${
                                plansFetching && 'isFetching'
                            }`}
                        >
                            <span>Filter by status</span>
                            <MultipleSelectField
                                title={'All'}
                                name={'status'}
                                selectWidth={'200px'}
                                selectHeight={'48px'}
                                values={alternateStatusOptions}
                                value={filterState?.status?.map(
                                    item => item?.label,
                                )}
                                inputValue={filterState?.status?.map(
                                    item => item?.label,
                                )}
                                handleMultipleSelectChange={value => {
                                    const values = value?.map(item => ({
                                        value: mapStatusValue[item],
                                        label: item,
                                        id: alternateStatusOptions?.filter(
                                            each => each.value === item,
                                        )?.[0]?.id,
                                    }))
                                    handleFilterChange({ status: values })
                                }}
                                maxTagCount={0}
                                optionStyle={{
                                    width: '100%',
                                    margin: '6px 0 10px 0',
                                    paddingRight: '12px',
                                }}
                            />
                        </div>
                        {plansFetching ? (
                            <ListingsSkeletalCards
                                total={3}
                                columnCount={5}
                            />
                        ) : (
                            <Tabs activeKey={currentTab} onChange={onTabChange}>
                                <Tabs.TabPane tab="All" key="1">
                                    {plans?.length > 0 ? (
                                        <PlansTable data={plans} />
                                    ) : (
                                        <EmptyState title="No alternate recommendation request found" />
                                    )}
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Subscription" key="2">
                                    {plans?.length > 0 ? (
                                        <PlansTable data={plans} />
                                    ) : (
                                        <EmptyState title="No subscription alternate recommendation request found" />
                                    )}
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Lease to own" key="3">
                                    {plans?.length > 0 ? (
                                        <PlansTable data={plans} />
                                    ) : (
                                        <EmptyState title="No lease to own alternate recommendation request found" />
                                    )}
                                </Tabs.TabPane>
                            </Tabs>
                        )}
                    </div>

                    {plans?.length > 0 && !plansFetching && (
                        <div className={styles.alternateRecomm__pagination}>
                            {paginationBlock()}
                        </div>
                    )}
                </main>
            </div>
        </>
    )
}

export default AlternateRecommendations
