import { useState } from 'react'
import { useMutation } from 'react-query'
import { validateExistingSSEConsumerApi } from 'src/api/shoppingExperience/landingPage'
import { fieldErrorHandler } from 'src/utils/errorHandler'

const useValidateExistingCustomer = ({ setErrors }) => {
    const [isExisting, setIsExisting] = useState(false)
    const validateUserIdMutation = useMutation({
        mutationFn: existingConsumerPayload =>
            validateExistingSSEConsumerApi(existingConsumerPayload),
        onSuccess: response => {
            setIsExisting(response.data.data)
        },
        onError: error => {
            // rename phone error key
            if (error?.response?.data?.errors?.phone_number) {
                error.response.data.errors.phone =
                    error.response.data.errors.phone_number
                delete error.response.data.errors.phone_number
            }

            const errorsArr = fieldErrorHandler(error?.response?.data)

            for (let key in errorsArr) {
                setErrors(err => {
                    return {
                        ...err,
                        ...errorsArr[key],
                    }
                })
            }
        },
    })

    return {
        validateUserIdMutation,
        isExisting,
        validateUserIdLoading: validateUserIdMutation.isLoading,
    }
}

export default useValidateExistingCustomer
