export const getClientLocator = async () => {
    // todo: resolve ipify behavior on the platform and re-introduce ip addresses
    // try {
    //     const ipAddress = await axios.get('https://api.ipify.org/?format=json')
    //     return {
    //         ipAddress: ipAddress.data?.ip,
    //     }
    // } catch (error) {
    //     return {
    //         ipAddress: '',
    //     }
    // }

    return {
        ipAddress: '',
    }
}
