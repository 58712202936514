import PropTypes from 'prop-types'
import Verify from 'src/components/GettingStarted/components/Verify'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import styles from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'
import 'src/components/GettingStarted/gettingstarted.scss'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import Toast from 'src/components/Toast'
import useLoginModal from './hook'

const LoginModal = ({
    showModal,
    setShowModal,
    onCancel,
    loginModalContent,
    setLoginModalContent,
    inputs,
    otpMethod,
    setOtpMethod,
}) => {
    const {
        methodDiv,
        methodDivActive,
        methodIcon,
        methodText,
        customStrokeAndFillStyle,
        customStrokeAndFillStyleIcon,
    } = styles

    const { isMobile } = useMediaQueries()
    const {
        handleClose,
        fullMethodName,
        handleClick,
        verifyLoginMutation,
        initiateLoginLoading,
        toastError,
        verifyLoginToastErr,
        errorMessage,
        verifyLoginErrMsg,
        otpMethods,
        handleSelectOtpMethod,
        errors,
        otp,
        setOtp,
        otpError,
        resendOTPMutation,
        resent,
        setResent,
    } = useLoginModal(
        onCancel,
        loginModalContent,
        setLoginModalContent,
        inputs,
        setShowModal,
        otpMethod,
        setOtpMethod,
    )

    const padding = isMobile ? 20 : '32px 42px 60px 42px'

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={handleClose}
            title={
                loginModalContent === 'select-otp-method'
                    ? 'Getting Started on SunFi'
                    : `Verify your ${fullMethodName}`
            }
            footerLeftContent="chat"
            primaryBtnText="Continue"
            handlePrimaryBtnClick={handleClick}
            primaryBtnLoading={
                verifyLoginMutation.isLoading || initiateLoginLoading
            }
            standardHeight={'fit-content'}
            standardWidth={476}
            mobileHeight={527}
            standardContentStyle={{ padding: padding, height: 294 }}
            mobileContentStyle={{ padding: padding }}
        >
            {(toastError || verifyLoginToastErr) && (
                <Toast
                    messageType={'error-secondary'}
                    message={errorMessage || verifyLoginErrMsg}
                />
            )}
            {loginModalContent === 'select-otp-method' && (
                <>
                    <div
                        className={'GettingStartedHeadingAndText'}
                        style={{ marginBottom: 15 }}
                    >
                        <h3>
                            Where would you like to receive your verification
                            code?
                        </h3>
                        <p>
                            Providing the code to your email or phone number
                            helps us verify your account.
                        </p>
                    </div>
                    {otpMethods?.map((each, i) => (
                        <div
                            key={i}
                            role="button"
                            className={`${methodDiv} ${
                                otpMethod === each?.key ? methodDivActive : ''
                            } ${
                                each?.key === 'phone'
                                    ? customStrokeAndFillStyle
                                    : ''
                            }`}
                            onClick={() => handleSelectOtpMethod(each?.key)}
                        >
                            <div
                                className={`${methodIcon} ${
                                    each?.key === 'phone'
                                        ? customStrokeAndFillStyleIcon
                                        : ''
                                }`}
                            >
                                {each?.icon}
                            </div>
                            <div className={methodText}>
                                <h4>{each?.title}</h4>
                                <p>{each?.description}</p>
                            </div>
                        </div>
                    ))}
                    <p
                        className="GettingStartedOTPErrorMsg"
                        style={{ marginTop: 0 }}
                    >
                        {errors?.otpMethod}
                    </p>
                </>
            )}
            {loginModalContent === 'enter-otp' && (
                <Verify
                    contact={otpMethod}
                    otp={otp}
                    setOtp={setOtp}
                    otpError={otpError}
                    inputs={inputs}
                    handleResendOtp={() => resendOTPMutation.mutate()}
                    resent={resent}
                    setResent={setResent}
                    disabled={verifyLoginMutation.isLoading}
                    padding={0}
                    margin={0}
                    minHeight={285}
                />
            )}
        </ConsumerModalBase>
    )
}

LoginModal.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    onCancel: PropTypes.func,
    loginModalContent: PropTypes.string,
    setLoginModalContent: PropTypes.func,
    inputs: PropTypes.object,
    otpMethod: PropTypes.string,
    setOtpMethod: PropTypes.func,
}

export default LoginModal
