export const businessType = ['Sole Proprietorship', 'LLC', 'NGO']

export const businessIndustry = [
    'Agriculture',
    'Aviation',
    'Bakery',
    'Barber Shops',
    'Car Washes',
    'Cleaning Services',
    'Commercial/Retail',
    'Construction',
    'Education and Training',
    'Electrical Parts and Equipments',
    'Energy and Power Generation',
    'FMCG',
    'Fashion',
    'Fast Food Restaurants',
    'Financial Services',
    'Haulage/Logistics',
    'Healthcare',
    'ICT',
    'Manufacturing',
    'Media & Entertainment',
    'Oil & Gas',
    'Pharmacies',
    'Photo Studio',
    'Professional Services',
    'Telecommunication',
    'Tourism/Hospitality',
    'Transportation',
    'Waste Management',
]
