import PropTypes from 'prop-types'
import '../consumermodalbase.scss'
import Header from './Header'
import Footer from './Footer'
import { footerPropTypes, standardModalPropTypes } from '../utils'
import { Modal } from 'antd'

const StandardModal = ({
    showModal,
    onCancel,
    children,
    title,
    footerLeftContent,
    chatCTAText,
    secondaryBtnWidth,
    secondaryBtnHeight,
    handleSecondaryBtnClick,
    secondaryBtnDisabled,
    secondaryBtnLoading,
    secondaryBtnText,
    primaryBtnWidth,
    primaryBtnHeight,
    handlePrimaryBtnClick,
    primaryBtnDisabled,
    primaryBtnLoading,
    primaryBtnText,
    standardWidth = 475,
    standardHeight,
    footerClassName,
    standardContentStyle = {},
}) => {
    return (
        <Modal
            open={showModal}
            onCancel={onCancel ? onCancel : () => {}}
            footer={null}
            wrapClassName={'consumer-modal-base-standard'}
            closable={false}
            destroyOnClose
            bodyStyle={{
                width: standardWidth,
                height: standardHeight,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header title={title} onCancel={onCancel} />
            <div
                className="consumer-modal-base-main"
                style={standardContentStyle}
            >
                {children}
            </div>
            <Footer
                footerLeftContent={footerLeftContent}
                chatCTAText={chatCTAText}
                secondaryBtnWidth={secondaryBtnWidth}
                secondaryBtnHeight={secondaryBtnHeight}
                handleSecondaryBtnClick={handleSecondaryBtnClick}
                secondaryBtnDisabled={secondaryBtnDisabled}
                secondaryBtnLoading={secondaryBtnLoading}
                secondaryBtnText={secondaryBtnText}
                primaryBtnWidth={primaryBtnWidth}
                primaryBtnHeight={primaryBtnHeight}
                handlePrimaryBtnClick={handlePrimaryBtnClick}
                primaryBtnDisabled={primaryBtnDisabled}
                primaryBtnLoading={primaryBtnLoading}
                primaryBtnText={primaryBtnText}
                footerClassName={footerClassName}
            />
        </Modal>
    )
}

StandardModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
    ...footerPropTypes,
    ...standardModalPropTypes,
}

export default StandardModal
