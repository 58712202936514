import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon-grey.svg'
import PropTypes from 'prop-types'
import '../consumermodalbase.scss'

const Header = ({ title, onCancel }) => {
    return (
        <div className="consumer-modal-base-header">
            <h4>{title}</h4>
            <CloseIcon
                role="button"
                onClick={onCancel}
                aria-label="close button"
            />
        </div>
    )
}

Header.propTypes = {
    title: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
}

export default Header
