import { appTracking } from 'src/utils/appTracker'
import { consumerKYCEvents } from 'src/utils/mp-event-tracker/consumer-kyc'
import { ReactComponent as Email } from 'src/assets/images/email-no-stroke.svg'
import { ReactComponent as Phone } from 'src/assets/images/phone-with-chat-no-stroke.svg'
import { shortenEmail } from 'src/utils/shortenEmail'

const pageUrlName = window.location.pathname

export const handleAppTrackingOnSignup = event => {
    appTracking(
        pageUrlName,
        '',
        '',
        consumerKYCEvents[event].action,
        consumerKYCEvents[event].label,
        consumerKYCEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}

export const formatOtpVerifyMethods = data => {
    return [
        {
            title: 'Send OTP to your email address',
            description: `A code will be sent to ${shortenEmail(data?.email)}`,
            key: 'email',
            icon: <Email />,
        },
        {
            title: 'Send OTP to your phone number',
            description: `A code will be sent to +234********${data?.phone?.slice(
                -2,
            )}`,
            key: 'phone',
            icon: <Phone />,
        },
    ]
}

export const kycPageTypes = [
    {
        title: 'Use One Page KYC',
        description:
            'Complete your KYC verification on a single page for a streamlined and efficient experience.',
        key: 'one-page-kyc',
    },
    {
        title: 'Use Multi-Page KYC',
        description:
            'Complete your KYC where the verification process is split across multiple steps or pages.',
        key: 'multi-page-kyc',
    },
]
