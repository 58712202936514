import { useEffect } from 'react'
import { scrollToById } from 'src/utils/scrollToById'

const useScrollToError = data => {
    useEffect(() => {
        const errorClassNames = ['ErrorText']
        let errorElement = null

        for (const className of errorClassNames) {
            errorElement = document.querySelector(`.${className}`)
            if (errorElement) break
        }

        if (errorElement) {
            if (!errorElement.id) {
                errorElement.id = 'errorElement'
            }
            scrollToById(errorElement.id, 200)
        }
    }, [data])
}

export default useScrollToError
