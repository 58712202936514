/* eslint-disable react/prop-types */
import { Spin } from 'antd'
import '../../assets/styles/spin.scss'

const Loader = ({ text }) => {
    return (
        <div className="SpinDiv">
            <Spin tip={text ? text : 'Loading...'} size="large"></Spin>
        </div>
    )
}

export const InlineLoader = ({ color = 'white' }) => {
    const classMap = {
        white: 'BtnLoading',
        primary: 'BtnLoadingPrimary',
    }

    return (
        <div className="LoadingWrapper">
            <div className={classMap[color]}></div>
        </div>
    )
}

export const InlineLoaderDark = ({ sseUse }) => {
    return (
        <div className="LoadingWrapper">
            <div
                className={`BtnLoadingDark ${sseUse ? 'sseSmallerIcon' : ''}`}
            ></div>
        </div>
    )
}

export const ThreeDotsLoader = () => {}
export default Loader
