import axios from '../../../config/axios'
import { useQuery } from "react-query"

export const getProviders = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
    statusType = '',
) => {
    const request = await axios.get(
        `/admin/provider?page=${page}&company_name=${searchText}&start_date=${startDate}&end_date=${endDate}&status=${statusType}`,
    )
    return request
}


export const useGetProviders = (page, search, startDate, endDate, statusType, options = {}) => {
    return useQuery({
        queryFn: () => getProviders(page, search, startDate, endDate, statusType),
        queryKey: ['all-providers-lists', page, search, startDate, endDate, statusType],
        ...options
    })
}