import React, { useEffect } from 'react'
import SeoComponent from '../../components/Seo';
import { Link } from 'react-router-dom';
import Navigation from '../../components/Navigation';
import styles from './notfound.module.scss';
import { ReactComponent as LeftArrow } from '../../assets/images/LeftArrow.svg';
import { ReactComponent as IllustrationBgWhite } from '../../assets/images/404IllustrationBgWhiteResp.svg';

const NotFound = () => {

  const { Wrapper, NotFoundContainer, NavHome, Illustrator } = styles;
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakPoint = 605;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <>
      <div className={Wrapper}>
        <SeoComponent 
          title='Not Found | SunFi | Simplifying and Scaling Clean Energy'
          tracker='NotFoundTracker'
        />
        <Navigation
          bgColor='var(--white)'
          textColor='var(--black)'
          btnBgColor='var(--blue)'
          btnTextColor='var(--white)'
          btnBgColorOutline= 'var(--white)'
          btnTextColorOutline='var(--blue)'
          page='notFound'
        />
      </div>
      <div className={NotFoundContainer}>
        <IllustrationBgWhite className={Illustrator} />
        <h2>Oopss, You’re Lost</h2>
        <p>
          Energy Storage helps you store energy for consumption whenever it is
          needed. {width > breakPoint && <br />} Imagine the possibilities. As
          the world transitions to cleaner
        </p>
        <div>
          <Link
            to='/home'
            className={NavHome}
            style={{
              color: 'white',
              fontWeight: '600',
            }}
          >
            <LeftArrow /> Go Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound
