/* eslint-disable no-undef */

import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Collapse } from 'antd'
import Slider from 'react-slick'
import Navigation from 'src/components/Navigation'
import SeoComponent from 'src/components/Seo'
import GetStarted, {
    ImageSection,
    TextSection,
} from 'src/components/Getstarted'
import FrequentlyAskedQuestions from 'src/components/Faq'
import ConsumerFooter from 'src/components/ConsumerFooter'
import PackageSection from 'src/components/PackageSection'
import {
    TextSectionOne,
    PictureSectionTwo,
} from 'src/components/PackageSection/SubSections'
import { ReactComponent as EfficientIcon } from 'src/assets/images/partnerspg-efficient-icon.svg'
import { ReactComponent as EmailNotificationsIcon } from 'src/assets/images/partnerspg-money-icon.svg'
import { ReactComponent as MoneyIcon } from 'src/assets/images/partnerspg-tag-icon.svg'
import { ReactComponent as ActiveAccordionIcon } from 'src/assets/images/partnerspg-active-accordion.svg'
import { ReactComponent as InactiveAccordionIcon } from 'src/assets/images/partnerspg-inactive-accordion.svg'
import { ReactComponent as ArrowDownOutlinedIcon } from 'src/assets/images/partnerspg-arrow-down-icon.svg'
import { ReactComponent as ArrowUpOutlinedIcon } from 'src/assets/images/partnerspg-arrow-up-icon.svg'
import ButtonComponent from 'src/components/Button'
import './partners.scss'
import { providerTypeContent } from './data'
import HeroSectionSunFiPartnersImg from 'src/assets/images/sunfi-partners.svg'
import CookieNotification from 'src/components/CookieNotificiation'
import { isMobile as mobileView } from 'src/utils/mediaQueries'
import Testimonial from '../Consumer/SmartShoppingExperience/Home/Testimonial/Testimonial'
import VideoSection from '../Consumer/SmartShoppingExperience/Home/VideoSection/VideoSection'
import BuildYourSolar from './components/BuildYourSolar'

const Partners = () => {
    const [visible, setVisible] = useState(false)
    const sectionOneRef = useRef(null)
    const sectionTwoRef = useRef(null)
    const sectionThreeRef = useRef(null)
    const history = useHistory()
    const [activeAccordionKey, setActiveAccordionKey] = useState('1')

    const handleVisibleChange = flag => {
        setVisible(flag)
    }
    const settings = {
        className: '',
        centerMode: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        // variableWidth: false,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: true,
        responsive: [
            {
                breakpoint: 475,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }
    const { Panel } = Collapse
    const tabletView = window.innerWidth > 767 && window.innerWidth < 1024

    const goToPartnerSignup = () => {
        history.push('/signup')
    }

    return (
        <>
            <SeoComponent
                title="Partners | SunFi | Simplifying and Scaling Clean Energy"
                tracker="PartnersTracker"
            />

            <Navigation
                bgColor="var(--blue)"
                textColor="var(--white)"
                btnTextColor="var(--blue)"
                btnBgColor="var(--white)"
                btnTextColorOutline="var(--white)"
                btnBgColorOutline="var(--blue)"
                page="partner"
            />

            <CookieNotification />

            <div>
                <GetStarted
                    bgColor="var(--blue)"
                    sectionOne={
                        <TextSection
                            page="partners"
                            title="Build your clean energy business on SunFi"
                            subTitle="We've built a platform to help solar business owners grow energy sales, get financing and manage their customers. Build your clean energy business on SunFi."
                            buttonText="Become a Partner"
                            subTextWidth="450px"
                            btnTextColor="var(--blue)"
                            headerTextColor={'#FFFFFF'}
                            btnBgColor="var(--white)"
                            dropdownMenu
                            btnBgColorOutline="var(--blue)"
                            btnTextColorOutline="var(--white)"
                            showDropdown={visible}
                            handleVisibleChange={handleVisibleChange}
                        />
                    }
                    sectionTwo={
                        <ImageSection
                            page="partners"
                            imgUrl={HeroSectionSunFiPartnersImg}
                        />
                    }
                />
            </div>
            <>
                <div className="PartnerSectionTwoWrapper">
                    <div className="PartnerSectionTwoChildOneWrapper">
                        <p>Trusted by leading solar providers</p>
                        {mobileView || tabletView ? (
                            <Slider {...settings}>
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/arnegy.svg"
                                    alt="anergy"
                                    height="70px"
                                    width="auto"
                                />
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/nebular.svg"
                                    alt="nebular"
                                    height="70px"
                                    width="auto"
                                />
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Solar+Haven+Logo.svg"
                                    alt="solar-haven"
                                    height="70px"
                                    width="auto"
                                />
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/APS+Logo.svg"
                                    alt="aspire"
                                    height="70px"
                                    width="auto"
                                />
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/dw-uwana.svg"
                                    alt="uwana"
                                    height="70px"
                                    width="auto"
                                />
                            </Slider>
                        ) : (
                            <div className="PSTCOLogoWrapper">
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/arnegy.svg"
                                    alt="anergy"
                                />
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/nebular.svg"
                                    alt="nebular"
                                />
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Solar+Haven+Logo.svg"
                                    alt="solar-haven"
                                />
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/APS+Logo.svg"
                                    alt="aspire"
                                />
                                <img
                                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/dw-uwana.svg"
                                    alt="uwana"
                                />
                            </div>
                        )}
                    </div>
                    <div className="PartnerSectionTwoChildTwoWrapper">
                        <PackageSection
                            bgColor="var(--white)"
                            Style={{ margin: '0' }}
                            SectionOne={
                                <div className="PSTCSectionOneWrapper">
                                    <div>
                                        <h4 className="PSTCSectionOneH4">
                                            GROW YOUR SOLAR BUSINESS WITH EASE
                                        </h4>
                                        <h2 className="PSTCSectionOneH2">
                                            No matter your business size, we’ve
                                            got you!
                                        </h2>
                                        <div
                                            style={{
                                                width: mobileView
                                                    ? '100%'
                                                    : 460,
                                            }}
                                        >
                                            <Collapse
                                                bordered={false}
                                                activeKey={[activeAccordionKey]}
                                                onChange={e =>
                                                    setActiveAccordionKey(e)
                                                }
                                                style={{
                                                    background: '#fff',
                                                }}
                                                accordion
                                                expandIcon={({ isActive }) =>
                                                    isActive ? (
                                                        <ActiveAccordionIcon
                                                            role={
                                                                'presentation'
                                                            }
                                                        />
                                                    ) : (
                                                        <InactiveAccordionIcon
                                                            role={
                                                                'presentation'
                                                            }
                                                        />
                                                    )
                                                }
                                            >
                                                {providerTypeContent().map(
                                                    provider => (
                                                        <Panel
                                                            header={
                                                                provider.name
                                                            }
                                                            style={{
                                                                background:
                                                                    '#fff',
                                                                fontFamily:
                                                                    provider.id ===
                                                                    activeAccordionKey
                                                                        ? 'SF-Pro-Display-Semibold'
                                                                        : 'SF-Pro-Display-Medium',

                                                                fontSize:
                                                                    '16px',
                                                                color: '#011A3C',
                                                                padding:
                                                                    '18px 0',
                                                            }}
                                                            key={provider.id}
                                                            extra={
                                                                provider.id ===
                                                                activeAccordionKey ? (
                                                                    <ArrowUpOutlinedIcon
                                                                        role={
                                                                            'presentation'
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <ArrowDownOutlinedIcon
                                                                        role={
                                                                            'presentation'
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    paddingLeft:
                                                                        '40px',
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        width: mobileView
                                                                            ? '100%'
                                                                            : 353,
                                                                    }}
                                                                    className="AccordionBodyText"
                                                                >
                                                                    {
                                                                        provider.body
                                                                    }
                                                                </p>
                                                                <button
                                                                    onClick={
                                                                        provider.redirectTo
                                                                    }
                                                                    className="AccordionButton"
                                                                >
                                                                    {
                                                                        provider.buttonText
                                                                    }
                                                                </button>
                                                            </div>
                                                        </Panel>
                                                    ),
                                                )}
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            }
                            SectionTwo={
                                <PictureSectionTwo
                                    picHeight="721px"
                                    picWidth="100%"
                                    page="partners"
                                    imgUrl={
                                        activeAccordionKey === '3'
                                            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/distributor-installer.svg'
                                            : activeAccordionKey === '2'
                                            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/partnerspg-section2.svg'
                                            : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/manufacter-installer.svg'
                                    }
                                    style={{
                                        marginTop: '-85px',
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </>
            <>
                <div className="PartnerSectionThreeWrapper">
                    <img
                        src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/partnerspg-dashboard.svg"
                        alt="dashboard-img"
                    />
                </div>
                <div className="PartnerSectionFourWrapper">
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <EfficientIcon role={'presentation'} />
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <p className="PartnerSectionFourIconTitle">
                                    3X
                                </p>
                                <p className="PartnerSectionFourIconSubTitle">
                                    Faster approvals than the
                                    <br />
                                    average bank
                                </p>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <EmailNotificationsIcon role={'presentation'} />
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <p className="PartnerSectionFourIconTitle">
                                    ₦0.00
                                </p>
                                <p className="PartnerSectionFourIconSubTitle">
                                    Charges on all your
                                    <br />
                                    transactions
                                </p>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <MoneyIcon role={'presentation'} />
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <p className="PartnerSectionFourIconTitle">
                                    50%
                                </p>
                                <p className="PartnerSectionFourIconSubTitle">
                                    Increase in monthly deals
                                    <br />
                                    closed
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            columnGap: '9px',
                            display: 'flex',
                        }}
                    >
                        <ButtonComponent
                            btnWidth="150px"
                            btnBgColor="#004aad"
                            btnTextColor="#fff"
                            fontSize="14px"
                            handleClick={() => history.push('/signup')}
                        >
                            Sign Up
                        </ButtonComponent>
                        <ButtonComponent
                            type="outline"
                            btnWidth="150px"
                            btnBgColorOutline="transparent"
                            btnTextColorOutline="#fff"
                            fontSize="14px"
                            handleClick={() => history.push('/login')}
                        >
                            Sign In
                        </ButtonComponent>
                    </div>
                </div>
            </>
            <>
                <div ref={sectionOneRef}>
                    <div
                        style={{
                            margin: mobileView
                                ? '90px 0 40px 0'
                                : tabletView
                                ? '90px 0 100px 0'
                                : '90px 0 200px 0',
                        }}
                    >
                        <PackageSection
                            bgColor="var(--white)"
                            SectionOne={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: tabletView
                                            ? 'center'
                                            : 'flex-end',
                                        marginBottom: tabletView ? '30px' : '',
                                    }}
                                >
                                    <TextSectionOne
                                        heading="BUILD YOUR BUSINESS WORKSPACE"
                                        title="Create and Maintain your Products"
                                        subTitle="Build a workspace for your business on the SunFi platform. List solar equipment, create solar packages update prices and generate multiple accounts for team members."
                                        buttonOneTitle="Get Started"
                                        section="installer"
                                        page="partners"
                                        titleWidth={
                                            tabletView ? '320px' : '298px'
                                        }
                                        subTitleWidth="382px"
                                        style={{
                                            padding: mobileView
                                                ? ''
                                                : tabletView
                                                ? '0 0 30px 0'
                                                : '30px 125px 0 0',
                                            maxWidth: '440px',
                                            width: '100%',
                                        }}
                                        handleClick={goToPartnerSignup}
                                    />
                                </div>
                            }
                            SectionTwo={
                                <PictureSectionTwo
                                    picHeight="442px"
                                    picWidth="auto"
                                    page="partners"
                                    imgUrl="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/partnerspg-component.svg"
                                    style={{
                                        padding:
                                            mobileView || tabletView
                                                ? ''
                                                : '0 0 0 110px',
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
                <div ref={sectionTwoRef}>
                    <div
                        style={{
                            marginBottom: mobileView
                                ? '30px'
                                : tabletView
                                ? '70px'
                                : '170px',
                        }}
                    >
                        <PackageSection
                            bgColor="var(--white)"
                            SectionOne={
                                <PictureSectionTwo
                                    picHeight="442px"
                                    picWidth="auto"
                                    page="partners"
                                    imgUrl="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/disbursement.svg"
                                    style={{
                                        textAlign: 'end',
                                        paddingRight: mobileView ? '' : '125px',
                                    }}
                                />
                            }
                            SectionTwo={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: tabletView
                                            ? 'center'
                                            : '',
                                        marginBottom: tabletView ? '30px' : '',
                                    }}
                                >
                                    <TextSectionOne
                                        heading="NO HIDDEN CHARGES OR TERMS"
                                        title="Create and Track Payment Plans for your Customers"
                                        subTitle="SunFi builds energy financial technology that optimizes and matches the right clean energy solution to meet your needs. From payment plans to installation support and after care service."
                                        buttonOneTitle="Get Started"
                                        section="installer"
                                        page="partners"
                                        titleWidth={
                                            tabletView ? '420px' : '293px'
                                        }
                                        style={{
                                            padding: mobileView
                                                ? ''
                                                : tabletView
                                                ? '70px 0 0 0'
                                                : '30px 0 0 125px',
                                            maxWidth: '490px',
                                            width: 'auto',
                                        }}
                                        handleClick={goToPartnerSignup}
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>
                <div ref={sectionThreeRef}>
                    <div
                        style={{
                            marginBottom:
                                mobileView || tabletView ? '90px' : '170px',
                        }}
                    >
                        <PackageSection
                            bgColor="var(--white)"
                            SectionOne={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: tabletView
                                            ? 'center'
                                            : 'flex-end',
                                        marginBottom: tabletView ? '70px' : '',
                                    }}
                                >
                                    <TextSectionOne
                                        heading="NEVER MISS AN UPDATE"
                                        title="Get Fully Automated Email Notifications"
                                        subTitle="With SunFi, you can never miss a deal or an update. Email notifications keep you informed of deal progress, new leads and helpful product updates."
                                        buttonOneTitle="Get Started"
                                        section="installer"
                                        page="partners"
                                        titleWidth={
                                            tabletView ? '420px' : '298px'
                                        }
                                        subTitleWidth="382px"
                                        style={{
                                            padding: mobileView
                                                ? ''
                                                : tabletView
                                                ? '0'
                                                : '30px 125px 0 0',
                                            maxWidth: '440px',
                                            width: '100%',
                                        }}
                                        handleClick={goToPartnerSignup}
                                    />
                                </div>
                            }
                            SectionTwo={
                                <PictureSectionTwo
                                    picHeight="442px"
                                    picWidth="auto"
                                    page="partners"
                                    imgUrl="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/partnerspg-email-notifications.svg"
                                    style={{
                                        padding: mobileView
                                            ? ''
                                            : tabletView
                                            ? '0 75px 0 0'
                                            : '0 0 0 125px',
                                    }}
                                />
                            }
                        />
                    </div>
                </div>

                <div
                    style={{
                        marginBottom:
                            mobileView || tabletView ? '90px' : '170px',
                    }}
                >
                    <PackageSection
                        bgColor="var(--white)"
                        SectionOne={
                            <PictureSectionTwo
                                picHeight="442px"
                                picWidth="auto"
                                page="partners"
                                imgUrl="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/disbursement.svg"
                                style={{
                                    textAlign: 'end',
                                    paddingRight: mobileView ? '' : '125px',
                                }}
                            />
                        }
                        SectionTwo={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: tabletView ? 'center' : '',
                                    marginBottom: tabletView ? '70px' : '',
                                }}
                            >
                                <TextSectionOne
                                    heading="ENJOY STRESS FREE PAYMENTS"
                                    title="Seamless Disbursement Process"
                                    subTitle="Business owners trust our fast and easy disbursement process. Complete a transaction and get paid within 3 working days. Drama-free transactions and payments are possible on SunFi."
                                    buttonOneTitle="Get Started"
                                    section="installer"
                                    page="partners"
                                    titleWidth={tabletView ? '390px' : '266px'}
                                    style={{
                                        padding: mobileView
                                            ? ''
                                            : tabletView
                                            ? '70px 0 0 0'
                                            : '30px 0 0 125px',
                                        maxWidth: '500px',
                                        width: '100%',
                                    }}
                                    handleClick={goToPartnerSignup}
                                />
                            </div>
                        }
                    />
                </div>
            </>
            <VideoSection />
            <BuildYourSolar />
            <FrequentlyAskedQuestions page="partners" />
            <Testimonial userType="provider" />

            <ConsumerFooter />
        </>
    )
}

export default Partners
