import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import useUpdateProfile from './useUpdateProfile'
import useUpdateProfilePackage from './useUpdateProfilePackage'
import useDeleteProfile from './useDeleteProfile'
import useDuplicateProfile from './useDuplicateProfile'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useShortenLink from 'src/utils/Hooks/useShortenLink'
import { formatPaymentTypesOptionsForApi } from '../../AddPackage/components/AddPackageDrawer/data'

const useAdminProfileDetails = ({ selectedPackages, setPage, profile }) => {
    const queryParams = new URLSearchParams(window.location?.search)
    const currentTab = queryParams.get('tab') || '1'

    const [singlePackageEnergyInfo, setSinglePackageEnergyInfo] = useState({})
    const [cluster, setCluster] = useState({
        subscription_cluster_code: '',
        lease_to_own_cluster_code: '',
    })

    const [singlePackageEnergyNeeds, setSinglePackageEnergyNeeds] = useState([
        {
            id: uuidv4(),
            name: '',
            quantity: '',
            backup_hours: '',
            backup_hours_night: '',
            power_rating: '',
            unitOfMeasurement: '',
        },
    ])

    const [showEditPackageDrawer, setShowEditPackageDrawer] = useState(false)
    const [showAddPackageDrawer, setShowAddPackageDrawer] = useState(false)
    const [showActionModal, setShowActionModal] = useState(false)
    const [action, setAction] = useState('')
    const [newProfileFromDuplicateId, setNewProfileFromDuplicateId] =
        useState('')
    const [showSharePackageModal, setShowSharePackageModal] = useState(false)
    const [sharePackageLink, setSharePackageLink] = useState('')

    const [paymentType, setPaymentType] = useState([])
    const [paymentTypeError, setPaymentTypeError] = useState('')

    const { id } = useParams()
    const history = useHistory()
    const { successAlert } = useCustomToast()

    const onUpdateProfileSuccess = () => {
        switch (action) {
            case 'unpublish-profile':
                setAction('profile-unpublished')
                break
            case 'remove-package':
                setAction('package-removed')
                setShowEditPackageDrawer(false)
                setPage(1)
                break
            case 'publish-profile':
                setAction('profile-published')
                break
            case 'update-profile-appliances':
                successAlert('Profile appliances successfully updated')
                setAction('')
                break
            case 'unpublish-profile-before-deleting-confirmation':
                setAction('profile-unpublished-before-deleting')
                break
            case 'add-packages':
                setShowAddPackageDrawer(false)
                setPage(1)
                break
        }
    }

    const onUpdateProfilePackageSuccess = () => {
        setShowEditPackageDrawer(false)
        setPage(1)
    }

    const onDeleteProfileSuccess = () => {
        setAction('profile-deleted')
        setTimeout(() => {
            history.push('/admin/consumer-profiles')
        }, [5000])
    }

    const onDuplicateProfileSuccess = newProfileId => {
        setAction('profile-duplicated')
        setNewProfileFromDuplicateId(newProfileId)
    }

    const {
        updateProfileMutate,
        updateProfileLoading,
        toastError,
        errorMessage,
    } = useUpdateProfile(action, onUpdateProfileSuccess)
    const {
        updateProfilePackageMutate,
        updateProfilePackageMutateAsync,
        updateProfilePackageLoading,
    } = useUpdateProfilePackage(onUpdateProfilePackageSuccess)
    const {
        deleteProfileMutate,
        deleteProfileLoading,
        deleteProfileToastError,
        deleteProfileErrorMessage,
    } = useDeleteProfile(onDeleteProfileSuccess)
    const {
        duplicateProfileMutate,
        duplicateProfileIsLoading,
        duplicateProfileToastError,
        duplicateProfileErrorMessage,
    } = useDuplicateProfile(onDuplicateProfileSuccess)

    const toggleEditPackageDrawer = () => {
        setShowEditPackageDrawer(!showEditPackageDrawer)
    }

    const toggleAddPackageDrawer = () => {
        setAction(showAddPackageDrawer ? '' : 'add-packages')
        setShowAddPackageDrawer(prev => !prev)
    }

    const storeActionAndOpenModal = action => {
        setAction(action)
        setShowActionModal(true)
    }

    const closeActionModal = () => {
        setAction('')
        setShowActionModal(false)
    }

    const handlePublishProfile = () => {
        updateProfileMutate({
            id: id,
            payload: {
                is_published: true,
            },
        })
    }

    const handleUnpublishProfile = () => {
        updateProfileMutate({
            id: id,
            payload: {
                is_published: false,
            },
        })
    }

    const handleEditPackage = item => {
        setSinglePackageEnergyInfo({
            ...item,
            lease_to_own_supported: item?.lease_to_own_supported,
            subscription_supported: item?.subscription_supported,
        })
        setSinglePackageEnergyNeeds(item?.appliances)
        setCluster({
            subscription_cluster_code: item?.cluster_info?.SUBSCRIPTION ?? '',
            lease_to_own_cluster_code: item?.cluster_info?.LEASE_TO_OWN ?? '',
        })
        setPaymentType(item?.payment_plan_types)
        setShowEditPackageDrawer(true)
    }

    const handleSavePackage = () => {
        let payment_type = [...paymentType]
        if (!payment_type.length) {
            setPaymentTypeError('Payment type is required')
        }

        const packageId = singlePackageEnergyInfo?.id
        updateProfilePackageMutate({
            profileId: id,
            packageId: packageId,
            payload: {
                appliances: singlePackageEnergyNeeds,
                ...(cluster?.subscription_cluster_code !== '' && {
                    subscription_cluster_code:
                        cluster?.subscription_cluster_code,
                }),
                ...(cluster?.lease_to_own_cluster_code !== '' && {
                    lease_to_own_cluster_code:
                        cluster?.lease_to_own_cluster_code,
                }),
                payment_plan_types:
                    formatPaymentTypesOptionsForApi(payment_type),
            },
        })
    }

    const handleRemovePackage = () => {
        storeActionAndOpenModal('remove-package')
    }

    const removePackage = () => {
        updateProfileMutate({
            id: id,
            payload: {
                remove_package_ids: [singlePackageEnergyInfo?.id],
            },
        })
    }

    const handleUpdateProfileAppliances = appliances => {
        setAction('update-profile-appliances')
        updateProfileMutate({
            id: id,
            payload: {
                appliances: appliances,
            },
        })
    }

    const handleUpdatePackages = async packages => {
        let add_packages = []
        let remove_package_ids = []
        let updatePackages = []
        let initialPackagesClone = [...selectedPackages]

        for (const selectedPackage of packages) {
            const existingPackage = initialPackagesClone.find(
                pkg => pkg.id === selectedPackage.id,
            )

            if (!existingPackage) {
                add_packages.push({
                    id: selectedPackage.id,
                    appliances: selectedPackage.appliances,
                    ...(selectedPackage?.subscription_cluster_code !== '' && {
                        subscription_cluster_code:
                            selectedPackage?.subscription_cluster_code,
                    }),
                    ...(selectedPackage?.lease_to_own_cluster_code !== '' && {
                        lease_to_own_cluster_code:
                            selectedPackage?.lease_to_own_cluster_code,
                    }),
                    payment_plan_types: selectedPackage.payment_plan_types,
                })
            } else {
                if (
                    JSON.stringify(existingPackage) !==
                    JSON.stringify(selectedPackage)
                ) {
                    updatePackages.push(selectedPackage)
                }

                initialPackagesClone = initialPackagesClone.filter(
                    pkg => pkg.id !== selectedPackage.id,
                )
            }
        }

        if (initialPackagesClone.length) {
            remove_package_ids = initialPackagesClone.map(pkg => pkg.id)
        }

        if (updatePackages.length) {
            let updatePackagesPromise = updatePackages.map(pkg =>
                updateProfilePackageMutateAsync({
                    profileId: id,
                    packageId: pkg.id,
                    payload: {
                        appliances: pkg.appliances,
                        ...(pkg?.subscription_cluster_code !== '' && {
                            subscription_cluster_code:
                                pkg?.subscription_cluster_code,
                        }),
                        ...(pkg?.lease_to_own_cluster_code !== '' && {
                            lease_to_own_cluster_code:
                                pkg?.lease_to_own_cluster_code,
                        }),
                        payment_plan_types: pkg.payment_plan_types,
                    },
                }),
            )

            await Promise.all(updatePackagesPromise)
        }

        if (add_packages.length || remove_package_ids.length) {
            updateProfileMutate({
                id,
                payload: {
                    add_packages,
                    remove_package_ids,
                },
            })
        }
    }

    const handleUpdateProfile = () => {
        history.push({
            pathname: `/admin/consumer-profiles/edit-consumer-profile/${id}`,
            state: {
                isPromotional: profile?.is_promotional === true,
            },
        })
    }

    const goBack = () => {
        history.push('/admin/consumer-profiles')
    }

    const actionModalHandler = () => {
        switch (action) {
            case 'remove-package':
                removePackage()
                break
            case 'package-removed':
                closeActionModal()
                break
            case 'package-saved':
                closeActionModal()
                setShowEditPackageDrawer(false)
                setPage(1)
                break
            case 'publish-profile':
                handlePublishProfile()
                break
            case 'profile-published':
                closeActionModal()
                break
            case 'unpublish-profile':
                handleUnpublishProfile()
                break
            case 'profile-unpublished':
                closeActionModal()
                break
            case 'unpublish-profile-before-deleting':
                setAction('unpublish-profile-before-deleting-confirmation')
                break
            case 'unpublish-profile-before-deleting-confirmation':
                handleUnpublishProfile()
                break
            case 'profile-unpublished-before-deleting':
                setAction('delete-profile')
                break
            case 'delete-profile':
                deleteProfileMutate()
                break
            case 'profile-deleted':
                closeActionModal()
                history.push('/admin/consumer-profiles')
                break
            case 'duplicate-profile':
                duplicateProfileMutate()
                break
            case 'profile-duplicated':
                history.push({
                    pathname: `/admin/consumer-profiles/edit-consumer-profile/${newProfileFromDuplicateId}`,
                    state: {
                        isPromotional: profile?.is_promotional === true,
                    },
                })
                break
        }
    }

    const { shortenLinkMutate, shortenLinkLoading } = useShortenLink(link =>
        setSharePackageLink(link),
    )

    const handleCopy = () => {
        shortenLinkMutate(
            `${location.origin}/consumer/profile-details/${id}/shared`,
        )
        setShowSharePackageModal(true)
    }

    return {
        singlePackageEnergyNeeds,
        setSinglePackageEnergyNeeds,
        showEditPackageDrawer,
        goBack,
        toggleEditPackageDrawer,
        showActionModal,
        action,
        storeActionAndOpenModal,
        closeActionModal,
        actionModalHandler,
        handleEditPackage,
        singlePackageEnergyInfo,
        handleSavePackage,
        updateProfileLoading,
        toastError,
        errorMessage,
        handleRemovePackage,
        handleUpdateProfileAppliances,
        updateProfilePackageLoading,
        deleteProfileLoading,
        deleteProfileToastError,
        deleteProfileErrorMessage,
        duplicateProfileIsLoading,
        duplicateProfileToastError,
        duplicateProfileErrorMessage,
        handleUpdateProfile,
        showAddPackageDrawer,
        toggleAddPackageDrawer,
        handleUpdatePackages,
        addPackagesLoading: updateProfileLoading || updateProfilePackageLoading,
        cluster,
        setCluster,
        handleCopy,
        shortenLinkLoading,
        sharePackageLink,
        setSharePackageLink,
        showSharePackageModal,
        setShowSharePackageModal,
        currentTab,
        paymentType,
        setPaymentType,
        paymentTypeError,
        setPaymentTypeError,
    }
}

export default useAdminProfileDetails
